import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from './usuario.service';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import Contacto from 'src/app/models/contacto.model';

declare function cargando();
declare function cargado();

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  constructor(
    private http:HttpClient,
    private router:Router,
    private usuarioService:UsuarioService
  ) { }

  private getHeaders(){
    return {
      headers: new HttpHeaders({'Authorization':this.usuarioService.token})
    };
  }

  getContacto( id:number ){
    let url = URL_SERVICIOS + '/contacto/'+id;
    
    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.proveedor;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        })
        return err;
      })
    );
  }

  getContactosCEOPage( page:number,IdProyecto:number ){
    let url = URL_SERVICIOS + '/contactos-ceo/'+page+'/'+IdProyecto;

    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.page;
      }),
      catchError(ex=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: ex.error.error.message || ex.error.error,
          footer: ''
        })
        return ex;
      })
    );
  }

  getContactosCEOAll(  ){
    let url = URL_SERVICIOS + '/contactos-ceo';

    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.data;
      }),
      catchError(ex=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: ex.error.error.message || ex.error.error,
          footer: ''
        })
        return ex;
      })
    );
  }

  getContactosPadron( IdPadron:number,page:number,termino:string ){
    let url = URL_SERVICIOS + '/contactos-padron/'+IdPadron+'/'+page+( (termino)?('/'+termino):'' );
    
    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.data;
      }),
      catchError(ex=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: ex.error.error.message || ex.error.error,
          footer: ''
        })
        return ex;
      })
    );
  }//fin getContactosPadron

  crear( contacto:Contacto ){
    let url = URL_SERVICIOS + '/contacto';

    cargando();
    return this.http.post( url,contacto,this.getHeaders() )
      .pipe(
        map((resp:any)=>{
          cargado();
          return resp;
        }),
        catchError(err=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.error.message || err.error.error,
            footer: ''
          });
          return err;
        })
      );
  }

  editar( id:number,contacto:Contacto ){
    let url = URL_SERVICIOS + '/contacto/'+id;

    cargando();
    return this.http.put( url,contacto,this.getHeaders() )
    .pipe(
      map((resp:any)=>{
        cargado();
        return resp;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        });
        return err;
      })
    );
  }

}//fin clase
