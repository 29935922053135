import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Usuario from 'src/app/models/usuario.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmpresaService } from 'src/app/services/service/empresa.service';
import Empresa from 'src/app/models/empresa.model';

@Component({
  selector: 'app-asignacion',
  templateUrl: './asignacion.component.html',
  styleUrls: ['./asignacion.component.css']
})
export class AsignacionComponent implements OnInit {

  empresas:Empresa[] = [];

  @Input() ShowModalAsignacion:boolean;
  @Input() usuario:Usuario;
  @Output() cambioEstado:EventEmitter<boolean> = new EventEmitter();

  form:FormGroup;
  oculto:string = 'oculto';

  constructor(
    private empresaService:EmpresaService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      IdEmpresa: new FormControl('',Validators.required)
    });
  }

  ngOnChanges(){
    if( this.ShowModalAsignacion ){
      this.oculto = '';
      this.getEmpresas();
    }else{
      this.oculto = 'oculto';
    }
  }

  cerrar(){
    this.cambioEstado.emit( false );
  }

  add(){
    
  }

  getEmpresas(){
    this.empresaService.getEmpresas()
      .subscribe( resp=>{
        this.empresas = resp;
      });
  }

}
