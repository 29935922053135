import { Component, OnInit } from '@angular/core';
import Proveedor from 'src/app/models/proveedor.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProveedorService } from 'src/app/services/service/proveedor.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-proveedor-form',
  templateUrl: './proveedor-form.component.html',
  styleUrls: ['./proveedor-form.component.css']
})
export class ProveedorFormComponent implements OnInit {

  titulo:string = '';
  form:FormGroup;
  proveedor:Proveedor = {};

  constructor(
    private _proveedorService:ProveedorService,
    private router:Router,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      Proveedor: new FormControl('',Validators.required),
      RFC: new FormControl('',Validators.required),
      Direccion: new FormControl('',Validators.required)
    });
    this.form.reset();

    this.activatedRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      if( !id ){
        this.titulo = 'Nuevo Proveedor';
      }else{
        this.titulo = 'Editar Proveedor';
        this.getUsuario( +id );
      }
    });

    
  }

  getUsuario( id:number ){
    this._proveedorService.getProveedor( id )
      .subscribe( ( proveedor :Proveedor)=>{
        this.proveedor = proveedor;
         this.form.setValue({
            Proveedor: proveedor.Proveedor,
            RFC: proveedor.RFC,
            Direccion: proveedor.Direccion
          });
      });
  }

  save(){
    let proveedor:Proveedor = this.form.value;
    if( !this.proveedor.IdProveedor ){
      proveedor.Activo = 1;
      this._proveedorService.crear( proveedor )
        .subscribe( (res:any)=>{
          if( !res.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          this.router.navigateByUrl('/proveedores');
        });
    }else{
      this._proveedorService.editar( this.proveedor.IdProveedor,proveedor )
        .subscribe( (res:any)=>{
          if( !res.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          this.router.navigateByUrl('/proveedores');
        });
    }
  }//fin save

}
