import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UsuarioService } from '../service.index';
import { map, catchError, delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { of } from 'rxjs';

declare function cargando();
declare function cargado();

@Injectable({
  providedIn: 'root'
})
export class UnicoService {

  constructor(
    private http:HttpClient,
    private usuarioService:UsuarioService
  ) { }

  private getHeaders(){
    return {
      headers: new HttpHeaders({'Authorization':this.usuarioService.token})
    };
  }

  getUnicos( Nombre ){
    let url = URL_SERVICIOS + '/unicos/'+Nombre;

    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.unicos;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        })
        return err;
      })
    );
  }

  crear( unico:any ){
    let url = URL_SERVICIOS + '/unico';

    cargando();
    return this.http.post( url,unico,this.getHeaders() )
    .pipe(
      map((resp:any)=>{
        cargado();
        return resp;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        });
        return err;
      })
    );
  }

  isValidoLaSeccion(seccion:number){
    return this.http.get(URL_SERVICIOS + '/valida-seccion-existe/'+seccion,this.getHeaders());
    /*return of({ isSeccionAvailable: seccion === 219})
      .pipe(delay(500));*/
  }

/*
  getProveedoresPage( page:number ){
    let url = URL_SERVICIOS + '/proveedores/'+page;
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        return resp.page;
      })
    );
  }

  

  editar( id:number,proveedor:Proveedor ){
    let url = URL_SERVICIOS + '/proveedor/'+id;
    return this.http.put( url,proveedor,this.getHeaders() );
  }*/

}
