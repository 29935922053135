import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginacion2',
  templateUrl: './paginacion2.component.html',
  styleUrls: ['./paginacion2.component.css']
})
export class Paginacion2Component implements OnInit {

  @Input() data:any;
  @Output() onSeleccion:EventEmitter<any> = new EventEmitter();

  paginas:number[];

  desde:number;
  hasta:number;

  constructor() { }

  ngOnInit() {
    this.calcular();
  }

  ngOnChanges(changes:SimpleChanges){
    this.calcular();
  }

  private calcular():void{
    this.desde = Math.min( Math.max(1,this.data.Pagina-4),this.data.Final-5 );
    this.hasta = Math.max( Math.min( this.data.Final,this.data.Pagina+4 ),6 );
    if( this.data.Final > 5){
      this.paginas = new Array<number>( this.hasta-this.desde+1 ).fill( 0 ).map( (valor,indice) =>{ return indice + this.desde  });
    }else{
      this.paginas = new Array<number>( this.data.Final ).fill( 0 ).map( (valor,indice) =>{ return indice + 1  });
    }
  }

  cargar( page:any ){
    this.onSeleccion.emit(page);
  }
}
