import { Component, OnInit } from '@angular/core';
import ceosegmento from 'src/app/models/ceosegmento.model';
import { CeoSegmentoPadronesService } from "src/app/services/service/ceoSegmentoPadrones.service";
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';
import Usuario from 'src/app/models/usuario.model';
import ceofiltro from 'src/app/models/ceofiltro.model';
declare var jQuery:any;
declare var $:any;
declare var checbox_marcar;
declare var checbox_marcarSinDeshabilitar;
declare var checbox_desmarcar;
declare var checbox_getvalor_check;
declare var Genero;
declare var GetValorID;
declare var moment;
declare var checbox_verificarmarcas;
declare var swicheryWil;
let _this;
@Component({
  selector: 'app-ceos',
  templateUrl: './ceos.component.html',
  styleUrls: ['./ceos.component.css']
})
export class CeosComponent implements OnInit {
  // ACTIVO: number = 1;
  // INACTIVO: number = 2;
  // RutaPage: string = '/ceos/page';
  bandera_swich: boolean = false;
  pagina:number=0;
  // data: any;
  data:any[];
  termino:string;
  //
  usuario:Usuario = {};
  // 
  CeoSegmentos: ceosegmento[]=[];
  MesEspañol:any =["","Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
  Meses:any = [{M:0,Mes:"Todos"}];
  Ocupacion_:any =[{Id:0,Ocu:"Todos"}];
  UsuarioMes =0;
  UsuarioOcupacion =0;
  Municipios: any [];
  Localidades: any;
  DistritoLocal: any;
  DistritoFederal: any;
  Secciones: any;
  ambitoTipo;
  PoliticoTipo;
  GeoAmbito: any =[
    {
      IdGeoAmbito: 1,
      Nombre: "Administrativo"
    },
    {
      IdGeoAmbito: 2,
      Nombre: "Politico"
    },
  ];
  lbl_tipo;
  GeoTipo: any=[
    {
      IdGeoTipo: 1,
      Nombre: "Municipio"
    },
    {
      IdGeoTipo: 2,
      Nombre: "Localidad"
    }
  ];
  GeoTipoPolitico: any=[
    {
      IdGeoTipo: 1,
      Nombre: "Municipio"
    },
    {
      IdGeoTipo: 2,
      Nombre: "Distrito Local"
    },
    {
      IdGeoTipo: 3,
      Nombre: "Distrito Federal"
    },
    {
      IdGeoTipo: 4,
      Nombre: "Secciones"
    }
  ];
  // 
  Totales_Banner: any[] =[{
    Total_Segmentos: 0,
    Total_Padrones: 0,
    Total_Integtrantes: 0,
    Total_Unicos: -1
  }];
  // filtro 
  Filtro_: ceofiltro={};
  FiltroAplicar_ = new ceofiltro;
  txtFecha1=-1; txtFecha2=-1; txtEdad1="-1"; txtEdad2="-1"; ckGenero=3;
  optAmbito=-1; optTipo=-1; optMunicipio:any=[]; optLocalidad=-1; optDisLocal=0; optDisFederal=0; optDisSecciones=0;
  PersonaTipo: any =[{Tp:'Responsable',id:4},{Tp:'Contactos',id:5}];
  bandera_criterios= false;
  // 
  bandera_aplicar: boolean = false;
  // 
  keyword = 'name';
  data_ = [];
  data2_=[];
  tp=0;
  Lider=0;
  constructor(
    private ceoSegmentoPadronesService: CeoSegmentoPadronesService,
    private activatedRoute: ActivatedRoute,
    private router:Router, public usuarioService:UsuarioService
  ) { 
    this.get(0);
    this.usuario = usuarioService.usuario;
    // this.FiltroAplicar_.IdPadron = ["0"];
    this.FiltroAplicar_.bandera =false;
    // console.log(this.usuario);
  }

  ngOnInit() {
  }
  ngAfterViewInit(){
    _this = this;
    this.componentesJQuery();
    swicheryWil();
  }
  cambioMensaje(){
    this.FiltroAplicar_.BanderaSMS = checbox_verificarmarcas('js-switch');
    this.Aplicar_Servidor();
  }
  cambioMail(){
    this.FiltroAplicar_.BanderaCorreo = checbox_verificarmarcas('js-switch2');
    this.Aplicar_Servidor();
  }
  componentesJQuery(){
    $('.selectpicker_GeoAmbito').selectpicker();
    $('.selectpicker_GeoTipo').selectpicker();
    $('.selectpicker_Municipio').selectpicker();
    $('.selectpicker_Localidad').selectpicker();
    $('.selectpicker_Meses').selectpicker();
    $('.selectpicker_PersonaTipo').selectpicker();
    $('.selectpicker_GeoTipoPolitico').selectpicker();
    $('.selectpicker_distLoc').selectpicker();
    $('.selectpicker_distFed').selectpicker();
    $('.selectpicker_secc').selectpicker();
    $('.selectpicker_Ocupacion').selectpicker();
    this.picker();
  }
  picker(){
      var start = moment().subtract(29, 'days');
      var end = moment();
      function cb(start, end) {
        $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        _this.GetFecha(start,end);
      }
      $('#reportrange').daterangepicker({
          startDate: start,
          endDate: end,
          ranges: {
             'Hoy': [moment(), moment()],
             'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
             'Ultimos 7 Dia': [moment().subtract(6, 'days'), moment()],
             'Ultimos 30 Dia': [moment().subtract(29, 'days'), moment()],
             'Este Mes': [moment().startOf('month'), moment().endOf('month')],
             'Ultimo Mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          }
      }, cb);
  
      cb(start, end);
  }
  GetFecha(start,end){
    // console.log("Fecha "+ start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    this.txtFecha1 = start.format('YYYY/MM/D');
    this.txtFecha2 = end.format('YYYY/MM/D');
  }
  getSelect(params,tipo){
    switch (tipo) {
      case 'getGeoAmbito':
        $('.selectpicker_GeoTipo').selectpicker('val', '0');
        $('.selectpicker_Municipio').selectpicker('val', '0');
        $('.selectpicker_Localidad').selectpicker('val', '0');
        if (params ==1){
          document.getElementById('select_tipo').style.display='block';
          document.getElementById('select_tipoPolitico').style.display = 'none';
          document.getElementById('select_distLoc').style.display='none';
          document.getElementById('select_distFed').style.display='none';
          document.getElementById('select_secc').style.display='none';
          this.ambitoTipo = 'Administrativo';
        }
        if(params ==2){
          this.ambitoTipo = 'Politico';
          // $('.selectpicker_Municipio').selectpicker('val', '0');
          // this.MunicipioPolitico();
          document.getElementById('select_tipoPolitico').style.display = 'block';
          document.getElementById('select_tipo').style.display='none';
          document.getElementById('select_municipio').style.display='none';
        }
        if(params ==0){
          this.ambitoTipo = 'N/A';
          document.getElementById('select_tipoPolitico').style.display = 'none';
          document.getElementById('select_tipo').style.display='none';
          document.getElementById('select_municipio').style.display='none';
          document.getElementById('select_localidad').style.display='none';
        }
        this.optAmbito = params;
      break;
      case 'getTipo':
        $('.selectpicker_Municipio').selectpicker('val', '0');
        $('.selectpicker_Localidad').selectpicker('val', '0');
        if(params == 1){
          this.MunicipiosAdministrativo();
          document.getElementById('select_municipio').style.display='block';
          document.getElementById('select_localidad').style.display='none';
          document.getElementById('select_distLoc').style.display='none';
          document.getElementById('select_distFed').style.display='none';
          document.getElementById('select_secc').style.display='none';
        }
        if(params==2){
          this.MunicipiosAdministrativo();
          document.getElementById('select_municipio').style.display='block';
          document.getElementById('select_localidad').style.display='block';
          document.getElementById('select_distLoc').style.display='none';
          document.getElementById('select_distFed').style.display='none';
          document.getElementById('select_secc').style.display='none';
        }
        if(params ==0){
          document.getElementById('select_municipio').style.display='none';
          document.getElementById('select_localidad').style.display='none';
        }
        this.optTipo = params;
      break;
      case 'getTipoPolitico':
        this.PoliticoTipo = params;
        $('.selectpicker_Municipio').selectpicker('val', '0');
        document.getElementById('select_municipio').style.display='block';
        this.MunicipioPolitico();
        if (params == 1) {
          // this.MunicipioPolitico();
          document.getElementById('select_distLoc').style.display='none';
          document.getElementById('select_distFed').style.display='none';
          document.getElementById('select_secc').style.display='none';
        }
        if (params == 2) {
          document.getElementById('select_distLoc').style.display='block';
          document.getElementById('select_distFed').style.display='none';
          document.getElementById('select_secc').style.display='none';
        }
        if (params == 3) {
          document.getElementById('select_distLoc').style.display='none';
          document.getElementById('select_distFed').style.display='block';
          document.getElementById('select_secc').style.display='none';
        }
        if (params == 4) {
          document.getElementById('select_distLoc').style.display='none';
          document.getElementById('select_distFed').style.display='none';
          document.getElementById('select_secc').style.display='block';
        }
        if (params == 0) {
          document.getElementById('select_municipio').style.display='none';
          document.getElementById('select_distLoc').style.display='none';
          document.getElementById('select_distFed').style.display='none';
          document.getElementById('select_secc').style.display='none';
        }
      break;
      case 'getMunicipio':
        this.optMunicipio = $('.selectpicker_Municipio').val();
        if ( this.ambitoTipo == 'Administrativo' && this.optMunicipio.length != 0) {
          this.getLocalidad(); 
        }
        if(this.ambitoTipo == 'Politico' &&this.optMunicipio.length != 0){
          if (this.PoliticoTipo == 2) {
            this.getDistritoLocal(); 
          }
          if (this.PoliticoTipo == 3) {
            this.getDistritoFederal();
          }
          if (this.PoliticoTipo == 4) {
            this.getSecciones();
          }
        }else{
          this.Localidades = [];
        }
        $('.selectpicker_Localidad').selectpicker('val', '0');
      break;
      case 'getLocalidad':
        this.optLocalidad = params;
      break;
      case 'getMeses':
        this.UsuarioMes = params;
      break;
      case 'getOcupacion':
        this.UsuarioOcupacion = params;
      break;
      case 'getDistritoLocal':
        this.optDisLocal = params;
      break;
      case 'getDistritoFederal':
        this.optDisFederal = params;
      break;
      case 'getSecciones':
        this.optDisSecciones = params;
      break;
      case 'getTipoPersona':
        this.tp =params;
        this.Lider = params;
        this.getMeses();
        this.getOcupacion();
        break;
      default:
        break;
    };
  }
  getLocalidad(){
    let m = {Municipio: this.optMunicipio}
    this.ceoSegmentoPadronesService.getLocalidadAdministrativo(m).subscribe(res =>{
      this.Localidades = res;
      setTimeout(()=>{
        $('.selectpicker_Localidad').selectpicker('refresh');
       }, 1000)
    })  
  }
  getDistritoLocal(){
    let m = {Municipio: this.optMunicipio}
    this.ceoSegmentoPadronesService.getDistritoLocalPolitico(m).subscribe(res =>{
      this.DistritoLocal = res;
      setTimeout(()=>{
        $('.selectpicker_distLoc').selectpicker('refresh');
       }, 1000)
    })
    // console.log(m)
  }
  getDistritoFederal(){
    let m = {Municipio: this.optMunicipio}
    this.ceoSegmentoPadronesService.getDistritoFederalPolitico(m).subscribe(res =>{
      this.DistritoFederal = res;
      setTimeout(()=>{
        $('.selectpicker_distFed').selectpicker('refresh');
       }, 1000)
    })
  }
  getSecciones(){
    let m = {Municipio: this.optMunicipio}
    this.ceoSegmentoPadronesService.getSeccionesPolitico(m).subscribe(res =>{
      this.Secciones = res;
      setTimeout(()=>{
        $('.selectpicker_secc').selectpicker('refresh');
       }, 1000)
    })
  }
  reset(){
    this.Filtro_ ={bandera:false};
    $('.selectpicker_GeoAmbito').selectpicker('val', '0');
    $('.selectpicker_GeoTipo').selectpicker('val', '0');
    $('.selectpicker_Municipio').selectpicker('val', '0');
    $('.selectpicker_Localidad').selectpicker('val', '0');
    $('.selectpicker_Meses').selectpicker('val', '0');
    $('.selectpicker_Ocupacion').selectpicker('val', '0');
    document.getElementById('select_tipoPolitico').style.display = 'none';
    document.getElementById('select_tipo').style.display='none';
    document.getElementById('select_municipio').style.display='none';
    document.getElementById('select_localidad').style.display='none';
    document.getElementById('select_distLoc').style.display='none';
    document.getElementById('select_distFed').style.display='none';
    document.getElementById('select_secc').style.display='none';

    checbox_desmarcar('checkbox_tb1');
    this.FiltroAplicar_ = this.Filtro_;
    this.bandera_swich =false;
    document.getElementById('div_sms').style.display='none';
    document.getElementById('div_correo').style.display='none';
    document.getElementById('div_busq').style.display='block';
    this.UsuarioMes = 0;
    this.UsuarioOcupacion=0;
    this.get(0);
  }
  Aplicar(){
      this.txtEdad1 = GetValorID('txtEdad1','txt');
      this.txtEdad2 = GetValorID('txtEdad2','txt');
      let G = Genero();
      // this.reset();

      this.Filtro_.Periodo1 = this.txtFecha1;
      this.Filtro_.Periodo2 = this.txtFecha2;
      this.Filtro_.AmbitoGeografico = this.optAmbito.toString();
      this.Filtro_.Tipo = this.optTipo.toString();
      this.Filtro_.Municipio = this.optMunicipio;
      this.Filtro_.Localidad= this.optLocalidad.toString();
      this.Filtro_.Edad1 = this.txtEdad1;
      this.Filtro_.Edad2 = this.txtEdad2;
      this.Filtro_.Genero = G;
      this.Filtro_.Mes = this.UsuarioMes;
      this.Filtro_.Ocupacion = this.UsuarioOcupacion;
      this.Filtro_.DistritoLocal = this.optDisLocal.toString();
      this.Filtro_.DsitritoFederal = this.optDisFederal.toString();
      this.Filtro_.DsitritoSeccion = this.optDisSecciones.toString();
      this.Filtro_.bandera = true;
      this.Filtro_.TipoPersona = this.tp;
      // console.log(this.Filtro_);
      if(this.txtFecha1 == -1)
        delete this.Filtro_.Periodo1;
      if(this.txtFecha2 == -1)
        delete this.Filtro_.Periodo2;
      if(this.optAmbito == -1)
        delete this.Filtro_.AmbitoGeografico;
      if(this.optTipo == -1)
        delete this.Filtro_.Tipo;
      if(this.optMunicipio.length == 0)
        delete this.Filtro_.Municipio;
      if(this.optLocalidad == -1)
        delete this.Filtro_.Localidad;
      if(this.txtEdad1 == "-1" || this.txtEdad1 == "")
        delete this.Filtro_.Edad1;        
      if(this.txtEdad2 == "-1" || this.txtEdad2 == "")
        delete this.Filtro_.Edad2;    
      if(G == -1)
        delete this.Filtro_.Genero;
      if(this.UsuarioMes == 0)
      delete this.Filtro_.Mes;
      if(this.UsuarioOcupacion == 0)
      delete this.Filtro_.Ocupacion;
      if(this.optDisLocal == 0)
        delete this.Filtro_.DistritoLocal;
      if(this.optDisFederal == 0)
        delete this.Filtro_.DsitritoFederal;
      if(this.optDisSecciones == 0)
        delete this.Filtro_.DsitritoSeccion;
      if(this.tp == 0)
        delete this.Filtro_.TipoPersona;
      // console.log(this.Filtro_);
      this.bandera_criterios = true;
      let id__ =[];
      this.ceoSegmentoPadronesService.getSegmentosPageFiltros(this.pagina,this.Filtro_).subscribe(res=>{
        
        for (let index = 0; index < res.length; index++) {
          for (let index2 = 0; index2 < res[index]['Padrones'].length; index2++) {
            id__.push(res[index]['Padrones'][index2]['IdPadron']);
          }          
        }
        checbox_marcar('checkbox_tb1',id__);
        this.cambioscheck();
      });
  }
  async Aplicar_Servidor(){
    this.Filtro_.bandera = true;
    
    let id_select = checbox_getvalor_check('checkbox_tb1');
    this.Filtro_.IdPadron = id_select;
    this.FiltroAplicar_ = await {};
    setTimeout(()=>{
      this.FiltroAplicar_ = this.Filtro_;
      this.bandera_swich= true;
      document.getElementById('div_sms').style.display='block';
      document.getElementById('div_correo').style.display='block';
      document.getElementById('div_busq').style.display='none';
     }, 1000)

    // console.log(this.FiltroAplicar_)
    // console.log(id_select)
  }
  btnCriterio_restablecer(){
    this.reset();
    this.bandera_criterios = false;
    this.bandera_aplicar = false;
    this.FiltroAplicar_.bandera==false;
  }
  async get( page:number  ) {
    // this.pagina = page;
    //   await this.ceoSegmentoPadronesService.getSegmentosPage(page)
    //   .subscribe(async users => {
    //       this.data = users.data[0];
    //       this.CeoSegmentos = users.data;

    //       for (let index_Se = 0; index_Se < this.CeoSegmentos.length; index_Se++) {
    //         for (let index_Pa = 0; index_Pa <  this.CeoSegmentos[index_Se].Padrones.length; index_Pa++) {
    //           this.data_.push({id: this.CeoSegmentos[index_Se].Padrones[index_Pa].IdPadron, name: this.CeoSegmentos[index_Se].Padrones[index_Pa].Padron});
    //           await this.data2_.push(this.CeoSegmentos[index_Se].Padrones[index_Pa].IdPadron);
    //         }
    //       }
    //       // console.log(this.data2_)
    //       this.FiltroAplicar_.IdPadron = this.data2_;
    //       this.getTotalesBaner();
    //       this.getMeses();
    //     });
    this.data2_ =[];
    this.ceoSegmentoPadronesService.getSegmentosPage( (page || 0) )
    .subscribe((res)=>{ 
      this.CeoSegmentos = res.data;
      this.data = res.data[0];
        for (let index_Se = 0; index_Se < this.CeoSegmentos.length; index_Se++) {
          for (let index_Pa = 0; index_Pa <  this.CeoSegmentos[index_Se].Padrones.length; index_Pa++) {
            this.data_.push({id: this.CeoSegmentos[index_Se].Padrones[index_Pa].IdPadron, name: this.CeoSegmentos[index_Se].Padrones[index_Pa].Padron});
            this.data2_.push(this.CeoSegmentos[index_Se].Padrones[index_Pa].IdPadron);
          }
        }
      this.FiltroAplicar_.IdPadron = this.data2_;
      this.getTotalesBaner();
      this.getMeses();
      this.getOcupacion();
    })
  }
  onSeleccion( event ){
    this.get( event);
  }
  MunicipiosAdministrativo(){
    this.ceoSegmentoPadronesService.getMunicipiosAdministrativo().subscribe(res=>{
      this.Municipios = res;
      setTimeout(()=>{
        $('.selectpicker_Municipio').selectpicker('refresh');
       }, 1000)
    });
  }
  MunicipioPolitico(){
    this.ceoSegmentoPadronesService.getMunicipiosPolitico().subscribe(res=>{
      this.Municipios = res;
      setTimeout(()=>{
        $('.selectpicker_Municipio').selectpicker('refresh');
       }, 1000)
    });
  }
  getMeses(){
    this.Meses = [{M:0,Mes:"Todos"}];
    this.ceoSegmentoPadronesService.getMeses(this.Lider).subscribe(res =>{
      // console.log(res)
      for (let index = 0; index < res.length; index++) {
        this.Meses.push({M:res[index].M,Mes:this.MesEspañol[res[index].M]});
      }
      setTimeout(()=>{
        $('.selectpicker_Meses').selectpicker('refresh');
       }, 1000)
    });
  }
  getOcupacion(){
    this.Ocupacion_ =[{Id:0,Ocu:"Todos"}];
    this.ceoSegmentoPadronesService.getOcupacion(this.Lider).subscribe(res =>{
      // console.log(res)
      for (let index = 0; index < res.length; index++) {
        this.Ocupacion_.push({Id:res[index].IdOcupacion, Ocu:res[index].Ocupacion});
        // this.Ocupacion_ = res.data;
      }
      setTimeout(()=>{
        $('.selectpicker_Ocupacion').selectpicker('refresh');
       }, 1000)
    });
  }
  getTotalesBaner(){
    let toSeg=0; let toInte=0;
    for (let index = 0; index < this.CeoSegmentos.length; index++) {
      toSeg = toSeg +this.CeoSegmentos[index].Padrones.length;
      for (let index2 = 0; index2 <this.CeoSegmentos[index].Padrones.length; index2++) {
        toInte = toInte +this.CeoSegmentos[index].Padrones[index2].Total;
      }
    }
    this.Totales_Banner=
    [{
      Total_Segmentos: this.CeoSegmentos.length,
      Total_Padrones: toSeg,
      Total_Integtrantes: toInte,
      Total_Unicos: 0
    }];
  }
  cambioscheck(){
    if (checbox_verificarmarcas('checkbox_tb1')==true) {
      this.bandera_aplicar= true;
      this.bandera_criterios = true;
    }else{
      this.bandera_aplicar= false;
      this.bandera_criterios = false;
    }
    this.FiltroAplicar_.bandera==false;
  }
  selectEvent(item) {
    let id__ =[];
    id__.push(item.id);
    checbox_marcarSinDeshabilitar('checkbox_tb1',id__);
    this.cambioscheck();
    this.bandera_criterios = true;
  }
  onChangeSearch(val: string) {
    // console.log(val)
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  onFocused(e){
    // console.log(e)
    // do something when input is focused
  }
}