import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';

@Component({
  selector: 'app-grafica-avance-registro',
  templateUrl: './grafica-avance-registro.component.html',
  styleUrls: ['./grafica-avance-registro.component.css']
})
export class GraficaAvanceRegistroComponent implements OnInit {
  @Input() Filtro_: any;
  dashboard = [];

  highcharts = Highcharts;
  chartOptions;
  
  fechaInicio: string;
  fechaFinal: string;

  constructor(
    private _dashboardService:DashboardService,
    private router:Router,
  ) { }

  ngOnInit() {
    var start = moment().format('YYYY-MM-DD');
    var end = moment().format('YYYY-MM-DD');

    this.getGraficaAvanceRegistro(start, end);
  }

  ngOnChanges() {
    var fechaI = moment(this.Filtro_.fechaInicio).format("YYYY-MM-DD");
    var fechaF = moment(this.Filtro_.fechaFinal).format("YYYY-MM-DD");
    
    this.getGraficaAvanceRegistro(fechaI, fechaF);
  }

  getGraficaAvanceRegistro(fechaInicio: string, fechaFinal: string){
    let categories = [];
    let militantes = [];
    let plantilla = [];
    
    this._dashboardService.getGraficaAvanceRegistro(fechaInicio, fechaFinal)
    .subscribe( res=>{
        res.forEach(row => {
          if (parseInt(row.CorreosEnviados) + parseInt(row.MensajesEnviados) > 0){
            categories.push(formatDate(row.fecha, 'dd-MM-yyyy', 'en-US'))
            militantes.push(parseInt(row.CorreosEnviados))
            plantilla.push(parseInt(row.MensajesEnviados))
          }
        });
        
        this.chartOptions = {
            chart: {
              type: "spline"
            },
            title: {
              text: "Avance de envio"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Totales"
            } 
            },
            series: [
              {
                name: 'Total Militantes',
                data: militantes
              },
              {
                name: 'Total Plantilla',
                data: plantilla
              }
            ]
          };
      });
  }
}
