import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import { GraficaEncabezadoComponent } from 'src/app/pages/dashboard/grafica-encabezado/grafica-encabezado.component';
import { GraficaProyectosComponent } from 'src/app/pages/dashboard/grafica-proyectos/grafica-proyectos.component';
import { GraficaGeneroEdadesComponent } from 'src/app/pages/dashboard/grafica-genero-edades/grafica-genero-edades.component';
import { GraficaCorreosComponent } from 'src/app/pages/dashboard/grafica-correos/grafica-correos.component';
import { GraficaMensajesComponent } from 'src/app/pages/dashboard/grafica-mensajes/grafica-mensajes.component';
import { GraficaEncabezadoSegmentoComponent } from 'src/app/pages/dashboard/grafica-encabezado-segmento/grafica-encabezado-segmento.component';
import { TablaContactosSegmentoComponent } from 'src/app/pages/dashboard/tabla-contactos-segmento/tabla-contactos-segmento.component';
import { DispersionEysSegmentoComponent } from 'src/app/pages/dashboard/dispersion-eys-segmento/dispersion-eys-segmento.component';
import { GraficaEncabezado2Component } from 'src/app/pages/dashboard/grafica-encabezado2/grafica-encabezado2.component';
import { TablaContactosPadronComponent } from 'src/app/pages/dashboard/tabla-contactos-padron/tabla-contactos-padron.component';
import { DispersionEysPadronComponent } from 'src/app/pages/dashboard/dispersion-eys-padron/dispersion-eys-padron.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})

export class DashboardComponent implements OnInit 
{
  @ViewChild(GraficaEncabezadoComponent, { static: false }) childEC: GraficaEncabezadoComponent;
  
  @ViewChild(GraficaProyectosComponent, { static: false }) childPC: GraficaProyectosComponent;
  @ViewChild(GraficaGeneroEdadesComponent, { static: false }) childGEC: GraficaGeneroEdadesComponent;

  @ViewChild(GraficaCorreosComponent, { static: false }) childGCC: GraficaCorreosComponent;
  @ViewChild(GraficaMensajesComponent, { static: false }) childGMC: GraficaMensajesComponent;

  @ViewChild(GraficaEncabezadoSegmentoComponent, { static: false }) childGESC: GraficaEncabezadoSegmentoComponent;
  @ViewChild(TablaContactosSegmentoComponent, { static: false }) childTCSC: TablaContactosSegmentoComponent;
  @ViewChild(DispersionEysSegmentoComponent, { static: false }) childDSC: DispersionEysSegmentoComponent;

  @ViewChild(GraficaEncabezado2Component, { static: false }) childGE2C: GraficaEncabezado2Component;
  @ViewChild(TablaContactosPadronComponent, { static: false }) childTCPC: TablaContactosPadronComponent;
  @ViewChild(DispersionEysPadronComponent, { static: false }) childDPC: DispersionEysPadronComponent;
  
  title = 'Dashboard';
  Filtro_: any = {
                    fechaInicio: " ",
                    fechaFinal: " "
                  }
  

  IdPerfil: number;

  constructor(
      public _serviceUsuario:UsuarioService
  ) {     
  }

  ngOnInit()
  {
    let usuario = this._serviceUsuario.getSession()
    this.IdPerfil = this._serviceUsuario.getSession().IdPerfil;
  }

  actualizar() {
    this.childEC.ngOnInit();
    this.actualizar_contenido(this.Filtro_)

    if (this.IdPerfil == 1)
    {
      this.childPC.ngOnInit();
      this.childGEC.ngOnInit();
    }

    if (this.IdPerfil == 2)
    {
      this.childGCC.ngOnInit();
      this.childGMC.ngOnInit();
    }

    if (this.IdPerfil == 3)
    {
      this.childGESC.ngOnInit();
      this.childTCSC.ngOnInit();
      this.childDSC.ngOnInit();
    }

    if (this.IdPerfil == 4)
    {
      this.childGE2C.ngOnInit();
      this.childTCPC.ngOnInit();
      this.childDPC.ngOnInit();
    }
  }

  actualizar_contenido(e) {
    this.Filtro_ = {
                      fechaInicio: e.fechaInicio,
                      fechaFinal: e.fechaFinal,
                   }
  }
}