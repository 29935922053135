import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-email',
  templateUrl: './modal-email.component.html',
  styleUrls: ['./modal-email.component.css']
})
export class ModalEmailComponent implements OnInit {
  @Input() ShowModalEmail:boolean;
  @Input() personas: Array<any> = [];
  @Output() cerrarModal:EventEmitter<boolean> = new EventEmitter();

  oculto:string = 'oculto';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    if( this.ShowModalEmail ){
      this.oculto = '';
    }else{
      this.oculto = 'oculto';
    }
  }

  cerrar(){
    this.cerrarModal.emit( false );
  }

}