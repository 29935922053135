import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import * as Highcharts from 'highcharts';
import { ɵINTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic';

@Component({
  selector: 'app-grafica-proyectos',
  templateUrl: './grafica-proyectos.component.html',
  styleUrls: ['./grafica-proyectos.component.css']
})
export class GraficaProyectosComponent implements OnInit {
  @ViewChild ('contenedorProyectos', {static : true})  chartDivProyectos: ElementRef;

  RutaPage: string = '/dashboard/page';
  
  chartOptions;
  
  constructor(
    private _dashboardService:DashboardService,
  ) {  }

  ngOnInit() {
    this.getGraficaProyectos();
  }

  getGraficaProyectos(){
    let categories = [];
    let contactos = [];
    let sms = [];
    
    this._dashboardService.getGraficaProyectos()
    .subscribe( res=>{
        res.forEach(row => {
            categories.push(row.Proyecto)
            contactos.push(parseInt(row.totalUnicos))
        });
        // console.log(categories);
        // console.log(contactos);

        this.chartOptions = {
            chart: {
              type: "column"
            },
            title: {
              text: "Proyectos"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Contactos"
              } 
            },
            plotOptions: {
              series: {
                  colorByPoint: true
              }
            },
            credits: {
              enabled: false
            },
            series: [
              {
                name: 'Contactos',
                data: contactos,
                showInLegend: false
              }
            ],
          };

        Highcharts.chart(this.chartDivProyectos.nativeElement, this.chartOptions);

      });
  }

}