import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Usuario from 'src/app/models/usuario.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PerfilService } from 'src/app/services/service/perfil.service';
import Perfil from 'src/app/models/perfil.model';
import { UsuarioService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';
import Contacto from 'src/app/models/contacto.model';
import { ContactoService } from 'src/app/services/service/contacto.service';

declare function setFocus(params:string);
declare function mascara(params:string);

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css']
})
export class UsuarioFormComponent implements OnInit {

  titulo:string='';
  contacto:Contacto = new Contacto();
  perfiles:Perfil[] = [];

  IdProyecto:number = -1;
  page:number = 0;

  constructor(
    private perfilService:PerfilService,
    private contactoService:ContactoService,
    private activatedRoute:ActivatedRoute,
    private router:Router
  ) {
    this.contacto.IdPerfil = 2;
   }

  ngOnInit() {

    //this.getPerfiles();
    this.getUsuario();


  }

  /*getPerfiles(){
    this.perfilService.getPerfilesOtros()
      .subscribe( res =>{
        this.perfiles = res;
      });
  }*/

  getUsuario( ){
    this.IdProyecto = +this.activatedRoute.snapshot.params.IdProyecto;
    this.page = +this.activatedRoute.snapshot.params.page;
    let id: number = +this.activatedRoute.snapshot.params.id;

      if (!id) { 
        this.titulo = 'Nuevo CEO';
        mascara('telefono-ceo');
        setFocus('inputNombreceo');

      }else{
        this.titulo = 'Editar CEO';
        this.contactoService.getContacto( id )
          .subscribe( res =>{
            res.IdPerfil = res.IdPerfil;
            delete res.IdPerfil;
            delete res.Perfil;
            this.contacto = res;
            mascara('telefono-ceo');
            setFocus('inputNombreceo');
          });
      }

      
  }

  save(){
    this.contacto.IdProyecto = this.IdProyecto;
    ///NUEVO
    if( !this.contacto.IdContacto ){
      this.contactoService.crear( this.contacto )
      .subscribe( res =>{
        Swal.fire(
          'Guardado!',
          'usuario guardado con exito',
          'success'
        );
        this.regresar();
      });
    }else{
      //EDITAR
      let u = this.contacto;
      delete u.Password;
      this.contactoService.editar( this.contacto.IdContacto,u )
      .subscribe( res => {
        Swal.fire(
          'Editado!',
          'usuario editado con exito',
          'success'
        );
        this.regresar();
      })
    }
    
  }///fin save

  regresar(){
    this.router.navigateByUrl('/usuarios/page/'+this.IdProyecto+'/'+this.page);
  }

}
