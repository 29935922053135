import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { PageComponent } from './page.component';
import { SharedModule } from '../shared/shared.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { JwPaginationModule } from 'jw-angular-pagination';

//RUTAS
import { PAGES_ROUTES } from './page.routes';
import { PaginacionComponent } from '../components/paginacion/paginacion.component';
import { Paginacion2Component } from '../components/paginacion2/paginacion2.component';
import { UsuarioFormComponent } from './usuarios/form/usuario-form/usuario-form.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProveedorFormComponent } from './proveedores/proveedor-form/proveedor-form.component';
import { AsignacionComponent } from './usuarios/asignacion/asignacion.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { ProyectoFormComponent } from './proyectos/proyecto-form/proyecto-form.component';
import { SegmentosComponent } from './segmentos/segmentos.component';
import { SegmentoFormComponent } from './segmentos/segmento-form/segmento-form.component';
import { PadronesComponent } from './padrones/padrones.component';
import { FormPadronComponent } from './padrones/form-padron/form-padron.component';
import { GraficaEnviosComponent } from './dashboard/grafica-envios/grafica-envios.component';
import { GraficaEncabezadoComponent } from './dashboard/grafica-encabezado/grafica-encabezado.component';
import { GraficaMensajesComponent } from './dashboard/grafica-mensajes/grafica-mensajes.component';
import { TablaEmailComponent } from './dashboard/tabla-email/tabla-email.component';
import { TablaSmsComponent } from './dashboard/tabla-sms/tabla-sms.component';
import { FormContactoComponent } from './padrones/form-contacto/form-contacto.component';
import { FormUnicosComponent } from './padrones/form-unicos/form-unicos.component';
import { CeosComponent } from './ceos/ceos.component';
import { GraficasComponent } from './ceos/graficas/graficas.component';
import { GraficasBarrasComponent } from './ceos/graficas/graficas-barras/graficas-barras.component';
import { DaterangepickerComponent } from './dashboard/daterangepicker/daterangepicker.component';
import { MapaContactosComponent } from './padrones/mapa-contactos/mapa-contactos.component';
import { GraficaAvanceRegistroComponent } from './dashboard/grafica-avance-registro/grafica-avance-registro.component';
import { ModalEmailComponent } from './dashboard/modal-email/modal-email.component';
import { ModalSmsComponent } from './dashboard/modal-sms/modal-sms.component';
import { GraficaProyectosComponent } from './dashboard/grafica-proyectos/grafica-proyectos.component';
import { CargarImagenComponent } from '../components/cargar-imagen/cargar-imagen.component';
import { GraficaGeneroEdadesComponent } from './dashboard/grafica-genero-edades/grafica-genero-edades.component';
import { TablaContactosSegmentoComponent } from './dashboard/tabla-contactos-segmento/tabla-contactos-segmento.component';
import { DispersionEysSegmentoComponent } from './dashboard/dispersion-eys-segmento/dispersion-eys-segmento.component';
import { ConfiguracionComponent } from './ceos/configuracion/configuracion.component';
import { PlantillasComponent } from './ceos/plantillas/plantillas.component';
import { CaracteristicaTipoComponent } from './ceos/caracteristica-tipo/caracteristica-tipo.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { TablaContactosPadronComponent } from './dashboard/tabla-contactos-padron/tabla-contactos-padron.component';
import { DispersionEysPadronComponent } from './dashboard/dispersion-eys-padron/dispersion-eys-padron.component';
import { ModalEmailPreviewComponent } from './dashboard/modal-email-preview/modal-email-preview.component';
import { GraficaEncabezado2Component } from './dashboard/grafica-encabezado2/grafica-encabezado2.component';
import { GraficaEncabezadoSegmentoComponent } from './dashboard/grafica-encabezado-segmento/grafica-encabezado-segmento.component';
import { MapaDeysPadronMunicipioComponent } from './dashboard/mapa-deys-padron-municipio/mapa-deys-padron-municipio.component';
import { MapaDeysPadronDistritoLocalComponent } from './dashboard/mapa-deys-padron-distrito-local/mapa-deys-padron-distrito-local.component';
import { MapaDeysPadronSeccionComponent } from './dashboard/mapa-deys-padron-seccion/mapa-deys-padron-seccion.component';
import { MapaDeysSegmentoDistritoLocalComponent } from './dashboard/mapa-deys-segmento-distrito-local/mapa-deys-segmento-distrito-local.component';
import { MapaDeysSegmentoMunicipioComponent } from './dashboard/mapa-deys-segmento-municipio/mapa-deys-segmento-municipio.component';
import { MapaDeysSegmentoSeccionComponent } from './dashboard/mapa-deys-segmento-seccion/mapa-deys-segmento-seccion.component';
import { MapaDeysPadronDistritoFederalComponent } from './dashboard/mapa-deys-padron-distrito-federal/mapa-deys-padron-distrito-federal.component';
import { MapaDeysSegmentoDistritoFederalComponent } from './dashboard/mapa-deys-segmento-distrito-federal/mapa-deys-segmento-distrito-federal.component';
import { GraficaCorreosComponent } from './dashboard/grafica-correos/grafica-correos.component';
// import { FileSaverModule } from 'ngx-filesaver/ngx-filesaver';

@NgModule({
    declarations:[
        DashboardComponent,
        ClientesComponent,
        ProveedoresComponent,
        UsuariosComponent,
        PageComponent,
        PaginacionComponent,
        Paginacion2Component,
        UsuarioFormComponent,
        ProveedorFormComponent,
        AsignacionComponent,
        ProyectosComponent,
        ProyectoFormComponent,
        SegmentosComponent,
        SegmentoFormComponent,
        PadronesComponent,
        FormPadronComponent,
        GraficaEnviosComponent,
        GraficaEncabezadoComponent,
        GraficaMensajesComponent,
        TablaEmailComponent,
        TablaSmsComponent,
        FormContactoComponent,
        FormUnicosComponent,
        CeosComponent,
        GraficasComponent,
        GraficasBarrasComponent,
        DaterangepickerComponent,
        MapaContactosComponent,
        GraficaAvanceRegistroComponent,
        ModalEmailComponent,
        ModalSmsComponent,
        GraficaProyectosComponent,
        CargarImagenComponent,
        GraficaGeneroEdadesComponent,
        TablaContactosSegmentoComponent,
        DispersionEysSegmentoComponent,
        ConfiguracionComponent,
        ConfiguracionComponent,
        PlantillasComponent,
        CaracteristicaTipoComponent,
        TablaContactosPadronComponent,
        DispersionEysPadronComponent,
        ModalEmailPreviewComponent,
        GraficaEncabezado2Component,
        GraficaEncabezadoSegmentoComponent,
        MapaDeysPadronMunicipioComponent,
        MapaDeysPadronDistritoLocalComponent,
        MapaDeysPadronSeccionComponent,
        MapaDeysSegmentoDistritoLocalComponent,
        MapaDeysSegmentoMunicipioComponent,
        MapaDeysSegmentoSeccionComponent,
        MapaDeysPadronDistritoFederalComponent,
        MapaDeysSegmentoDistritoFederalComponent,
        GraficaCorreosComponent
    ],
    exports:[
        DashboardComponent,
        ClientesComponent,
        ProveedoresComponent,
        UsuariosComponent,
        PageComponent,
        CargarImagenComponent ,
        JwPaginationModule
    ],
    imports:[
        SharedModule,
        PAGES_ROUTES,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        HighchartsChartModule,
        JwPaginationModule
        // FileSaverModule 
    ]
})

export class PageModule{}