import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UsuarioService } from '../service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
declare function cargando();
declare function cargado();
@Injectable({
    providedIn: 'root'
  })
export class CeoCaracteristicaTipo {
    constructor(
        private http:HttpClient,
        private router:Router,
        private usuarioService:UsuarioService
    ) { }
    private getHeaders(){
        return {
            headers: new HttpHeaders({'Authorization':this.usuarioService.token})
        };
    }
    getCaracteristica( page:number ){
        let url = URL_SERVICIOS + '/caracteristica/'+page;
        cargando();
        return this.http.get( url,this.getHeaders() )
        .pipe( 
          map((resp:any)=>{
            cargado();
            if( !resp.ok ){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error intentelo de nuevo',
                footer: ''
              })
            }
            return resp.page;
          }),
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: ex.error.error,
              footer: ''
            })
            return ex;
          })
        );
    }
    postGuardarCaracteristica(Caracteristica: any ){
      let url = URL_SERVICIOS + '/GuardarCaracteristica';
      cargando();
        return this.http.post( url,Caracteristica,this.getHeaders() )
        .pipe(      
          map((resp:any)=>{
            cargado();
          }),       
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un problema al guardar la Caracteristica, intente de nuevo',
              footer: ''
            })
            return ex;
          })
        );
    }
    putCaracteristica(Caracteristica: any ){
      let url = URL_SERVICIOS + '/ActualizarCaracteristica';
      cargando();
        return this.http.post( url,Caracteristica,this.getHeaders() )
        .pipe(        
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un problema al Actualizar la caracteristica, intente de nuevo',
              footer: ''
            })
            return ex;
          })
        );
    }
    // 
    getTipo( page:number ){
        let url = URL_SERVICIOS + '/ceotipo/'+page;
        cargando();
        return this.http.get( url,this.getHeaders() )
        .pipe( 
          map((resp:any)=>{
            cargado();
            if( !resp.ok ){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error intentelo de nuevo',
                footer: ''
              })
            }
            return resp.page;
          }),
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: ex.error.error,
              footer: ''
            })
            return ex;
          })
        );
    }
    postGuardarTipo(Tipo: any ){
      let url = URL_SERVICIOS + '/GuardarTipo';
      cargando();
        return this.http.post( url,Tipo,this.getHeaders() )
        .pipe(      
          map((resp:any)=>{
            cargado();
          }),       
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un problema al guardar el Tipo, intente de nuevo',
              footer: ''
            })
            return ex;
          })
        );
    }
    putTipo(tipo: any ){
      let url = URL_SERVICIOS + '/ActualizarTipo';
      cargando();
        return this.http.post( url,tipo,this.getHeaders() )
        .pipe(        
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un problema al Actualizar el tipo, intente de nuevo',
              footer: ''
            })
            return ex;
          })
        );
    }
}