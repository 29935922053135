import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { UsuarioService } from './usuario.service';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(
    private http: HttpClient,
    private usuarioService:UsuarioService
  ) { }

  getPerfiles(){
    let url = URL_SERVICIOS + '/perfiles';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url,options )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.perfiles;
        })
      );
  }//fin getPerfiles
  getPerfilesOtros(){
    let url = URL_SERVICIOS + '/perfiles-otros';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url,options )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.perfiles;
        })
      );
  }//fin getPerfiles
}
