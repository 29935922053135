export default class Unico{
    public IdUnico?:number;
    public Nombre?:string = "";
    public ApellidoPaterno?:string = "";
    public ApellidoMaterno?:string = "";
    public Genero?:number;
    public Direccion?:string;
    public IdMunicipio?:number;
    public Seccion?:string;
    public FechaNacimiento?:Date;
    public IdEstado?:number;
    public IdOcupacion?:number;
    public IdOficial?:string;

    public Foto?:string;
}