import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../services/service.index';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  foto:string;

  constructor(
    public usuarioService:UsuarioService
  ) { }

  ngOnInit() {
  }

  getFoto(event){
    this.foto = event;
  }

  subirFoto(){
    this.usuarioService.guardarFoto(this.usuarioService.getSession().IdContacto,this.foto)
    .subscribe(res=>{
      location.reload();
    });
  }

}
