import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { UsuarioService } from './usuario.service';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import Proveedor from 'src/app/models/proveedor.model';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService {

  constructor(
    private http:HttpClient,
    private usuarioService:UsuarioService
  ) { }

  private getHeaders(){
    return {
      headers: new HttpHeaders({'Authorization':this.usuarioService.token})
    };
  }

  getProveedor( id:number ){
    let url = URL_SERVICIOS + '/proveedor/'+id;
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        return resp.proveedor;
      })
    );
  }

  getProveedoresPage( page:number ){
    let url = URL_SERVICIOS + '/proveedores/'+page;
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        return resp.page;
      })
    );
  }

  crear( proveedor:Proveedor ){
    let url = URL_SERVICIOS + '/proveedor';
    return this.http.post( url,proveedor,this.getHeaders() );
  }

  editar( id:number,proveedor:Proveedor ){
    let url = URL_SERVICIOS + '/proveedor/'+id;
    return this.http.put( url,proveedor,this.getHeaders() );
  }

  cambioEstatus( id:number,status:number ){
    let url = URL_SERVICIOS + '/proveedor_cambiar_estatus/'+id;
    let proveedor:Proveedor = { Activo:status };
    return this.http.put( url,proveedor,this.getHeaders() );
  }
}
