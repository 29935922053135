import { Component, OnInit, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import Segmento from 'src/app/models/segmento.model';
import Contacto from 'src/app/models/contacto.model';
import { SegmentoService } from 'src/app/services/service/segmento.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';

declare function setFocus(params:string);
declare function mascara(params:string);

@Component({
  selector: 'app-segmento-form',
  templateUrl: './segmento-form.component.html',
  styleUrls: ['./segmento-form.component.css']
})
export class SegmentoFormComponent implements OnInit {

  @ViewChild('FormularioSegmento',{ static: true }) FormularioSegmento:ElementRef;

  titulo:string = 'Nuevo segmento';
  segmento:Segmento = new Segmento();
  contacto:Contacto = new Contacto();

  constructor(
    public _usuarioService:UsuarioService,
    public _segmentoService:SegmentoService,
    public router:Router,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit() {
    this.getSegmento();
  }

  getSegmento(){
    let id: number = +this.activatedRoute.snapshot.params.id;

      if (!id) { 
        this.titulo = 'Nuevo Segmento';
        //this.input.nativeElement.focus();
        mascara('telefono-segmento');
        setFocus('input-segmento');
      }else{
        this.titulo = 'Editar Segmento';
        this._segmentoService.getSegmento(id)
          .subscribe( res =>{
            
            this.segmento = res.segmento;
            this.contacto = res.contacto;
            //this.input.nativeElement.focus();

            mascara('telefono-segmento');
            setFocus('input-segmento');
          });
      }
      this.FormularioSegmento.nativeElement.reset();
  }

  save(){
    
    if( !this.segmento.Segmento ){
      Swal.fire('El nombre del segmento no puede estar vacio!','','warning');
      return;
    }

    if( !this.contacto.Nombre ){
      Swal.fire('El Nombre no puede estar vacio!','','warning');
      return;
    }

    if( !this.contacto.ApellidoPaterno ){
      Swal.fire('El campo apellido no puede estar vacio!','','warning');
      return;
    }

    if( !this.contacto.Correo ){
      Swal.fire('El correo no puede estar vacio!','','warning');
      return;
    }

    if( !this.contacto.Password ){
      Swal.fire('La contraseña no puede estar vacio!','','warning');
      return;
    }

    let data = {
      segmento: this.segmento,
      contacto: this.contacto
    };
    ///NUEVO
    if( !this.segmento.IdSegmento ){

      //agregando el idproyecto
      data.segmento.IdProyecto = this._usuarioService.getSession().IdProyecto;
      data.contacto.IdProyecto = this._usuarioService.getSession().IdProyecto;

      this._segmentoService.crear( data )
      .subscribe( res =>{
        Swal.fire(
          'Guardado!',
          'segmento guardado con exito',
          'success'
        );
        this.router.navigate(['/segmentos']);
      });
    }else{
      //EDITAR
      let u:any = {
        IdSegmento: data.segmento.IdSegmento,
        Segmento: data.segmento.Segmento
      }
      data.segmento = u;

      let c:any = data.contacto;
      delete c.Padron;
      delete c.Segmento;

      data.contacto = c;

      this._segmentoService.editar( this.segmento.IdSegmento,data )
      .subscribe( res => {
        Swal.fire(
          'Editado!',
          'segmento editado con exito',
          'success'
        );
        //this.router.navigateByUrl('/segmentos');
        this.router.navigate(['/segmentos']);
      })
    }
    
  }///fin save

  cancelar(){
    this.router.navigate(['/segmentos']);
  }

}
