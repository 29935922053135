import { Injectable } from '@angular/core';
import Usuario from 'src/app/models/usuario.model';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

declare function cargando();
declare function cargado();

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  public static ACTIVO = 1;
  usuario:Usuario = null;
  token:string = null;

  constructor(
    private http:HttpClient,
    private router:Router
  ) { 
    this.cargarStorage();
  }

  getSession(){
    return this.usuario;
  }

  login( usuario:Usuario ){
    let url:string = URL_SERVICIOS+'/login';
    return this.http.post( url,usuario )
      .pipe( 
        map( ( response:any )  =>{
            sessionStorage.setItem('usuario',JSON.stringify( response.usuario ));
            sessionStorage.setItem('token',response.token);

            this.usuario = response.usuario;
            this.token = response.token;

            return true;
        }),
        catchError(error =>  {
          Swal.fire('Upss!!',(error.error.error.message || error.error.error),'error');
          return error;
        } )
      );
  }

  logout():void{
    this.token = null;
    this.usuario = null;

    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

  logeado():boolean{
    return ( this.token )?true:false;
  }

  cargarStorage(){
    if( sessionStorage.getItem('token') ){
      this.token = sessionStorage.getItem('token');
      this.usuario = JSON.parse( sessionStorage.getItem('usuario') );
    }else{
      this.token = null;
      this.usuario = null;
    }
  }

  getUsuariosPaginacion( usuario:Usuario,pagina:number=0 ){
    let url = URL_SERVICIOS + '/usuarios/'+pagina+'?filtro='+encodeURIComponent( JSON.stringify(usuario) );
    let headers = new HttpHeaders({'Authorization':this.token});
    const options = {
      headers,
      body: {
        a:1
      }
    }
    return this.http.get( url,options )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.page;
        })
      );
  }

  getUsuario( id:number ){
    let url = URL_SERVICIOS + '/usuario/'+id ;
    let headers = new HttpHeaders({'Authorization':this.token});

    return this.http.get( url,{ headers} )
    .pipe(
      map( (resp:any)=>{
        return resp.usuario;
      })
    )
  }

  cambiarEstatus( id:number,status:number ){
    let url = URL_SERVICIOS + '/usuario/'+id;
    let headers = new HttpHeaders({'Authorization':this.token});
    const options = {
      headers,
      body: {
        Activo:status
      }
    }
    return this.http.delete( url, options);
  }

  guardar( usuario:Usuario ){
    let url = URL_SERVICIOS + '/usuario';
    let headers = new HttpHeaders({'Authorization':this.token});
    return this.http.post( url,usuario,{headers} );
  }

  editar( id:number,usuario:Usuario){
    let url = URL_SERVICIOS + '/usuario/'+id;
    let headers = new HttpHeaders({'Authorization':this.token});
    return this.http.put( url,usuario,{headers} );
  }

  cambiarPassword( id:number,usuario:Usuario){
    let url = URL_SERVICIOS + '/cambiarPassword/'+id;
    let headers = new HttpHeaders({'Authorization':this.token});
    return this.http.put( url,usuario,{headers} );
  }


  guardarFoto( id:number,foto:string ){
    let url = URL_SERVICIOS + '/guardarFoto';
    let headers = new HttpHeaders({'Authorization':this.token});

    cargando();
    return this.http.post( url,{id,foto},{headers} ).pipe(
      map((resp:any)=>{
        cargado();
        return resp;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:  err.error.sqlMessage || err.error.error.message || err.error.error,
          footer: ''
        });
        return err;
      })
    );
  }
}
