import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HighchartsChartComponent } from 'highcharts-angular';

import { APP_ROUTES } from './app.routes';

///MODULES
import { PageModule } from './pages/page.module';

//COMPONENTS
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';


//SERVICIOS
import { ServiceModule } from './services/service.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DaterangepickerComponent } from './pages/dashboard/daterangepicker/daterangepicker.component';
import { CargarImagenComponent } from './components/cargar-imagen/cargar-imagen.component';
import { PerfilComponent } from './perfil/perfil.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NopagefoundComponent,
    PerfilComponent
    // DaterangepickerComponent
    //HighchartsChartComponent
    //,PageComponent    
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    PageModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
