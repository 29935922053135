import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../service/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements  CanActivate{

  constructor( 
    private _usuaerioService:UsuarioService,
    private router:Router
  ){

  }

  canActivate() {
    let bol:boolean = this._usuaerioService.logeado();
    if( !bol ){
      this.router.navigateByUrl('/login');
    }

    return bol;
  }
  
}
