import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from '../services/service.index';
import Usuario from '../models/usuario.model';
//import swal from 'sweetalert';

declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  constructor( 
    public router: Router,
    private usuarioService: UsuarioService
   ) { }

  ngOnInit() {
    init_plugins();

    this.form = new FormGroup({
      Usuario: new FormControl( '',Validators.required ),
      Password: new FormControl( '',Validators.required )
    });
  }
  
  login(){
    if( this.form.valid ){
      let usuario:Usuario = this.form.value;
      this.usuarioService.login( usuario )
        .subscribe( response=>{
            this.router.navigate(['/dashboard']);
        });
    }//FIN IF
  }
}
