import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import Mensaje from 'src/app/models/mensaje.model';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tabla-sms',
  templateUrl: './tabla-sms.component.html',
  styleUrls: ['./tabla-sms.component.css']
})
export class TablaSmsComponent implements OnInit {
  @Input() Filtro_: any;
  mensajes: Mensaje[] = [];

  data: any;
  RutaPage: string;
  pagina:number=0;
  items: [];
  pageOfItems: Array<any>;
  ShowModalSms: boolean = false;

  personas: any[] = [];

  constructor(
    private _dashboardService:DashboardService,
    private activatedRoute: ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit() {
    var start = moment().format('YYYY-MM-DD');
    var end = moment().format('YYYY-MM-DD');

    //this.RutaPage = 'tabla-envios/' + start + '/' + end + '/';
    this.getEnviosMensajes(start, end, 0)
  }

  // onChangePage(pageOfItems: Array<any>) {
  //   this.pageOfItems = pageOfItems;
  // }

  ngOnChanges() {
    var fechaI = moment(this.Filtro_.fechaInicio).format("YYYY-MM-DD");
    var fechaF = moment(this.Filtro_.fechaFinal).format("YYYY-MM-DD");

    //this.RutaPage = 'tabla-envios/' + fechaI + '/' + fechaF + '/';
    
    this.getEnviosMensajes(fechaI, fechaF, 0);
  }

  abrirModal(){
    this.ShowModalSms = true;
  }
  
  cerrarModal( event:boolean ){
    this.ShowModalSms = event;
  }

  getPersonas(IdM: Array<any>){
    this.activatedRoute.paramMap.subscribe(params => {
    this._dashboardService.getPersonasSms(IdM)
      .subscribe( res=>{
        console.log(res);
        this.personas = res;
      });
    });

    this.abrirModal();
  }

  onSeleccionEnvios(page: number){
    var fechaI = moment(this.Filtro_.fechaInicio).format("YYYY-MM-DD");
    var fechaF = moment(this.Filtro_.fechaFinal).format("YYYY-MM-DD");

    this.getEnviosMensajes(fechaI, fechaF, page);
  }

  getEnviosMensajes(fechaInicio: string, fechaFinal: string, page:number)
  {
    this.activatedRoute.paramMap.subscribe(params => {
      //let pagina: number = +params.get('page');
      // this.pagina = +params.get('page');

      // if (!this.pagina) { this.pagina = 0 };
      // if( p !== -1 ){ this.pagina = 0 }

    // this._dashboardService.getEnviosMensajes(fechaInicio, fechaFinal, this.pagina)
    //   .subscribe( res=>{
    //     console.log(res);
    //     this.data = res;
    //     this.mensajes = res.data;
    //   });

    this._dashboardService.getEnviosMensajes(fechaInicio, fechaFinal, (page || 0))
      .subscribe( res=>{
        //console.log(res);
        this.data = res;
        this.items = res.data[0];
      });
    });
  }
}