import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { UnicoService } from 'src/app/services/service/unico.service';
import Unico from 'src/app/models/unico.model';
import { MunicipioService } from 'src/app/services/service/municipio.service';
import { EstadoService } from 'src/app/services/service/estado.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { OcupacionService } from 'src/app/services/service/ocupacion.service';
import { IfStmt } from '@angular/compiler';
import { Validaciones } from './validaciones';

declare function setFocus(params:string);
declare function mascar(params:string);
declare function mascaraIdentificacionOficial(params:string);

@Component({
  selector: 'app-form-unicos',
  templateUrl: './form-unicos.component.html',
  styleUrls: ['./form-unicos.component.css']
})
export class FormUnicosComponent implements OnInit {

  @Output() EventoCerrarUnicos:EventEmitter<any> = new EventEmitter();
  @Output() EventoObtenerUnico:EventEmitter<any> = new EventEmitter();

  form:FormGroup;

  modobuscador:boolean = true;
  yabuscaste:boolean = false;

  unico:Unico = new Unico();
  ocupaciones = [];
  data:any[] = [];
  estados:any[] = [];
  model:any = {
    nombre:'',
    apellidoPaterno:'',
    apellidoMaterno:''
  }

  flat:boolean=false;
  requeridos:any[] = [];

  constructor(
    public _unicoService:UnicoService,
    public _municipioService:MunicipioService,
    public _serviceOcupacion: OcupacionService,
    private formBuild:FormBuilder,
    public _estadoService:EstadoService
  ) { }

  ngOnInit() {
    this.flat = true;

    this.buildForm();

    this._estadoService.getEstados()
    .subscribe(res=>{
      this.estados = res
    });
    
    this._serviceOcupacion.getOcupaciones()
    .subscribe(res=>{
        this.ocupaciones = res;
    });

    //
    mascaraIdentificacionOficial('inputIdentificadorUnico');
  }
  ngAfterViewChecked(){
    if(this.flat){
      setFocus('input-nombre-unico');
      this.flat = false;
    }
  }
  ngOnChanges(){
    
  }

  buscar(){
    this._unicoService.getUnicos(this.model.nombre+' '+this.model.apellidoPaterno+' '+this.model.apellidoMaterno)
    .subscribe(res=>{
      if( res.length === 100){
        Swal.fire('Atención','el resultado de la busqueda es muy extensa, por favor ingrese más información para realizar una mejor busqueda','warning');
        return;
      }
      this.data = res;
      this.yabuscaste = true;
    });
  }

  seleccionarUnico(unico:any){
    this.cerrar();
    this.EventoObtenerUnico.emit(unico);
  }

  modoBuscador(){
    this.form.reset();
    this.unico = new Unico();
    this.modobuscador = true;
  }

  cerrar(){
    this.unico = new Unico();
    this.modobuscador = true;
    this.EventoCerrarUnicos.emit(false);
  }

  buildForm(){
    this.form = this.formBuild.group({
      IdOficial: ['',[Validaciones.isIdOficialValido]],
      Nombre: ['',[Validators.required]],
      ApellidoPaterno: ['',[Validators.required]],
      ApellidoMaterno: ['',[]],
      Genero: ['',[Validators.required]],
      FechaNacimiento: ['',[Validators.required]],
      IdEstado: ['',[Validators.required]],
      Seccion: ['',[Validators.required],[Validaciones.validateExistSeccion(this._unicoService)]],
      Manzana: ['',[]],
      IdOcupacion: ['',[Validators.required]]
    })
  }

  add(){
    //VALIDAR FORMULARIO
    this.requeridos = [];

    ///VALIDANDO DEL FORMULARIO
    if( !this.form.valid ){
      this.form.markAllAsTouched();
      return;
    }

    let u = this.form.value;
    if(this.unico.Foto)
      u.Foto = this.unico.Foto;

    if(!u.Manzana)
      delete u.Manzana;
    
    u.ApellidoMaterno = u.ApellidoMaterno || '';

    this._unicoService.crear(u)
    .subscribe(res=>{
      this.form.reset();
      this.modoBuscador();
    });
  }


  getFoto(event:any){
    this.unico.Foto = this.convertirBinario(event);
  }

  convertirBinario(lema:string){
    lema = lema.split("").map(function(item,index){
        return item.charCodeAt(0).toString(2);
    }).join(" ");
    return lema;
  }

  calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }

    return edad;
}
  
}
