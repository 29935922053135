import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-email-preview',
  templateUrl: './modal-email-preview.component.html',
  styleUrls: ['./modal-email-preview.component.css']
})
export class ModalEmailPreviewComponent implements OnInit {
  @Input() ShowModalEmailPreview: boolean;
  @Input() mensaje: string;
  @Output() cerrarModalPreview: EventEmitter<boolean> = new EventEmitter();

  oculto:string = 'oculto';

  constructor() { }

  ngOnInit() {
    //console.log(this.mensaje);
    //document.getElementById('mensaje').innerHTML = this.mensaje;
  }

  ngOnChanges(){
    document.getElementById('mensaje').innerHTML = this.mensaje;
    
    if( this.ShowModalEmailPreview ){
      this.oculto = '';
    }else{
      this.oculto = 'oculto';
    }
  }

  cerrar(){
    this.cerrarModalPreview.emit( false );
  }

}
