import { Component, OnInit, ViewChild, ElementRef, Output,EventEmitter, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { UsuarioService } from 'src/app/services/service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import * as Highcharts from 'highcharts';

declare var google;
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-mapa-deys-padron-seccion',
  templateUrl: './mapa-deys-padron-seccion.component.html',
  styleUrls: ['./mapa-deys-padron-seccion.component.css']
})

export class MapaDeysPadronSeccionComponent implements OnInit {
  @Input() Seccion_: any;
  @ViewChild('map3',{ static: true }) mapaElement:ElementRef;
  @ViewChild ('contenedorEdadesSeccion', {static : true})  chartDivEdadesSeccion: ElementRef;
  @ViewChild ('contenedorOnomasticosSeccion', {static : true})  chartDivOnomasticosSeccion: ElementRef;
  @ViewChild ('contenedorOcupacionesSeccion', {static : true})  chartDivOcupacionesSeccion: ElementRef;
  @ViewChild ('contenedorOrigenSeccion', {static : true})  chartDivOrigenSeccion: ElementRef;

  IdContacto: number;
  IdSeccion: number;
  dashboard: any;

  chartOptions_generoedad_seccion;
  chartOptions_onomastico_seccion;
  chartOptions_ocupacion_seccion;
  chartOptions_origen_seccion;
  
  geoJsonObject: any = {};
  geoJson: any;
  GeoData: any = {};
  bounds = new google.maps.LatLngBounds();
  seccion: number;
  totales: any = {};
  all_polygons: any = [];
  polygon = null;

  infoWindow: google.maps.InfoWindow;
  map: google.maps.Map;
  
  constructor(
    private _dashboardService:DashboardService,
    public _serviceUsuario:UsuarioService
    ) {}

  ngOnInit() 
  {
    $("#sidebar-seccion").slideDown(50), $("#sidebar-seccion").toggleClass("shw-rside");
    this.cargarMapa();
  }

  ngOnChanges() 
  {
    //this.cargarMapa();
    this.getGeoJson(this.Seccion_);
  }

  ngAfterViewInit(){
    this.componentesJQuery();
  }

  componentesJQuery(){
    $('[data-plugin="knob"]').knob();
    $(".scroll-sidebar, .right-side-panel, .message-center, .right-sidebar").perfectScrollbar()
  }

  cargarMapa()
  {
    let latLng = new google.maps.LatLng(18.519742, -88.300422);

    const mapaOpciones: google.maps.MapOptions = {
      center: latLng,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.mapaElement.nativeElement, mapaOpciones);
  }

  getGeoJson(idSeccion)
  {
    this.LimpiaMapa();

    this._dashboardService.getGeoJsonSeccionPadron(idSeccion)
      .subscribe( res=>{
        this.geoJsonObject = res;
        this.GeoData = this.geoJsonObject.geo;
      
        for (let index = 0; index < this.GeoData.length; index++)
        {
          const rows = this.GeoData[index];
          this.PintaPoligono(rows);    
        }

        this.map.fitBounds(this.bounds);
      });
  }

  getInfoSeccion(seccion: number)
  {
    this._dashboardService.getTotalesGeneroSeccion(seccion)
    .subscribe( res=>{
      this.totales = res;

      let t = res.TotalUnicos;
      let p = 0;
      p = (res.Hombres / t) * 100;
    
      $('.GH').val(p+"%").trigger('change');
    
      p = (res.Mujeres / t) * 100;
      $('.GM').val(p+"%").trigger('change');
    });
  }

  getGraficaEdades(seccion: number){
    let categories = ['0-04','05-13','14-17','18-30','31-50','51-70','71-90','91->'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaGenerosSeccion(seccion)
    .subscribe( res=>{
        totalesH = [parseInt(res.HEDAD04) * -1, parseInt(res.HEDAD513) * -1, parseInt(res.HEDAD1417) * -1, parseInt(res.HEDAD1830) * -1, parseInt(res.HEDAD3150) * -1, parseInt(res.HEDAD5170) * -1, parseInt(res.HEDAD7190) * -1, parseInt(res.HEDAD91) * -1]
        totalesM = [parseInt(res.MEDAD04), parseInt(res.MEDAD513), parseInt(res.MEDAD1417), parseInt(res.MEDAD1830), parseInt(res.MEDAD3150), parseInt(res.MEDAD5170), parseInt(res.MEDAD7190), parseInt(res.MEDAD91)]

        this.chartOptions_generoedad_seccion = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Género y Edad"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', edad de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };
        
        Highcharts.chart(this.chartDivEdadesSeccion.nativeElement, this.chartOptions_generoedad_seccion);
        
    });
  }

  getGraficaOnomasticos(seccion: number){
    let categories = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaOnomasticoSeccion(seccion)
    .subscribe( res=>{
        totalesH = [parseInt(res.HENE) * -1, parseInt(res.HFEB) * -1, parseInt(res.HMAR) * -1, parseInt(res.HABR) * -1, parseInt(res.HMAY) * -1, parseInt(res.HJUN) * -1, parseInt(res.HJUL) * -1, parseInt(res.HAGO) * -1, parseInt(res.HSEP) * -1, parseInt(res.HOCT) * -1, parseInt(res.HNOV) * -1, parseInt(res.HDIC) * -1]
        totalesM = [parseInt(res.MENE), parseInt(res.MFEB), parseInt(res.MMAR), parseInt(res.MABR), parseInt(res.MMAY), parseInt(res.MJUN), parseInt(res.MJUL), parseInt(res.MAGO), parseInt(res.MSEP), parseInt(res.MOCT), parseInt(res.MNOV), parseInt(res.MDIC)]

        this.chartOptions_onomastico_seccion = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Onomástico"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', mes de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH            
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivOnomasticosSeccion.nativeElement, this.chartOptions_onomastico_seccion);

    });
  }

  getGraficaOcupaciones(seccion: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOcupacionSeccion(seccion)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Ocupacion)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_ocupacion_seccion = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Ocupación"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOcupacionesSeccion.nativeElement, this.chartOptions_ocupacion_seccion);

      });
  }

  getGraficaOrigen(seccion: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOrigenSeccion(seccion)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Estado)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_origen_seccion = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Lugar de Origen"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOrigenSeccion.nativeElement, this.chartOptions_origen_seccion);
    });
  }

  PintaPoligono(GeoPinta){
    const datos = GeoPinta.GeoJson;
    var coords = datos.coordinates;
    
    var paths = [];

    for (var i = 0; i < coords.length; i++) {
            for (var j = 0; j < coords[i].length; j++) {
                var path = [];
                for (var k = 0; k < coords[i][j].length; k++) {
                    var ll = new google.maps.LatLng(coords[i][j][k][0],coords[i][j][k][1]);
                    path.push(ll);
                }
                paths.push(path);
            }
      }

      this.polygon = new google.maps.Polygon({
        paths: paths,
        strokeColor: "#FF7800",
        strokeOpacity: 1,
        strokeWeight: 5,
        fillColor: "#46461F",
        fillOpacity: 0.25,
      });

      this.polygon.setMap(this.map);
      this.all_polygons.push(this.polygon);
      this.AgregarInfoWindow(this.polygon, GeoPinta.SECCION);
  }

  AgregarInfoWindow(polygon, seccion)
  {
    polygon.addListener('click', e =>{
      this.seccion = seccion;
      this.IdSeccion = seccion;

      this.getInfoSeccion(seccion);
      this.getGraficaEdades(seccion);
      this.getGraficaOnomasticos(seccion);
      this.getGraficaOcupaciones(seccion);
      this.getGraficaOrigen(seccion);

      var modal = document.getElementById("modal-seccion");
      modal.style.display = "block";

      $("body").addClass("modal-open");

      $("#sidebar-seccion").slideDown(50), $("#sidebar-seccion").toggleClass("shw-rside");
    });

    for (var i = 0; i < polygon.getPath().getLength(); i++) {
      this.bounds.extend(polygon.getPath().getAt(i));
    }
  }

  LimpiaMapa(){
    // Solo cuando hay elementos
    if(this.polygon != null){
        // Recorrer el arreglo de todos los poligons
        for (var i = 0; i < this.all_polygons.length; i++) {
          // Eliminar aplicar el null al poligo con la funcion de "setMap"
           this.all_polygons[i].setMap(null);
        }
        // Inicialziando variables
        this.all_polygons = [];
        this.polygon = null;
        this.bounds = new google.maps.LatLngBounds();
    }  
  }

  exportarContactosSeccionDtl(){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-seccion-padron-detalle/' + this.IdContacto + '/' + this.IdSeccion);
  }

  cerrar()
  {
    $("#tabOcupacionesSec").removeClass("active")
    $("#tabOnomasticosSec").removeClass("active")
    $("#tabOrigenSec").removeClass("active")
    $("#tabEdadesSec").addClass("active")

    $("#ocupaciones_seccion").removeClass("active")
    $("#onomasticos_seccion").removeClass("active")
    $("#origen_seccion").removeClass("active")
    $("#edades_seccion").addClass("active")
    
    $("body").removeClass("modal-open")

    $("#sidebar-seccion").delay(300).slideDown(50);
      
    $("#sidebar-seccion").toggleClass("shw-rside").promise().done(function(){
      var modal = document.getElementById("modal-seccion");
      modal.style.display = "none";
    });
  }

}