import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UsuarioService } from '../service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import Proyecto from 'src/app/models/proyecto.model';

declare function cargando();
declare function cargado();

@Injectable({
  providedIn: 'root'
})
export class ProyectoService {

  constructor(
    private http:HttpClient,
    private router:Router,
    private usuarioService:UsuarioService
  ) { }

  private getHeaders(){
    return {
      headers: new HttpHeaders({'Authorization':this.usuarioService.token})
    };
  }

  getProyecto( id:number ){
    let url = URL_SERVICIOS + '/proyecto/'+id;

    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        })
        return err;
      })
    );
  }

  getProyectosPage( page:number ){
    let url = URL_SERVICIOS + '/proyectos/'+page;

    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.page;
      }),
      catchError(ex=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: ex.error.error.message || ex.error.error,
          footer: ''
        })
        return ex;
      })
    );
  }

  crear( data:any ){ 
    let url = URL_SERVICIOS + '/proyecto';

    cargando();
    return this.http.post( url,data,this.getHeaders() )
      .pipe(
        map((resp:any)=>{
          cargado();
          return resp;
        }),
        catchError(err=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.error.message || err.error.error,
            footer: ''
          });
          return err;
        })
      );
  }

  editar( id:number,data:any ){
    let url = URL_SERVICIOS + '/proyecto/'+id;

    cargando();
    return this.http.put( url,data,this.getHeaders() )
    .pipe(
      map((resp:any)=>{
        cargado();
        return resp;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        });
        return err;
      })
    );
  }
}
