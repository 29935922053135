import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Proyecto from 'src/app/models/proyecto.model';
import Contacto from 'src/app/models/contacto.model';
import { ContactoService } from 'src/app/services/service/contacto.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { ProyectoService } from 'src/app/services/service/proyecto.service';

declare function mascara(parametro:string);

@Component({
  selector: 'app-proyecto-form',
  templateUrl: './proyecto-form.component.html',
  styleUrls: ['./proyecto-form.component.css']
})
export class ProyectoFormComponent implements OnInit {
  @ViewChild('input',{static:true}) input:ElementRef;

  titulo:string;
  proyecto:Proyecto = new Proyecto();
  contacto:Contacto = new Contacto();

  constructor(
    private contactoService:ContactoService,
    private proyectoService:ProyectoService,
    private activatedRoute:ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit() {
    this.contacto.IdPerfil = 3;//enlace
    this.getProyecto();

    /*let element:any;
    element = $('#telefono');
    element.mask('(000) 000-0000');*/

    //(<any>$('#telefono')).mask('(000) 000-0000');

  }

  getProyecto( ){
    let id: number = +this.activatedRoute.snapshot.params.id;

      if (!id) { 
        this.titulo = 'Nuevo Proyecto';
        mascara('telefono-mask');
        this.input.nativeElement.focus();

      }else{
        this.titulo = 'Editar Proyecto';
        this.proyectoService.getProyecto( id )
          .subscribe( res =>{
            this.proyecto = {
              IdProyecto: res.proyecto.IdProyecto,
              IdContacto: res.proyecto.IdContacto,
              Proyecto: res.proyecto.Proyecto
            };
            if(res.contacto.Nacimiento != null)
              res.contacto.Nacimiento = res.contacto.Nacimiento.split('T')[0];
            
            this.contacto = res.contacto;
            this.input.nativeElement.focus();
            mascara('telefono-mask');
          });
      }

  }

  save(){

    let data = {
      proyecto: this.proyecto,
      contacto: this.contacto
    };

    //data.contacto.Telefono = data.contacto.Telefono.split(" ").join("").split("(").join("").split(")").join("");

    ///NUEVO
    if( !this.proyecto.IdProyecto ){
      this.proyectoService.crear( data )
      .subscribe( res =>{
        Swal.fire(
          'Guardado!',
          'usuario guardado con exito',
          'success'
        );
        this.router.navigateByUrl('/proyectos');
      });
    }else{
      //EDITAR
      let u = this.proyecto;
      this.proyectoService.editar( this.proyecto.IdProyecto,data )
      .subscribe( res => {
        Swal.fire(
          'Editado!',
          'usuario editado con exito',
          'success'
        );
        this.router.navigateByUrl('/proyectos');
      })
    }
    
  }///fin save
  
}