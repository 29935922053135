import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { UsuarioService } from './usuario.service';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

declare function cargando();
declare function cargado();

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  httpOptions:any;

  constructor(
    private http:HttpClient,
    private usuarioService:UsuarioService
  ) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',
        'Authorization': usuarioService.token
      })
    };

  }

  getMenu(){
    let url = URL_SERVICIOS + '/menu';
    let token = this.usuarioService.token;
    let headers = new HttpHeaders({'Authorization':token});

    cargando();
    return this.http.get( url,{headers})
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        })
        return err;
      })
    );
  }
}
