import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UsuarioService } from '../service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
declare function cargando();
declare function cargado();
@Injectable({
    providedIn: 'root'
  })
export class CeoConfiguracionService {
    constructor(
        private http:HttpClient,
        private router:Router,
        private usuarioService:UsuarioService
    ) { }

    private getHeaders(){
        return {
            headers: new HttpHeaders({'Authorization':this.usuarioService.token})
        };
    }
    getPlantillaPage( page:number ){
        let url = URL_SERVICIOS + '/plantilla/'+page;
        cargando();
        return this.http.get( url,this.getHeaders() )
        .pipe( 
          map((resp:any)=>{
            cargado();
            if( !resp.ok ){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error intentelo de nuevo',
                footer: ''
              })
            }
            return resp.page;
          }),
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: ex.error.error,
              footer: ''
            })
            return ex;
          })
        );
    }
    postGuardarPlantilla(plantilla: any ){
      let url = URL_SERVICIOS + '/GuardarPlantilla';
      cargando();
        return this.http.post( url,plantilla,this.getHeaders() )
        .pipe(        
          map((resp:any)=>{
            cargado();
          }),     
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un problema al guardar la plantilla, intente de nuevo',
              footer: ''
            })
            return ex;
          })
        );
    }
    postImagenPlantilla(identificador: any, foto:string ){
      let url = URL_SERVICIOS + '/GuardarImagenPlantilla';
      // cargando();
        return this.http.post( url,{identificador,foto},this.getHeaders() )
        .pipe(        
          map((resp:any)=>{
            cargado();
          }),     
          catchError(ex=>{
            // cargado();
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: 'Hubo un problema al guardar la plantilla, intente de nuevo',
            //   footer: ''
            // })
            return ex;
          })
        );
    }
    putPlantilla(plantilla: any ){
      let url = URL_SERVICIOS + '/ActualizarPlantilla';
      cargando();
        return this.http.post( url,plantilla,this.getHeaders() )
        .pipe(        
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un problema al guardar la plantilla, intente de nuevo',
              footer: ''
            })
            return ex;
          })
        );
    }
    postConfiguracion(configuracion: any ){
      let url = URL_SERVICIOS + '/configuracionGuardar';
      cargando();
        return this.http.post( url,configuracion,this.getHeaders() )
        .pipe(        
          map((resp:any)=>{
            cargado();
          }),     
          catchError(ex=>{
            cargado();
            if(ex.error.error.sqlState == "23000"){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Correo ya registrado',
                footer: ''
              })
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Hubo un problema al guardar la configuracion, intente de nuevo',
                footer: ''
              })
            }
            return ex;
          })
        );
    }
    getConfiguracion(){
      let url = URL_SERVICIOS + '/configuracion';
      cargando();
        return this.http.get( url,this.getHeaders() )
        .pipe( 
          map((resp:any)=>{
            cargado();
            if( !resp.ok ){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error intentelo de nuevo',
                footer: ''
              })
            }
            return resp.result;
          }),
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: ex.error.error,
              footer: ''
            })
            return ex;
          })
        );
    }
    postConfiguracionTestCorreo(configuracion: any ){
      let url = URL_SERVICIOS + '/testCorreo';
      cargando();
        return this.http.post( url,configuracion,this.getHeaders() )
        .pipe(   
          map((resp:any)=>{
            cargado();
            return resp;
          }),          
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Error en la configuracion, Verifique los datos e intente de nuevo',
              footer: ''
            })
            return ex;
          })
        );
    }
    postConfiguracionSMS(configuracion: any ){
      let url = URL_SERVICIOS + '/configuracionSMS';
      cargando();
        return this.http.post( url,configuracion,this.getHeaders() )
        .pipe(      
          map((resp:any)=>{
            cargado();
          }),     
          catchError(ex=>{
            cargado();
            if(ex.error.error.sqlState == "23000"){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Numero de telefono ya registrado',
                footer: ''
              })
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Hubo un problema al guardar la configuracion, intente de nuevo',
                footer: ''
              })
            }
            return ex;
          })
        );
    }
    getConfiguracionSMS(){
      let url = URL_SERVICIOS + '/configuracionSMS';
      cargando();
        return this.http.get( url,this.getHeaders() )
        .pipe( 
          map((resp:any)=>{
            cargado();
            if( !resp.ok ){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error intentelo de nuevo',
                footer: ''
              })
            }
            return resp.result;
          }),
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: ex.error.error,
              footer: ''
            })
            return ex;
          })
        );
    }
}