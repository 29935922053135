import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cargar-imagen',
  templateUrl: './cargar-imagen.component.html',
  styleUrls: ['./cargar-imagen.component.css']
})
export class CargarImagenComponent implements OnInit {

  @Output() getFoto:EventEmitter<any> = new EventEmitter();
  @ViewChild('foto',{static:true}) inputFotoElement:ElementRef;
  @ViewChild('lienzo',{static:true}) CanvasElement:ElementRef;
  input:any;
  lienzo:any;
  imagen:any;

  constructor() { }

  ngOnInit() {
    this.input = this.inputFotoElement.nativeElement;

    this.CanvasElement.nativeElement.addEventListener('click',()=>{
      this.input.click();
    });
    var ctx = this.CanvasElement.nativeElement.getContext("2d");

    this.inputFotoElement.nativeElement.onchange = ()=>{
      let file = this.inputFotoElement.nativeElement.files[0];
      let reader = new FileReader();

      reader.onloadend = ()=> {
          let b64 = reader.result;
          this.imagen = b64;
          this.getFoto.emit(b64);
          let img = new Image();
          img.src = b64+"";
          img.onload = ()=>{
            ctx.clearRect(0, 0, 300, 150);
            ctx.drawImage(img, 0, 0, 300, 150);
          }
      };

      reader.readAsDataURL(file);
    }
  }//fin oninit

}
