import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

declare function cargando();
declare function cargado();

@Injectable({
  providedIn: 'root'
})
export class LocalidadService {

  constructor(
    private http: HttpClient,
    private usuarioService:UsuarioService
  ) { }

  getLocalidades(id:number){
    let url = URL_SERVICIOS + '/localidades/'+id;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }

    //cargando();
    return this.http.get( url,options )
      .pipe( 
        map((resp:any)=>{
          cargado();
          return resp.localidades;
        }),
        catchError(err=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.error.message || err.error.error,
            footer: ''
          });
          return err;
        })
      );
  }//fin 
}
