import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidebarService, UsuarioService } from '../../services/service.index';
import { MenuService } from 'src/app/services/service/menu.service';
import { Subscription } from 'rxjs';
import Usuario from 'src/app/models/usuario.model';
import { URL_SERVICIOS } from 'src/app/config/config';
import { Router } from '@angular/router';

declare function init_plugins();

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit,OnDestroy {
  url = URL_SERVICIOS+'/imagenUsuario/-1';
  usuario:Usuario = {};
  modulos:any[] = [];

  constructor(
    public _sidebar: SidebarService,
    public menuService: MenuService,
    public usuarioService: UsuarioService,
    private router:Router
  ) { }

  ngOnInit() {
    this.url = URL_SERVICIOS+'/imagenUsuario/'+this.usuarioService.getSession().IdContacto;

    this.usuario = this.usuarioService.usuario;
    this.menuService.getMenu().subscribe( (res:any)=>{
      this.modulos = res.menu; 
      init_plugins();
    });
  }
  
  ngOnDestroy(): void {
    //this.subsMenu.unsubscribe();
  }
  openPerfil(){
    this.router.navigateByUrl('/perfil' );
  }
}
