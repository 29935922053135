import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import Usuario from 'src/app/models/usuario.model';
import { MunicipioService } from 'src/app/services/service/municipio.service';
import { OcupacionService } from 'src/app/services/service/ocupacion.service';
import { LocalidadService } from 'src/app/services/service/localidad.service';
import Contacto from 'src/app/models/contacto.model';
import { ContactoService } from 'src/app/services/service/contacto.service';
import Padron from 'src/app/models/padron.model';
import { PadronService } from 'src/app/services/service/padron.service';
import Swal from 'sweetalert2';
import Unico from 'src/app/models/unico.model';

declare function mascara(params:string);
declare function setFocus(params:string);

@Component({
  selector: 'app-form-contacto',
  templateUrl: './form-contacto.component.html',
  styleUrls: ['./form-contacto.component.css']
})
export class FormContactoComponent implements OnInit {

  @Input() Padron:Padron;
  @Input() Contacto:Contacto;

  @Output() getContactos:EventEmitter<any> = new EventEmitter();
  @Output() getPadrones:EventEmitter<any> = new EventEmitter();

  @ViewChild('input',{static:true}) input:ElementRef;
  @ViewChild('inputNombreCompleto',{static:true}) inputNombreCompleto:ElementRef;
  @ViewChild('selectMunicipio',{static:true}) selectMunicipio:ElementRef;

  loading = false;

  titulo:string = 'Nuevo Contacto';
  modo:string = "nuevo";

  municipios = [];
  ocupacion = [];
  localidades = [];

  ShowModalFormUnicos:boolean = false;
  showUnicos:boolean = false;

  unico:Unico = new Unico();
  contacto:Contacto = new Contacto();

  txtLocalidad:string = '';

  constructor(
    public _serviceMunicipio: MunicipioService,
    public _serviceOcupacion: OcupacionService,
    public _serviceLocalidad: LocalidadService,
    public _serviceContacto: ContactoService,
    public _servicePadron:PadronService
  ) { }

  ngOnInit() {
    if(this.Contacto.IdContacto){
      this.modo = 'editar';
      this.titulo = 'Editar Contacto';
      this.contacto = this.Contacto;
      this.unico = {
        IdUnico:this.contacto.IdUnico,
        Nombre:this.contacto.Nombre,
        ApellidoPaterno:this.contacto.ApellidoPaterno,
        ApellidoMaterno:this.contacto.ApellidoMaterno
      }
      this.buscarLocalidades(this.contacto.IdMunicipio);
    }else{
      
    }

    this._serviceMunicipio.getMunicipios()
      .subscribe(res=>{
        this.municipios = res;
      });
    
    this._serviceOcupacion.getOcupaciones()
    .subscribe(res=>{
        this.ocupacion = res;
    });

    setFocus('button-addon2');
    mascara('inputCelular');
  }///fin oninit

  ngOnChanges(){

  }
  abrirUnicos(){
    this.showUnicos = !this.showUnicos;
  }
  buscarLocalidades(id){
    this.loading = true;
    this._serviceLocalidad.getLocalidades(id)
    .subscribe(res=>{
      this.localidades = res;
      this.loading = false;
    });
  }
  cerrar(){
    this.getContactos.emit(this.Padron);
  }

  EventoCerrarUnicos(){
    this.abrirUnicos();
  }
  EventoObtenerUnico(u:Unico){
    this.unico = u;
    this.contacto.IdUnico = this.unico.IdUnico;
    this.contacto.Nombre = this.unico.Nombre;
    this.contacto.ApellidoPaterno = this.unico.ApellidoPaterno;
    this.contacto.ApellidoMaterno = this.unico.ApellidoMaterno;
    setFocus('inputCalle');
  }

  add(){
    ///OBTENER IMAGEN   

    ///validar que exista coordenadas
    if(!this.contacto.Lat){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Falta agregar la ubicacion!',
        footer: ''
      });
      return;
    }

    if(this.modo === 'nuevo'){
      this.contacto.IdPerfil = 5;
      this.contacto.IdPadron = this.Padron.IdPadron;
      this.contacto.Password = '';
      this.contacto.IdProyecto = this.Padron.IdProyecto;

      this._serviceContacto.crear(this.contacto)
        .subscribe(res=>{
          this.getPadrones.emit();
          this.getContactos.emit(this.Padron);
        });
    }else{
      this._serviceContacto.editar(this.contacto.IdContacto,this.contacto)
        .subscribe(res=>{
          this.getPadrones.emit();
          this.getContactos.emit(this.Padron);
        });
    }
  }///fin add

  ////////////////////////////////***********************
  ////////////////////////////////EVENTOS MAPA***********************
  DatosMapa(event:any){
    this.contacto.Lat = event.lat;
    this.contacto.Lng = event.lng;
    setFocus('inputRef');
  }
  ////////////////////////////////***********************
  ////////////////////////////////***********************
  ////////////////////////////////***********************
  findTextoLocalidad(id){
    let loc = this.localidades.find(localidad=>localidad.cod_loc == id);
    this.txtLocalidad = (loc.nom_loc || '');
  }
}
