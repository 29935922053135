import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as Highcharts from 'highcharts';
import * as more from 'highcharts/highcharts-more.src';

@Component({
  selector: 'app-grafica-genero-edades',
  templateUrl: './grafica-genero-edades.component.html',
  styleUrls: ['./grafica-genero-edades.component.css']
})
export class GraficaGeneroEdadesComponent implements OnInit {
  @ViewChild ('contenedorEdades', {static : true})  chartDivEdades: ElementRef;

  dashboard = {
    Hombres: 0,
    Mujeres: 0
  };
  
  highcharts = Highcharts;
  chartOptions;
  
  fechaInicio: string;
  fechaFinal: string;

  constructor(
    private _dashboardService:DashboardService,
  ) { }

  ngOnInit() {
    this.getTotalesSexo()
    this.getGraficaEdades();
  }

  getTotalesSexo(){
    this._dashboardService.getTotalesSexo()
      .subscribe( res=>{
        this.dashboard = res;
      });
  }

  getGraficaEdades(){
    let categories = ['0-04','05-13','14-17','18-30','31-50','51-70','71-90','91->'];
    let totales = [];
    
    this._dashboardService.getGraficaEdades()
    .subscribe( res=>{
        totales = [parseInt(res.EDAD04), parseInt(res.EDAD513), parseInt(res.EDAD1417), parseInt(res.EDAD1830), parseInt(res.EDAD3150), parseInt(res.EDAD5170), parseInt(res.EDAD7190), parseInt(res.EDAD91)]

        this.chartOptions = {
            chart: {
              type: "areaspline"
            },
            title: {
              text: "Rango de Edad"
            },
            Legend: {
              layout: 'vertical',
              align: 'left',
              verticalAlign: 'top',
              x: 150,
              y: 100,
              floating: true,
              borderWidth: 1,
              backgroundColor:
                  Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
            },
            xAxis:{
              name: "Edades",
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Contactos"
              }
            },
            tooltip: {
              // shared: true,
              valueSuffix: ' contactos(s)'
            },
            credits: {
              enabled: false
            },
            colors: [
              '#FF7D00',
              '#33ADFF',
              '#33CC33',
              '#F03C3C',
              '#9933FF',
              '#FFFF00',
              '#2BD68F',
              '#2200CC'
              ],
            plotOptions: {
              areaspline: {
                  fillOpacity: 0.5
              }
            },
            series: [
              {
                name: 'Total',
                data: totales,
                showInLegend: false
              }
            ]
          };

        Highcharts.chart(this.chartDivEdades.nativeElement, this.chartOptions);

      });
  }
}