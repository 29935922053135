import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import  ceoconfiguracion  from 'src/app/models/ceoconfiguracion.model';
import Ceoconfiguracionsms from 'src/app/models/ceoconfiguracionsms.model';
import { CeoConfiguracionService } from "src/app/services/service/ceoConfiguracion.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

declare var jQuery:any;
declare var $:any;
declare var GetValorID;
declare var SetValorID;
declare var copiar_;
declare function mascara(params:string);
@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {
  @ViewChild('input',{static:true}) input:ElementRef;
  configuracion: ceoconfiguracion= new ceoconfiguracion();
  ConfiguracionSMS: Ceoconfiguracionsms = new Ceoconfiguracionsms();
  Puertos:any=0;
  butDisabled:boolean = true;
  DisabledSMS: boolean = false;
  DisabledHost: boolean = false;
  DisabledTipo: boolean = false;
  Disabledpassword: any="";
  ACTIVO: number = 1;
  INACTIVO: number = 2;
  RutaPage: string = '/ceos/page';
  pagina:number=0;
  data: any;
  envios_route='';
  marcar_route='';
  guardar_route='';
  constructor(private ceoConfiguracionService: CeoConfiguracionService,private activatedRoute: ActivatedRoute,
    private router:Router) { }

  ngOnInit() {
    // this.ConfiguracionSMS.Telefono;
    mascara('telefono-Configuracion');
    console.clear();
    this.getConfiguracion();
    this.getConfiguracionSMS();
    this.input.nativeElement.focus();
  }
  
  ngAfterViewInit(){
    this.componentesJQuery();
  }
  componentesJQuery(){
    $('.selectpicker_Host').selectpicker();
    $('.selectpicker_Tipo').selectpicker();
    $('.selectpicker_Companias').selectpicker();
  }
  getSelect(params,tipo){
    this.Puertos =0;
    switch (tipo) {
      case 'Servidor':
        this.butDisabled= false;
        this.DisabledHost= true;
        this.configuracion.Servidor = params;
        this.setPuertosSelect( this.configuracion.Tipo)  
        // setTimeout(()=>{ 
        //   $('select[name=Tipo]').val(0);
        //   $('.selectpicker_Tipo').selectpicker('refresh');
        // }, 500)
        // this.Puertos = 0;
      break;
      case 'Tipo':
        this.DisabledTipo = true;
        this.configuracion.Tipo = params;   
        this.setPuertosSelect(params)
      break;
      // case 'Puerto':
      //   this.configuracion.Puerto = params;   
      // break;
      default:
        break;
    }
    // Tsl 587 Ssl 465
  }
  setPuertosSelect(tipo){
    if (this.configuracion.Servidor=='smtp.gmail.com') {
      if (tipo =='SSL') {
        this.Puertos = 465;
      }else{
        this.Puertos = 587;
      }
    }
    if (this.configuracion.Servidor=='smtp.mail.yahoo.com'||this.configuracion.Servidor=='smtp.live.com') {
      if (tipo =='SSL') {
        this.Puertos = 25;
      }else{
        this.Puertos = 465;
      }
    }
    if (this.configuracion.Servidor=='smtp-mail.outlook.com') {
      if (tipo =='SSL') {
        this.Puertos = 25;
      }else{
        this.Puertos = 587;
      }
    }
    this.configuracion.Puerto = this.Puertos;
  }
  GuardarConfiguracionCorreo(){
    
    // this.configuracion.Correo = GetValorID('txtContraseña','txt');
    this.configuracion.Contraseña = this.Disabledpassword;
    this.ConfiguracionSMS

    delete this.configuracion.IdConfuguracion;
    delete this.configuracion.IdContacto;
    // console.log(this.configuracion);
    this.ceoConfiguracionService.postConfiguracion(this.configuracion).subscribe(resp=>{
      Swal.fire(
        'Guardado!',
        'Configuración guardada con exito',
        'success'
      );
    })
  }
  getConfiguracion(){
    this.ceoConfiguracionService.getConfiguracion().subscribe(res=>{
      // console.log(res);
      if (res.length>0) {
        this.configuracion = res[0];
        SetValorID('txtCorreo',this.configuracion.Correo);
        SetValorID('txtContraseña',this.configuracion.Contraseña);
        SetValorID('txtPuerto',this.configuracion.Puerto);  
        $('select[name=Servidor]').val(this.configuracion.Servidor);
        $('select[name=Tipo]').val(this.configuracion.Tipo);
        this.butDisabled= false;
        setTimeout(()=>{ 
          $('.selectpicker_Host').selectpicker('refresh');
          $('.selectpicker_Tipo').selectpicker('refresh');
        }, 500)      
      }
    })
  }
  getConfiguracionSMS(){
    this.ceoConfiguracionService.getConfiguracionSMS().subscribe(res =>{
      if (res.length>0) {
        this.ConfiguracionSMS = res[0];
        $('select[name=Compania]').val(this.ConfiguracionSMS.Compañia);
        setTimeout(()=>{ 
          $('.selectpicker_Companias').selectpicker('refresh');
        }, 500)   
        this.envios_route = 'https://inmonitor.red70s.net/lbase/sms/lbase/envios.php?id='+this.ConfiguracionSMS.Identificador;
        this.marcar_route ='https://inmonitor.red70s.net/lbase/sms/lbase/marcar.php?id='+this.ConfiguracionSMS.Identificador;
        this.guardar_route ='https://inmonitor.red70s.net/lbase/sms/lbase/guardarMensaje.php';
      }
    })
  }
  getSelectCompanias(params){
    if (params =='0') {
      this.DisabledSMS= false;
    }else{
      this.ConfiguracionSMS.Compañia= params;
      this.DisabledSMS= true;
    }
  }
  guardarconfiguracionSMS(){
    const time_ = new Date();
    this.ConfiguracionSMS.Identificador = time_.getTime();
    this.ceoConfiguracionService.postConfiguracionSMS(this.ConfiguracionSMS).subscribe(res=>{
      Swal.fire(
        'Guardado!',
        'Plantilla guardada con exito',
        'success'
      );
      this.envios_route = 'https://inmonitor.red70s.net/lbase/sms/lbase/envios.php?id='+time_.getTime();
      this.marcar_route ='https://inmonitor.red70s.net/lbase/sms/lbase/marcar.php';
      this.guardar_route ='https://inmonitor.red70s.net/lbase/sms/lbase/guardarMensaje.php';
    })
    // console.log(this.ConfiguracionSMS);
  }
  Copiar(ruta){
    let texto =GetValorID(ruta,'txt');
    var aux = document.createElement("TEXTAREA");
    var t = document.createTextNode(texto);
        aux.appendChild(t);
    document.body.appendChild(aux);
    (aux  as HTMLInputElement).select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    Swal.fire(
      'Copiado!',
      'Se guardo en portapapeles',
      'success'
    );
    // copiar_(ruta);
  }
  testCorreo(){
    this.configuracion.Contraseña = this.Disabledpassword;
    // console.log(this.configuracion);
    this.ceoConfiguracionService.postConfiguracionTestCorreo(this.configuracion).subscribe(resp=>{
      // console.log(resp);
      if (resp['ok'] == true) {
        Swal.fire(
          'Conexión Exitosa!',
          'Los datos son correctos',
          'success'
        );
      }else{
        Swal.fire(
          // 'Error de Conexión!',
          // 'Los datos son incorrectos',
          {
            icon: 'error',
            title: 'Error de Conexión!',
            text: 'Error en la configuracion, Verifique los datos e intente de nuevo',
            footer: ''
          }
        );
      }
    })
  }
}
