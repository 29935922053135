import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import Mensaje from 'src/app/models/mensaje.model';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tabla-email',
  templateUrl: './tabla-email.component.html',
  styleUrls: ['./tabla-email.component.css']
})
export class TablaEmailComponent implements OnInit {
  @Input() Filtro_: any;
  mensajes: Mensaje[] = [];
  mensaje: string;
  data: any;
  RutaPage: string;
  pagina:number=0;
  items: [];
  pageOfItems: Array<any>;
  ShowModalEmail: boolean = false;
  ShowModalEmailPreview: boolean = false;
  personas: any[] = [];

  constructor(
    private _dashboardService:DashboardService,
    private activatedRoute: ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit() {
    var start = moment().format('YYYY-MM-DD');
    var end = moment().format('YYYY-MM-DD');

    //good
    this.getEnviosEmail(start, end, 0)
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  ngOnChanges() {
    var fechaI = moment(this.Filtro_.fechaInicio).format("YYYY-MM-DD");
    var fechaF = moment(this.Filtro_.fechaFinal).format("YYYY-MM-DD");

    //this.RutaPage = 'tabla-envios/' + fechaI + '/' + fechaF + '/';

    this.getEnviosEmail(fechaI, fechaF, 0);
  }

  abrirModal(){
    this.ShowModalEmail = true;
  }

  cerrarModal( event:boolean ){
    this.ShowModalEmail = event;
  }

  preview(mensaje: string){
    this.mensaje = mensaje;
    this.ShowModalEmailPreview = true;
  }
  
  cerrarModalPreview( event:boolean ){
    this.ShowModalEmailPreview = event;
  }

  getPersonas(IdM: Array<any>){
    this.activatedRoute.paramMap.subscribe(params => {
    this._dashboardService.getPersonasEmail(IdM)
      .subscribe( res=>{
        console.log(res);
        this.personas = res;
      });
    });

    this.abrirModal();
  }

  onSeleccionEnvios(page: number){
    var fechaI = moment(this.Filtro_.fechaInicio).format("YYYY-MM-DD");
    var fechaF = moment(this.Filtro_.fechaFinal).format("YYYY-MM-DD");

    this.getEnviosEmail(fechaI, fechaF, page);
  }

  getEnviosEmail(fechaInicio: string, fechaFinal: string, page: number )
  {
    this.activatedRoute.paramMap.subscribe(params => {
      //let pagina: number = +params.get('page');
      // this.pagina = +params.get('page');

      // if (!this.pagina) { this.pagina = 0 };
      // if( p !== -1 ){ this.pagina = 0 }

    // this._dashboardService.getEnviosEmail(fechaInicio, fechaFinal, this.pagina)
    //   .subscribe( res=>{
    //     console.log(res);
    //     this.data = res;
    //     this.items = res.data;
    //   });

    this._dashboardService.getEnviosEmail(fechaInicio, fechaFinal, (page || 0))
      .subscribe( res=>{
        //console.log(res);
        this.data = res;
        this.items = res.data[0];
      });
    });
  }
}