import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import * as c3 from 'c3';
import * as d3 from 'd3';

declare var jQuery:any;
declare var $:any;
let _this;

@Component({
  selector: 'app-grafica-correos',
  templateUrl: './grafica-correos.component.html',
  styleUrls: ['./grafica-correos.component.css']
})
export class GraficaCorreosComponent implements OnInit {
  dashboard = {
    PorcentajeEnviados: 0,
    PorcentajeNoEnviados: 0
  };

  constructor(
    private _dashboardService:DashboardService,
  ) { }

  ngOnInit() {
    _this = this;
  
    this.getGraficaCorreos();
  }

  getGraficaCorreos(){
    var PorcentajeEnviados;
    var PorcentajeNoEnviados; 
    
    this._dashboardService.getGraficaCorreos()
      .subscribe( res=>{
        this.dashboard = res;

        PorcentajeEnviados = res.CorreosEnviados;
        PorcentajeNoEnviados = res.CorreosNoEnviados;

        var chart2 = c3.generate({
          bindto: '#chart2',
          data: {
                  columns: [
                           ['Enviados', PorcentajeEnviados],
                           ['NoEnviados', PorcentajeNoEnviados]
                          ],
                  type : 'donut',
                },
          tooltip: {
            format: {
              value: function (value, ratio) {
                return value + ' (' + d3.format(',.2%')(ratio) + ')';
              }
            }
          },
          donut: {
              label: {
                  format: function(value, ratio, id) {
                    return d3.format('.2f')(value)
                  },
                  show: false
                },
              title: "Correos",
              width: 20,
          },
          size: {
            height: 425
          },
          // axis: {
          //   x: {
          //     tick: {
          //     format: d3.format('.2f')
          //   }
          // }
          // },
          legend: {
            hide: true
            //or hide: 'data1'
            //or hide: ['data1', 'data2']
          },
          color: {
                pattern: ['#1E88E5', '#26C6DA', '#1e88e5', '#745af2']
          }
      });
      });
  }

  ngAfterViewInit(){
    //_this = this;
  }

}