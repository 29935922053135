import { Component, OnInit } from '@angular/core';
import Usuario from 'src/app/models/usuario.model';
import { ActivatedRoute, Router } from '@angular/router';
import Contacto from '../../models/contacto.model';
import Swal from 'sweetalert2'
import { ContactoService } from 'src/app/services/service/contacto.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  ACTIVO: number = 1;
  INACTIVO: number = 2;

  RutaPage: string = '/usuarios/page';
  bol:boolean = false;
  contactos: Contacto[];
  data: any;

  IdProyecto:number = -1;
  pagina:number=0;

  contacto:Contacto = {};

  constructor(
    private contactoService: ContactoService,
    private activatedRoute: ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit() {
    
    this.getUsuarios();
  }
  buildRuta(id_proyecto){
    if(this.bol){return;}
    this.RutaPage += '/'+id_proyecto;
    this.bol = true;
  }
  getUsuarios( p:number = -1 ) {


    this.activatedRoute.paramMap.subscribe(params => {

      //let pagina: number = +params.get('page');
      this.pagina = +params.get('page');
      this.IdProyecto = +params.get('id');

      this.buildRuta(this.IdProyecto);

      if (!this.pagina) { this.pagina = 0 };
      if( p !== -1 ){ this.pagina = 0 }

      this.contactoService.getContactosCEOPage( this.pagina,this.IdProyecto )
        .subscribe(users => {
          this.data = users;
          this.contactos = users.data;
        });

    });

  }

  nuevo(){
    this.router.navigateByUrl('/usuario-form/'+this.IdProyecto+'/'+this.pagina);
  }

  editar( contacto:Contacto ){
    this.router.navigateByUrl('/usuario-form/'+this.IdProyecto+'/'+this.pagina+'/'+contacto.IdContacto );
  }

  regresar(){
    this.router.navigateByUrl('/proyectos');
  }

  /*cambiarPassword( usuario:Usuario ){
    Swal.fire({
      title: 'Nueva contraseña',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value) {
        this.contactoService.cambiarPassword( usuario.IdUsuario,{ Password: result.value} )
          .subscribe( resp=>{
            Swal.fire('Editado','Contraseña editada con exito','success');
          });
      }
    })
  }//fin cambiarPassword*/

}
