import { Component, OnInit } from '@angular/core';
import Padron from 'src/app/models/padron.model';
import { PadronService } from 'src/app/services/service/padron.service';
import Swal from 'sweetalert2';
import { ContactoService } from 'src/app/services/service/contacto.service';
import Contacto from 'src/app/models/contacto.model';
import { URL_SERVICIOS } from 'src/app/config/config';

@Component({
  selector: 'app-padrones',
  templateUrl: './padrones.component.html',
  styleUrls: ['./padrones.component.css']
})
export class PadronesComponent implements OnInit {

  dataPadron:any[];
  termino:string;
  terminoContacto:string;

  urlFoto:string = '';

  ////****** */
  data:any[];
  ////****** */

  showFormContacto:boolean=false;
  showFormPadron:boolean=false;
  showListaPadrones:boolean=true;

  padron:Padron;
  padrones:Padron[] = [];

  contactos:Contacto[] = [];
  contacto:Contacto;

  seleccionpadron:boolean = false;
  padron_seleccionado:string = '';

  oculto:string = '';

  constructor(
    public _servicePadron:PadronService,
    public _serviceContacto:ContactoService
  ) { }

  ngOnInit() {
    this.urlFoto = URL_SERVICIOS;
    this.getPadrones( 0,'' );
  }

  getPadrones( page:number,termino:string ){
    this.showFormPadron = false;
    this.showListaPadrones = true;
    this._servicePadron.getPadronesByAsignado( (page || 0),( termino || '' ) )
    .subscribe((res)=>{ 
      this.padrones = res.data;
      this.dataPadron = res;
    });
  }//getPadrones

  getContactos(p:Padron,page:number,termino:string){
    this.showFormContacto = false;
    this.showListaPadrones = true;
    
    this.padron = p;
    this.seleccionpadron = true;
    this.padron_seleccionado = p.Padron;
    this._serviceContacto.getContactosPadron(p.IdPadron, (page || 0 ), (termino || '') )
    .subscribe(res=>{
      //this.contactos = res;
      this.data = res;
      this.contactos = res.data;
    });
    
  }

  onSeleccion(page:number){
    this.getContactos(this.padron,page,this.terminoContacto);
  }

  

  async nuevo(){
    this.padron = undefined;
    this.showFormPadron = true;
    this.showListaPadrones = false;
  }//fin nuevo

  async editarPadron(padron:any){
    this._serviceContacto.getContacto(padron.IdContacto2)
    .subscribe(res=>{
      this.padron = padron;
      this.contacto = res;
      this.showFormPadron = true;
      this.showListaPadrones = false;
    });

    
  }//editarPadron

  ////CONTACTOS
  abrirFormContacto(){
    this.contacto = new Contacto();
    this.showFormContacto = true;
    this.showListaPadrones = false;
  }

  editarContacto(contacto:Contacto){
    this.contacto = contacto;
    this.showFormContacto = true;
    this.showListaPadrones = false;
  }

  ///PAGINACION
  onSeleccionPadron( event ){
    this.getPadrones( event,this.termino );
  }

  openExcel(IdPadron:number){
    window.open(URL_SERVICIOS+'/contactos-padron-excel/'+IdPadron);
  }

  buscarTermino(texto:string){

    this.termino = texto;
    this.getPadrones( 0,texto );
  }

  buscarTerminoContacto(texto:string){
    this.terminoContacto = texto;
    this.getContactos( this.padron,0,texto );
  }

  getUrlFoto(contacto:Contacto){
    return URL_SERVICIOS+'/imagenUnico/'+contacto.IdUnico;
  }
  
}
