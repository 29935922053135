import { Component, OnInit, ViewChild, ElementRef, Output,EventEmitter, Input } from '@angular/core';

declare function setFocus(params:string);

@Component({
  selector: 'app-mapa-contactos',
  templateUrl: './mapa-contactos.component.html',
  styleUrls: ['./mapa-contactos.component.css']
})
export class MapaContactosComponent implements OnInit {
  _this = this;


  @Output() DatosMapa:EventEmitter<any> = new EventEmitter();

  @ViewChild('map',{ static: true }) mapaElement:ElementRef;
  @ViewChild('search',{static: true}) searchElemet:ElementRef;

  @Input() Lat:any;
  @Input() Lng:any;
  @Input() inputTxt:string;

  map: google.maps.Map;
  searchBox:google.maps.places.SearchBox;
  markers:google.maps.Marker[] = [];
  market:google.maps.Marker;

  fnBusqueda:any;
  fnInput:any;

  constructor() { }

  ngOnInit() {
    this.cargarMapa();
  }

  ngOnChanges(){
      
  
  }

  cargarMapa(){

    let latLng = new google.maps.LatLng(18.519742, -88.300422);

    if(this.Lat){
      latLng = new google.maps.LatLng(parseFloat(this.Lat), parseFloat(this.Lng));
    }

    const mapaOpciones:google.maps.MapOptions = {
      center: latLng,
      zoom:13,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    let map = new google.maps.Map(this.mapaElement.nativeElement,mapaOpciones);

    let searchBox = new google.maps.places.SearchBox(this.searchElemet.nativeElement);
    
    let fn = (mk)=>{
      this.send(mk);
    }
    let fnChangeMarket = (lat,lng)=>{
      this.sendWithMarker(lat,lng);
    }

    let fnInput = function() {
      searchBox.setBounds(map.getBounds());
    }

    map.addListener('bounds_changed', fnInput);


    var markers:any = [];
    var market = this.market;

    this.fnBusqueda = function() {
      var places = searchBox.getPlaces();

      if(market){
        market.setMap( null );
      }

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach(function(marker) {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach(function(place) {
        if (!place.geometry) {
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };

        // Create a marker for each place.
        let m = new google.maps.Marker({
          map: map,
          icon: icon,
          title: place.name,
          position: place.geometry.location,
          draggable: true,
          animation: google.maps.Animation.DROP
        });
        google.maps.event.addListener(m, 'dragend', function (evt) {
          fnChangeMarket(evt.latLng.lat(),evt.latLng.lng());
        });
        markers.push(m);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        
      });
      map.fitBounds(bounds);
      fn(markers[0]);
    }
    searchBox.addListener('places_changed', this.fnBusqueda);
    
  

    ///ASIGNACION DE REFERENCIAS
    this.map = map;
    this.searchBox = searchBox;
    this.markers = markers;

    //CREAR UN MARKET SI ES EDITAR
    if(this.Lat){
      this.addMarcador(this.Lat,this.Lng);
    }
  }//fin cargar mapa

  send(mk:any){
    let lat = mk.getPosition().lat();
    let lng = mk.getPosition().lng();
    this.DatosMapa.emit({lat,lng});
  }
  sendWithMarker(lat:any,lng:any){
    this.DatosMapa.emit({lat,lng});
  }

  setDir(){
    setFocus('search');
  }

  addMarcador(Lat,Lng){
    const l = new google.maps.LatLng( parseFloat(Lat), parseFloat(Lng) );
    const market = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: l
    });
    this.market = market;
    this.markers.push(market);
  }//fin addMarcador

}
