import Perfil from "./perfil.model";

export default class Usuario{
    IdContacto?:number;
    IdUsuario?:number;
    Nombre?:string;
    ApellidoPaterno?:string;
    ApellidoMaterno?:string;
    Usuario?:string;
    Telefono?:string;
    Email?:string;
    Password?:string;
    FechaAlta?:Date;
    IdPerfil?:number;
    Perfil?:Perfil;
    Activo?:number;
    IdProyecto?:number;
}