import { Component, OnInit } from '@angular/core';
import Proyecto from 'src/app/models/proyecto.model';
import { ProyectoService } from 'src/app/services/service/proyecto.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit {
  
  ACTIVO: number = 1;
  INACTIVO: number = 2;

  RutaPage: string = '/proyectos';
  proyectos: Proyecto[] = [];
  data: any;

  pagina:number=0;

  constructor(
    private proyectoService: ProyectoService,
    private activatedRoute: ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit() {

    this.get();
  }
  
  get( p:number = 0 ) 
  {
      
      this.pagina = p;
      this.proyectoService.getProyectosPage( p )
        .subscribe(users => {
          this.data = users;
          this.proyectos = users.data;
        });
  }

  onSeleccion( page:number ){
    this.get( page );
  }

  nuevo(){
    this.router.navigateByUrl('/proyecto-form');
  }

  editar( proyecto:Proyecto ){
    this.router.navigateByUrl('/proyecto-form/'+proyecto.IdProyecto );
  }

  irCEOs( proyecto:Proyecto ){
    this.router.navigateByUrl('/usuarios/page/'+proyecto.IdProyecto+'/0' );
  }

}
