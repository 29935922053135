import { Component, OnInit } from '@angular/core';
import CeoCaracteristica from 'src/app/models/ceocaracteristica.model';
import CeoTipo from 'src/app/models/ceotipo.model';
import { CeoCaracteristicaTipo } from "src/app/services/service/ceoCaracteristicaTipo.service";
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
declare var GetValorID;
declare var resetValor;
declare var SetValorID;
declare var jQuery:any;
declare var $:any;
@Component({
  selector: 'app-caracteristica-tipo',
  templateUrl: './caracteristica-tipo.component.html',
  styleUrls: ['./caracteristica-tipo.component.css']
})
export class CaracteristicaTipoComponent implements OnInit {
  CeoCaracteristicas: any;
  Caracteristica: CeoCaracteristica = new CeoCaracteristica();
  CeoTipos: any;
  Tipo: CeoTipo= new CeoTipo();
  ACTIVO: number = 1;
  INACTIVO: number = 2;
  RutaPage: string = '/ceos/page';
  pagina:number=0;
  data: any;
  // 
  ACTIVOT: number = 1;
  INACTIVOT: number = 2;
  RutaPageT: string = '/ceos/page';
  paginaT:number=0;
  dataT: any;
  edtC= false;
  edtT = false;
  constructor(private CeoCaracteristicaTipoService: CeoCaracteristicaTipo,private activatedRoute: ActivatedRoute,
    private router:Router) { }

  ngOnInit() {
    this.getCaracteristicas(0);
    this.getTipo(0);
    console.clear();
  }
  nuevoCarac(){
    this.reset();
    this.edtC = false;
  }
  nuevoTip(){
    this.reset();
    this.edtT = false;
  }
  async getCaracteristicas( page:number ) {
      this.CeoCaracteristicaTipoService.getCaracteristica(  (page || 0) )
      .subscribe(users => {
          this.data = users.data[0];
          this.CeoCaracteristicas = users.data;
          // console.log(this.CeoCaracteristicas)
          // this.getTotalesBaner();
        });
  }
  onSeleccion( event ){
    this.getCaracteristicas( event);
  }
  async getTipo( page:number ) {
      this.CeoCaracteristicaTipoService.getTipo( (page || 0) )
      .subscribe(users => {
          this.dataT = users.data[0];
          this.CeoTipos = users.data;
          // console.log(this.CeoTipos)
          // this.getTotalesBaner();
        });
  }
  onSeleccionTipo( event ){
    this.getTipo( event);
  }
  EditTipo(t){
    this.Tipo = t;
    this.edtT = true;
    SetValorID('txtTipo',this.Tipo.Tipo);
  }
  EditCaracteristica(c){
    this.Caracteristica = c;
    this.edtC = true;
    SetValorID('txtCaracteristica',this.Caracteristica.Caracteristica);
  }
  async ActualizarCarac(){
    this.Caracteristica.Caracteristica = GetValorID('txtCaracteristica','txt');
    await this.CeoCaracteristicaTipoService.putCaracteristica(this.Caracteristica).subscribe(res =>{
      this.getCaracteristicas(0);
      Swal.fire(
        'Guardado!',
        'Caracteristica guardada con exito',
        'success'
      );
      $("#modal_caracteristicas .close").click();
    });
    // delete this.Caracteristica.Caracteristica;
  }
  async ActualizarTipo(){
    this.Tipo.Tipo = GetValorID('txtTipo','txt');
    await this.CeoCaracteristicaTipoService.putTipo(this.Tipo).subscribe(res =>{
      this.getTipo(0);
      Swal.fire(
        'Guardado!',
        'Tipo guardado con exito',
        'success'
      );
      $("#modal_Tipo .close").click();
      this.reset();
    });
    delete this.Tipo.Tipo;
  }
  reset(){
    resetValor('txtCaracteristica');
    resetValor('txtTipo');
  }
  async GuardarCaracteristica(){
    this.Caracteristica.Caracteristica = GetValorID('txtCaracteristica','txt');
    delete this.Caracteristica.IdCaracteristica;
    delete this.Caracteristica.IdContacto;
    await this.CeoCaracteristicaTipoService.postGuardarCaracteristica(this.Caracteristica).subscribe(res =>{
      this.getCaracteristicas(0);
      Swal.fire(
        'Guardado!',
        'Caracteristica guardada con exito',
        'success'
      );
      $("#modal_caracteristicas .close").click();
    });
    delete this.Caracteristica.Caracteristica;
    // console.log(this.Caracteristica);
  }
  async GuardarTipo(){
    this.Tipo.Tipo = GetValorID('txtTipo','txt');
    delete this.Tipo.IdTipo;
    delete this.Tipo.IdContacto;
    await this.CeoCaracteristicaTipoService.postGuardarTipo(this.Tipo).subscribe(res =>{
      this.getTipo(0);
      Swal.fire(
        'Guardado!',
        'Tipo guardado con exito',
        'success'
      );
      $("#modal_Tipo .close").click();
      this.reset();
    });
    delete this.Tipo.Tipo;
    // console.log(this.Tipo);
  }

}
