import { Component, OnInit, Input,ViewChild,ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Mensaje from '../../../models/mensaje.model';
import  ceoplantilla  from 'src/app/models/ceoplantilla.model';
import * as Highcharts from 'highcharts';
import Swal from 'sweetalert2';
import { CeoSegmentoPadronesService } from "src/app/services/service/ceoSegmentoPadrones.service";
import { CeoConfiguracionService } from "src/app/services/service/ceoConfiguracion.service";
import { CeoCaracteristicaTipo } from "src/app/services/service/ceoCaracteristicaTipo.service";
import { saveAs } from 'file-saver';
declare var jQuery:any;
declare var $:any;
var _this;
declare var GetValorID;
declare var resetValor;
// declare var saveAs; 
@Component({
  selector: 'app-graficas',
  templateUrl: './graficas.component.html',
  styleUrls: ['./graficas.component.css']
})
export class GraficasComponent implements OnInit {
  @ViewChild ('contenedorlinealEdades', {static : true})  chartDivLinealEdades: ElementRef;
  // Totales_Banner: any =[]; FiltroAplicar_
  @Input() Totales_Banner: any;
  @Input() FiltroAplicar_: any;
  cssUrl: string;
  Mensaje : Mensaje = {
    IdMensaje: 0,
    IdTipoMensaje: 0,
    Emisor: "",
    Receptor: "",
    Mensaje: "",
    Estatus: 0,
    Fecha: new Date(),
    Asunto: "",
    Caracteristica: "-1",
    Tipo: "-1",
    IdPlantilla: 0
  };
  M : Mensaje = {
    IdMensaje: 0,
    IdTipoMensaje: 0,
    Emisor: "",
    Receptor: "",
    Mensaje: "",
    Estatus: 0,
    Fecha: new Date(),
    Asunto: "",
    Caracteristica: " ",
    Tipo: " ",
    IdPlantilla: 0
  };
  Cor=[];
  Tel=[];
  IdC=[];
  Recep:any;
  Receptores:any;
  IdContactos:any;
  Plantilla: any;
  Clasificacion: any;//= ["Gubernamental","Político","Medio Ambiente"]
  Tipo_:  any; //=["Contención","Fortalecimiento","Neutral"];  
  lblTitulo; Tipo;
  //Graficas
  linealEdades = Highcharts; chartOptionslinealEdades;
  totSeg=0; totPad=0; totInt=0; totUni=0;
  totFac=0; totTwi=0; totCor=0; totCel=0;
  totInUn=0; totMuj=0; totHom=0;
  // 
  data: any;
  banderaBloqueo=false;
  constructor(public sanitizer: DomSanitizer, private ceoSegmentoPadronesService: CeoSegmentoPadronesService,
    private ceoConfiguracionService: CeoConfiguracionService,private CeoCaracteristicaTipoService: CeoCaracteristicaTipo,
    ) { }

  ngOnInit() {
  }
  ngOnChanges() {
    // alert(this.FiltroAplicar_)
    this.getServidor(this.FiltroAplicar_);
    this.getDatosEnvio_();
  }
  async getServidor(filtro){
    this.totFac=0; this.totTwi=0; this.totCor=0; this.totCel=0;
    await this.ceoSegmentoPadronesService.getFiltros(filtro).subscribe( async respuesta =>{

        this.data = respuesta;
        const  Fa=respuesta[0].Facebook, Tw=respuesta[1].Twitter, Co=respuesta[2].Correo, Te=respuesta[3].Telefono;
        this.totFac = Fa;
        this.totTwi = Tw;
        this.totCor = Co;
        this.totCel = Te;
    });
    this.ceoSegmentoPadronesService.getGraficaGenero(filtro).subscribe(res =>{
      let Hom=res[1].Hombre, Muj=res[0].Mujer, Tot=res[2].Total;
      // Grafica Genero
      this.totInUn = Tot;
      this.totMuj = Muj;
      this.totHom = Hom;
      this.GraficasCirculares(Hom,Muj,Tot);
    });
    // grafica edades
    this.ceoSegmentoPadronesService.getGraficaLinealEdades(filtro).subscribe(async res =>{
      let data: any=[]; let values:any=[];
      for (let index = 0; index < res.length; index++) {
        data.push(res[index].edad)
        values.push(res[index].Total)
      }
      this.GraficaLinealEdades(await data,await values);
    });
    this.ceoSegmentoPadronesService.getBannerPrincipal(filtro).subscribe(async res=>{
      // banner 1
      let TotalContacto =0;
      this.totSeg = res[0][0].TotalSegmentos;
      this.totPad = res[1][0].TotalPadron;
      this.totInt = await  res[2][0].TotalContacto;
      this.totUni = res[3][0].TotalUnico;
      if (Object.entries(this.FiltroAplicar_).length === 0) {
        this.banderaBloqueo= false;
        this.totSeg = this.Totales_Banner[0].Total_Segmentos;
        this.totPad = this.Totales_Banner[0].Total_Padrones;
      }
      await console.clear();
    });
    // console.clear();
  }
  ngAfterViewInit(){
    this.componentesJQuery();
  }
  GraficasCirculares(h,m,t){
    let p=0;
    p= (m / t)*100;
    $('.G_M').val(p+"%").trigger('change');
    //console.log(p);
    p= (h / t)*100;
    $('.G_H').val(p+"%").trigger('change');
    //console.log(p);
    // $("input.dial").val('10%');//cambiar valor
    // $("input.dial").trigger('change');//actualizar al valor cambiado
  }
  async GraficaLinealEdades(datos,valores){
    try {
      var data = [{
        name: 'Contactos',
        data: valores
      }];
      var categorias = await datos;
      this.chartOptionslinealEdades = {   
        chart: {
          type: "spline"
        },
        title: {
          text: "Distribucion de Edades"
        },
        xAxis:{
          categories: await categorias
        },
        yAxis: {          
          title:{
              text:"Contactos"
          },
          labels: {
            formatter: function () {
                return this.value + '°';
            }
        }
        },
        line: {
          marker: {
              enabled: false
          }
        },
        series: data
      };
      Highcharts.chart(this.chartDivLinealEdades.nativeElement, this.chartOptionslinealEdades);
    } catch (error) {
      
    }
    
  }
  GetValoresSelec(valor, tipo){
    switch (tipo) {
      case 'Caracteristica':
        //console.log(valor)
        this.Mensaje.Caracteristica = valor;
        $('.selectpicker_Caracteristica').selectpicker('refresh');
        break;
      case 'Tipo_':
        this.Mensaje.Tipo = valor;
        $('.selectpicker_Tipo').selectpicker('refresh');
        break;
      default:
        break;
    }
  }
  getDatosEnvio_(){
    this.Cor=[];
    this.Tel=[];
    this.IdC=[];
    this.ceoSegmentoPadronesService.getDatosEnvio(this.FiltroAplicar_).subscribe(res=>{
      //console.log(res);
      for (let index = 0; index < res.length; index++) {
        this.Cor.push(res[index].Correo);
        this.Tel.push(res[index].Telefono);
        this.IdC.push(res[index].IdContacto);
      }
    })
  }
  Enviar(){
    delete this.Mensaje.Fecha;
    delete this.Mensaje.Estatus;
    
    if(this.Tipo =='Correo'){
      let x = document.getElementsByClassName('note-editable panel-body');
      let y = x[0].innerHTML;
      this.Mensaje.Mensaje = y;
      this.Receptores = this.Cor;
    }else{
      let x = document.getElementsByClassName('note-editable panel-body');
      let y = x[1].innerHTML;
      this.Mensaje.Mensaje = y;
      this.Receptores = this.Tel;
    }
    this.IdContactos =this.IdC;
    let envio ={ 
      Mensaje: this.Mensaje,
      Receptores: this.Receptores,
      IdContactos:this.IdContactos
    }
    //
    // console.log(this.Mensaje)
    if (this.Mensaje.Asunto =="" ||this.Mensaje.Caracteristica =="-1" ||this.Mensaje.Tipo =="-1") {
           Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Datos incompletos ',
          footer: ''
        })
    }else{
      this.ceoSegmentoPadronesService.postGuardarMensaje(envio).subscribe(resp=>{
        if(resp.ok ==true){
          Swal.fire(
            'Enviado!',
            'Mensaje enviado con exito',
            'success'
          );
          $("#modal_envio .close").click();
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Revise la configuración de '+this.Tipo,
            footer: ''
          })
        }
      })
    }
  }
  reset(){
    $('.summernoteCorreo').summernote('reset');
  }
  AgregarParametro(parametro){
    //console.log(parametro);
    let tipo;
    switch (this.Tipo) {
      case 'Correo':
        tipo ='';
        let x = document.getElementsByClassName('note-editable panel-body');
        let y = x[0].innerHTML.substring(0, (x[0].innerHTML.length - 4));
        let y1 = x[0].innerHTML.substring(x[0].innerHTML.length - 4);
        let variable ='<b style="font-weight: bold;">{{'+parametro+'}}</b>';
        let texto_final = y+' '+variable+' '+y1;
        x[0].innerHTML=texto_final;
        //console.log(x[0].innerHTML);
        break;
      case 'SMS':
        tipo ='Mensaje_SMS'
        break;
      default:
        break;
    }
    $('.selectpicker_Cambios').selectpicker('val', 'Seleccionar');
    // let texto = document.getElementById(tipo).innerHTML;
    let variable ='<b>{{'+parametro+'}}</b>';
    let texto_final = '-'+' '+variable;
    //console.log(texto_final);
    // $('.selectpicker').selectpicker('refresh');
  }
  AgregarTemplate(template){
    //console.log(template)
    let x = document.getElementsByClassName('note-editable panel-body');
    x[0].innerHTML =`${template}`;
  }
  Modal_Configuracion(tipo){  
    this.Recep="" ;
    // console.log(Object.entries(this.FiltroAplicar_.bandera).toString());
    if(Object.entries(this.FiltroAplicar_.bandera).toString() == "false"){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Seleccione el filtro o Padron',
        footer: ''
      });
    }else{
      if (this.FiltroAplicar_.bandera == false) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Seleccione el filtro o Padron',
          footer: ''
        });
      } else { 
        $("#modal_envio").modal()
        this.LimpiarMensaje();
        // 
        this.CeoCaracteristicaTipoService.getCaracteristica(0).subscribe(res=>{
          this.Clasificacion = res.data;
          setTimeout(()=>{
            $('.selectpicker_Caracteristica').selectpicker('refresh');
          }, 1000)
        })
        this.CeoCaracteristicaTipoService.getTipo(0).subscribe(res=>{
          this.Tipo_ = res.data;
          setTimeout(()=>{
            $('.selectpicker_Tipo').selectpicker('refresh');
          }, 1000)
        })
        // 
        this.Tipo = tipo;
        switch (tipo) {
          case 'Correo':
            this.lblTitulo = 'Correo Electronico';
            this.Recep = this.Cor;
            document.getElementById('div_area_correo').style.display='block';
            document.getElementById('drob_template').style.display='block';
            document.getElementById('div_area_SMS').style.display='none';
            document.getElementById('destinatarioCorreo').style.display= 'block';
            document.getElementById('destinatario').style.display= 'none';
            this.Mensaje.IdTipoMensaje = 3;
            this.ceoConfiguracionService.getPlantillaPage(0).subscribe(res=>{
              this.Plantilla = res.data;
              setTimeout(()=>{
                $('.selectpicker_Template').selectpicker('refresh');
              }, 1000)
            })
          break;
          case 'SMS':
            this.lblTitulo = 'SMS';
            this.Recep = this.Tel;
            document.getElementById('div_area_correo').style.display='none';
            document.getElementById('drob_template').style.display='none';
            document.getElementById('div_area_SMS').style.display='block';
            document.getElementById('destinatarioCorreo').style.display= 'none';
            document.getElementById('destinatario').style.display= 'block';
            this.Mensaje.IdTipoMensaje = 1;
          break;
          default:
            break;
        }
      }
    }
  }
  LimpiarMensaje(){
    this.Mensaje.Emisor ='';
    this.Mensaje.Receptor ='';
    this.Mensaje.Mensaje ='';
    this.Mensaje.Asunto ='';
    this.Mensaje.Caracteristica ='-1';
    this.Mensaje.Tipo ='-1';
  }
  editorCorreo(){
    var Nombrebtn = function (context) {
      var ui = $.summernote.ui;
      // create button
      var button = ui.button({
        contents: 'Nombre',//<i class="fa fa-child"/>
        tooltip: 'Nombre',
        click: function () {
          context.invoke('pasteHTML', '<span><b style="font-weight: bold;">{{Nombre}}</b><span>');
          document.execCommand('bold');
        }
      });
    
      return button.render();   // return button as jquery object
    }
    var ApellidoPBtn = function (context) {
      var ui = $.summernote.ui;
      // create button
      var button = ui.button({
        contents: 'Apellido Paterno',
        tooltip: 'Apellido Paterno',
        click: function () {
          context.invoke('pasteHTML', '<span><b style="font-weight: bold;">{{ApellidoPaterno}}</b><span>');
          document.execCommand('bold');
        }
      });
    
      return button.render();   // return button as jquery object
    }
    var ApellidoMBtn = function (context) {
      var ui = $.summernote.ui;
      // create button
      var button = ui.button({
        contents: 'Apellido Materno',
        tooltip: 'Apellido Materno',
        click: function () {
          context.invoke('pasteHTML', '<span><b style="font-weight: bold;">{{ApellidoMaterno}}</b><span>');
          document.execCommand('bold');
        }
      });
    
      return button.render();   // return button as jquery object
    }
    // 
    $('.summernoteCorreo').summernote({
      height: 200, // set editor height
      minHeight: null, // set minimum height of editor
      maxHeight: null, // set maximum height of editor
      focus: false,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline']],
        // ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontname', ['fontname']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        //['height', ['height']],
        ['table', ['table']],
        ['insert', [ 'picture', 'hr']],//'link',
        // ['view', ['fullscreen', 'codeview']],
        // ['help', ['help']],
        ['listabtn', ['Nombre','ApellidoP','ApellidoM']],
        // ['buttonApellido', ['Plantilla']],
        // ['buttonFecha', ['Fecha']]
      ],
    
      buttons: {
        Nombre: Nombrebtn,
        ApellidoP: ApellidoPBtn,
        ApellidoM: ApellidoMBtn,
        // Plantilla: plantillabtn
      },
      onImageUpload: function(files, editor, welEditable) {
       // console.log(files)
      }

    });
  }
  editorSMS(){
    var Nombrebtn = function (context) {
      var ui = $.summernote.ui;
      // create button
      var button = ui.button({
        contents: 'Nombre',//<i class="fa fa-child"/>
        tooltip: 'Nombre',
        click: function () {
          context.invoke('pasteHTML', '<span><b style="font-weight: bold;">{{Nombre}}</b><span>');
          document.execCommand('bold');
        }
      });
    
      return button.render();   // return button as jquery object
    }
    var ApellidoPBtn = function (context) {
      var ui = $.summernote.ui;
      // create button
      var button = ui.button({
        contents: 'Apellido Paterno',
        tooltip: 'Apellido Paterno',
        click: function () {
          context.invoke('pasteHTML', '<span><b style="font-weight: bold;">{{ApellidoPaterno}}</b><span>');
          document.execCommand('bold');
        }
      });
    
      return button.render();   // return button as jquery object
    }
    var ApellidoMBtn = function (context) {
      var ui = $.summernote.ui;
      // create button
      var button = ui.button({
        contents: 'Apellido Materno',
        tooltip: 'Apellido Materno',
        click: function () {
          context.invoke('pasteHTML', '<span><b style="font-weight: bold;">{{ApellidoMaterno}}</b><span>');
          document.execCommand('bold');
        }
      });
    
      return button.render();   // return button as jquery object
    }
    // 
    $('.summernoteSMS').summernote({
      height: 100, // set editor height
      minHeight: null, // set minimum height of editor
      maxHeight: null, // set maximum height of editor
      focus: false,
      toolbar: [
        // ['font', ['bold']],
        ['listabtn', ['Nombre','ApellidoP','ApellidoM']],
      ],
    
      buttons: {
        Nombre: Nombrebtn,
        ApellidoP: ApellidoPBtn,
        ApellidoM: ApellidoMBtn,
        // Plantilla: plantillabtn
      }
    });
  }
  async getplantillas(){
    this.ceoConfiguracionService.getPlantillaPage(0).subscribe(res=>{
      // this.Plantilla = res.data;
      // setTimeout(()=>{
      //   $('.selectpicker_Template').selectpicker('refresh');
      //  }, 1000)
      return res.data;
    })
  }
  cerrar(modal){
    $("#"+modal+" .close").click();
  }
  componentesJQuery(){
    // Editor
    this.editorCorreo();
    this.editorSMS();
    // Graficas total
    $('[data-plugin="knob"]').knob();
    //select
    $('.selectpicker_Caracteristica').selectpicker();
    $('.selectpicker_Tipo').selectpicker();
    $('.selectpicker_Cambios').selectpicker();
    $('.selectpicker_Template').selectpicker();
  }
  EnvioPreliminar(){
    //console.log(this.Tipo)
    if (this.Tipo =='Correo') {
      let x = document.getElementsByClassName('note-editable panel-body');
      let y = x[0].innerHTML;
      this.M.Mensaje = y;
      let PrevioC: any ={
        Correo: GetValorID('destinatarioCorreo','txt'),
        Mensaje: this.M,
      };
      this.ceoSegmentoPadronesService.postPrevioCorreo(PrevioC).subscribe(res =>{
        if(res.ok ==true){
          Swal.fire(
            'Enviado!',
            'Mensaje enviado con exito',
            'success'
          );
        }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Revise la configuración de correo',
              footer: ''
            })
        }
      })
    }else{
      let x = document.getElementsByClassName('note-editable panel-body');
      let y = x[1].innerHTML;
      this.M.Mensaje = y;
      let PrevioC: any ={
        sms: GetValorID('destinatario','txt'),
        Mensaje: this.M,
      };
      this.ceoSegmentoPadronesService.postSMS(PrevioC).subscribe(res =>{
        if(res.ok ==true){
          Swal.fire(
            'Enviado!',
            'Mensaje enviado con exito',
            'success'
          );
        }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Revise la configuración de SMS',
              footer: ''
            })
        }
      })
    }
    this.cerrar('modal_Preliminar');
  }
  Descargar(){
    // console.log(this.FiltroAplicar_.IdPadron.length);
    if(Object.entries(this.FiltroAplicar_.bandera).toString() == "false"){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Seleccione el filtro o Padron',
        footer: ''
      });
    }else{
      if (this.FiltroAplicar_.bandera == false) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Seleccione el filtro o Padron',
          footer: ''
        });
      } else {
        // console.log("else")
        this.ceoSegmentoPadronesService.Descargar(this.FiltroAplicar_).subscribe(res=>{
          // console.log(res);
          let fileBlob = res;
          let blob = new Blob([fileBlob], {
            type: "application/vnd.ms-excel"
          });
          saveAs(blob,`export.xlsx`);
          // this._FileSaverService.save(blob, `export.xlsx`);
        });     
      }
    }
  }
}
