import { Component, OnInit } from '@angular/core';
import { SegmentoService } from 'src/app/services/service/segmento.service';
import Segmento from 'src/app/models/segmento.model';
import Padron from 'src/app/models/padron.model';
import { PadronService } from 'src/app/services/service/padron.service';
import { Router } from '@angular/router';
import { URL_SERVICIOS } from 'src/app/config/config';

@Component({
  selector: 'app-segmentos',
  templateUrl: './segmentos.component.html',
  styleUrls: ['./segmentos.component.css']
})
export class SegmentosComponent implements OnInit {

  data:any[];
  dataPadron:any[];

  segmento:Segmento;
  segmentos:Segmento[] = [];
  padrones:Padron[] = [];
  padron_seleccionado:boolean = false;
  segmento_seleccionado:string = '';

  constructor(
    public _segmentoService:SegmentoService,
    public _padronService:PadronService,
    public route:Router
  ) { }

  ngOnInit() {
    this.getSegmentos(0);
  }

  ngOnChanges(){
    
  }

  getSegmentos(page:number){
    let u = JSON.parse(sessionStorage.getItem("usuario"));
    this._segmentoService.getSegmentos(u.IdContacto,(page || 0))
    .subscribe(res=>{
      
      this.segmentos = res.data;
      this.data = res;
    });
  }

  getPadrones(segmento:any,page:number){
    this.segmento = segmento;
    this._padronService.getPadrones(segmento.IdSegmento,(page || 0))
    .subscribe(res=>{
      this.segmento_seleccionado = segmento.Segmento;
      this.padron_seleccionado = true;
      this.padrones = res.data;
      this.dataPadron = res;
    })
  }

  nuevo(){
    this.route.navigateByUrl('/segmentos-form');
  }
 
  editar(segmento:Segmento){
    this.route.navigateByUrl('/segmentos-form/'+segmento.IdSegmento);
  }

  onSeleccion( event ){
    this.getSegmentos(event);
  }
  onSeleccionPadron( event ){
    this.getPadrones(this.segmento,event);
  }

  openExcel(IdPadron:number){
    window.open(URL_SERVICIOS+'/contactos-padron-excel/'+IdPadron);
  }
}
