import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { UsuarioService } from './usuario.service';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
//import excel from 'excel4node'
//var excel = require('excel4node');

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http:HttpClient,
    private usuarioService:UsuarioService
  ) { }

  getGraficaEncabezado(){
    let url = URL_SERVICIOS + '/dashboard-encabezado';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard[0];
      })
    );
  }

  getTotalesGraficaEnvios(fechaInicio: string, fechaFinal: string){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-grafica-envios/' + fechaInicio + '/' +  fechaFinal;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard[0];
      })
    );
  }

  getGraficaEnvios(fechaInicio: string, fechaFinal: string){
    let url = URL_SERVICIOS + '/grafica-envios/' + fechaInicio + '/' + fechaFinal;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getGraficaProyectos(){
    let url = URL_SERVICIOS + '/grafica-proyectos';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getGraficaCorreos(){
    let url = URL_SERVICIOS + '/grafica-correos/';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard[0];
      })
    );
  }

  getGraficaMensajes(){
    let url = URL_SERVICIOS + '/grafica-mensajes/';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard[0];
      })
    );
  }

  // getEnviosEmail(fechaInicio: string, fechaFinal: string, page: number){
  //   let url = URL_SERVICIOS + '/envios-email/' + fechaInicio + '/' + fechaFinal + '/' + page;
  //   let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
  //   const options = {
  //     headers
  //   }
  //   return this.http.get( url, options )
  //   .pipe( 
  //     map((resp:any)=>{
  //       if( !resp.ok ){
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Oops...',
  //           text: 'Ocurrio un error intentelo de nuevo',
  //           footer: ''
  //         })
  //       }
  //       //console.log(resp);
  //       return resp.page;
  //     })
  //   );
  // }

  getEnviosEmail(fechaInicio: string, fechaFinal: string, page: number){
    let url = URL_SERVICIOS + '/envios-email/' + fechaInicio + '/' + fechaFinal + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getPersonasEmail(IdMsg: Array<any>){
    let url = URL_SERVICIOS + '/personas-email/' + IdMsg;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        
        //console.log(resp);
        return resp.result[0];
      })
    );
  }

  getPersonasSms(IdMsg: Array<any>){
    let url = URL_SERVICIOS + '/personas-sms/' + IdMsg;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.result[0];
      })
    );
  }

  // getEnviosMensajes(fechaInicio: string, fechaFinal: string, page: number){
  //   let url = URL_SERVICIOS + '/envios-mensajes/' + fechaInicio + '/' + fechaFinal + '/' + page;
  //   let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
  //   const options = {
  //     headers
  //   }
  //   return this.http.get( url, options )
  //   .pipe( 
  //     map((resp:any)=>{
  //       if( !resp.ok ){
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Oops...',
  //           text: 'Ocurrio un error intentelo de nuevo',
  //           footer: ''
  //         })
  //       }
  //       //console.log(resp);
  //       return resp.page;
  //     })
  //   );
  // }

  getEnviosMensajes(fechaInicio: string, fechaFinal: string, page: number){
    let url = URL_SERVICIOS + '/envios-mensajes/' + fechaInicio + '/' + fechaFinal + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getTotalesSexo(){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-sexo';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard[0];
      })
    );
  }

  getGraficaEdades(){
    let url = URL_SERVICIOS + '/grafica-edades';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard[0];
      })
    );
  }

  getGraficaAvanceRegistro(fechaInicio: string, fechaFinal: string){
    let url = URL_SERVICIOS + '/grafica-avance-registro/' + fechaInicio + '/' + fechaFinal;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp;
      })
    );
  }

  getContactosSegmento(){
    let url = URL_SERVICIOS + '/contactos-segmento';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        // console.log(resp);
        return resp.result;
      })
    );
  }

  getDispersionElectoral(idSegmento: number){
    let url = URL_SERVICIOS + '/dispersion-electoral/' + idSegmento;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getSegmentos(){
    let url = URL_SERVICIOS + '/dashboard-segmentos';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getDistritosF(){
    let url = URL_SERVICIOS + '/dashboard-distritosF';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getDistritos(){
    let url = URL_SERVICIOS + '/dashboard-distritos';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getContactosDistritoF(idDistrito: number, page: number){
    let url = URL_SERVICIOS + '/dashboard-contactos-distritoF/' + idDistrito + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getContactosDistrito(idDistrito: number, page: number){
    let url = URL_SERVICIOS + '/dashboard-contactos-distrito/' + idDistrito + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getMunicipios(){
    let url = URL_SERVICIOS + '/dashboard-municipios';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getSecciones(){
    let url = URL_SERVICIOS + '/dashboard-secciones';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getContactosMunicipio(idMunicipio: number, page: number){
    let url = URL_SERVICIOS + '/dashboard-contactos-municipio/' + idMunicipio + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getContactosPadron(page: number){
    let url = URL_SERVICIOS + '/contactos-padron/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getPadrones(){
    let url = URL_SERVICIOS + '/dashboard-padrones';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getDispersionElectoralPadrones(idPadron: number){
    let url = URL_SERVICIOS + '/dispersion-electoral-padrones/' + idPadron;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getDistritosFPadron(){
    let url = URL_SERVICIOS + '/dashboard-distritosf-padron';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getDistritosPadron(){
    let url = URL_SERVICIOS + '/dashboard-distritos-padron';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getContactosDistritoFPadron(idDistrito: number, page: number){
    let url = URL_SERVICIOS + '/dashboard-contactos-distritof-padron/' + idDistrito + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getContactosDistritoPadron(idDistrito: number, page: number){
    let url = URL_SERVICIOS + '/dashboard-contactos-distrito-padron/' + idDistrito + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getMunicipiosPadron(){
    let url = URL_SERVICIOS + '/dashboard-municipios-padron';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getSeccionesPadron(){
    let url = URL_SERVICIOS + '/dashboard-secciones-padron';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getContactosMunicipioPadron(idMunicipio: number, page: number){
    let url = URL_SERVICIOS + '/dashboard-contactos-municipio-padron/' + idMunicipio + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getContactosSeccionPadron(idSeccion: number, page: number){
    let url = URL_SERVICIOS + '/dashboard-contactos-seccion-padron/' + idSeccion + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getContactosSeccion(idSeccion: number, page: number){
    let url = URL_SERVICIOS + '/dashboard-contactos-seccion/' + idSeccion + '/' + page;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.page;
      })
    );
  }

  getTotalesDistritoFSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-distritoF-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getTotalesDistritoSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-distrito-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getTotalesMunicipioSegmento(idMunicipio: number){
    let url = URL_SERVICIOS + '/totales-municipio-segmento/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getTotalesSeccionSegmento(idSeccion: number){
    let url = URL_SERVICIOS + '/totales-seccion-segmento/' + idSeccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getTotalesDistritoFPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-distritof-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getTotalesDistritoPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-distrito-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getTotalesMunicipioPadron(idMunicipio: number){
    let url = URL_SERVICIOS + '/totales-municipio-padron/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getTotalesSeccionPadron(idSeccion: number){
    let url = URL_SERVICIOS + '/totales-seccion-padron/' + idSeccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe(
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getGraficaMunicipiosPadron(){
    let url = URL_SERVICIOS + '/grafica-municipios-padron';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getGraficaDistritosFPadron(){
    let url = URL_SERVICIOS + '/grafica-distritosf-padron';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getGraficaDistritosLPadron(){
    let url = URL_SERVICIOS + '/grafica-distritosl-padron';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getGraficaMunicipiosSegmento(){
    let url = URL_SERVICIOS + '/grafica-municipios-segmento';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getGraficaDistritosFSegmento(){
    let url = URL_SERVICIOS + '/grafica-distritosf-segmento';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getGraficaDistritosLSegmento(){
    let url = URL_SERVICIOS + '/grafica-distritosl-segmento';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.dashboard;
      })
    );
  }

  getTablaFrecuenciasMunPadron(idMunicipio: number){
    let url = URL_SERVICIOS + '/frecuencias-mun-padron/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }

        return resp.result;
      })
    );
  }

  getGeoJsonMunicipioPadron(idMunicipio: number){
    let url = URL_SERVICIOS + '/geoJsonMunicipioPadron/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: Response)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        
        //console.log(resp);
        // return JSON.stringify(resp);

        //console.log(JSON.stringify(resp));
        //console.log(resp);
        return resp;
      })
    );
  }

  getTotalesGeneroSeccion(seccion: number){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-genero-seccion/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaGenerosSeccion(seccion: number){
    let url = URL_SERVICIOS + '/totales-grafica-genero-seccion/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOnomasticoSeccion(seccion: number){
    let url = URL_SERVICIOS + '/totales-grafica-onomastico-seccion/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOcupacionSeccion(seccion: number){
    let url = URL_SERVICIOS + '/totales-grafica-ocupacion-seccion/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOrigenSeccion(seccion: number){
    let url = URL_SERVICIOS + '/totales-grafica-origen-seccion/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGeneroDFPadron(IdDistrito: number){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-genero-df-padron/' + IdDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaGenerosDFPadron(IdDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-genero-df-padron/' + IdDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOnomasticoDFPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-onomastico-df-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOcupacionDFPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-ocupacion-df-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOrigenDFPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-origen-df-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGeneroDLPadron(IdDistrito: number){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-genero-dl-padron/' + IdDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaGenerosDLPadron(IdDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-genero-dl-padron/' + IdDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOnomasticoDLPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-onomastico-dl-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOcupacionDLPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-ocupacion-dl-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOrigenDLPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-origen-dl-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGeneroMunPadron(IdMunicipio: number){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-genero-mun-padron/' + IdMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaGenerosMunPadron(IdMunicipio: number){
    let url = URL_SERVICIOS + '/totales-grafica-genero-mun-padron/' + IdMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOnomasticoMunPadron(idMunicipio: number){
    let url = URL_SERVICIOS + '/totales-grafica-onomastico-mun-padron/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOcupacionMunPadron(idMunicipio: number){
    let url = URL_SERVICIOS + '/totales-grafica-ocupacion-mun-padron/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOrigenMunPadron(idMunicipio: number){
    let url = URL_SERVICIOS + '/totales-grafica-origen-mun-padron/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTablaFrecuenciasDFPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/frecuencias-df-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }

        return resp.result;
      })
    );
  }

  getGeoJsonDistritoFederalPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/geoJsonDistritoFederalPadron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: Response)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        
        //console.log(resp);
        // return JSON.stringify(resp);

        //console.log(JSON.stringify(resp));
        //console.log(resp);
        return resp;
      })
    );
  }

  getTablaFrecuenciasDLPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/frecuencias-dl-padron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }

        return resp.result;
      })
    );
  }

  getGeoJsonDistritoLocalPadron(idDistrito: number){
    let url = URL_SERVICIOS + '/geoJsonDistritoLocalPadron/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: Response)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        
        //console.log(resp);
        // return JSON.stringify(resp);

        //console.log(JSON.stringify(resp));
        //console.log(resp);
        return resp;
      })
    );
  }

  getGeoJsonSeccionPadron(idSeccion: number){
    let url = URL_SERVICIOS + '/geoJsonSeccionPadron/' + idSeccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: Response)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        
        //console.log(resp);
        // return JSON.stringify(resp);

        //console.log(JSON.stringify(resp));
        //console.log(resp);
        return resp;
      })
    );
  }

  getTotalesGeneroDFSegmento(IdDistrito: number){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-genero-df-segmento/' + IdDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaGenerosDFSegmento(IdDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-genero-df-segmento/' + IdDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOnomasticoDFSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-onomastico-df-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOcupacionDFSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-ocupacion-df-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOrigenDFSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-origen-df-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGeneroDLSegmento(IdDistrito: number){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-genero-dl-segmento/' + IdDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaGenerosDLSegmento(IdDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-genero-dl-segmento/' + IdDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOnomasticoDLSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-onomastico-dl-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOcupacionDLSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-ocupacion-dl-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOrigenDLSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/totales-grafica-origen-dl-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGeneroMunSegmento(IdMunicipio: number){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-genero-mun-segmento/' + IdMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaGenerosMunSegmento(IdMunicipio: number){
    let url = URL_SERVICIOS + '/totales-grafica-genero-mun-segmento/' + IdMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOnomasticoMunSegmento(idMunicipio: number){
    let url = URL_SERVICIOS + '/totales-grafica-onomastico-mun-segmento/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOcupacionMunSegmento(idMunicipio: number){
    let url = URL_SERVICIOS + '/totales-grafica-ocupacion-mun-segmento/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOrigenMunSegmento(idMunicipio: number){
    let url = URL_SERVICIOS + '/totales-grafica-origen-mun-segmento/' + idMunicipio;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOrigenSeccionSegmento(seccion: number){
    let url = URL_SERVICIOS + '/totales-grafica-origen-seccion-segmento/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }











  getTablaFrecuenciasDFSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/frecuencias-df-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }

        return resp.result;
      })
    );
  }

  getSeccionesFrecuenciasDFSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/secciones-frecuencias-df-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }

        return resp.result;
      })
    );
  }

  getGeoJsonDistritoFederalSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/geoJsonDistritoFederalSegmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: Response)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        
        //console.log(resp);
        // return JSON.stringify(resp);

        //console.log(JSON.stringify(resp));
        //console.log(resp);
        return resp;
      })
    );
  }

  getTablaFrecuenciasDLSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/frecuencias-dl-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }

        return resp.result;
      })
    );
  }

  getGeoJsonDistritoLocalSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/geoJsonDistritoLocalSegmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: Response)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        
        //console.log(resp);
        // return JSON.stringify(resp);

        //console.log(JSON.stringify(resp));
        //console.log(resp);
        return resp;
      })
    );
  }

  getTotalesGeneroSeccionSegmento(seccion: number){
    //console.log("URL getTotalesGraficasEnvios");
    //console.log(fechaInicio);
    let url = URL_SERVICIOS + '/totales-genero-seccion-segmento/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
      );
  }
  
  getTablaFrecuenciasMunSegmento(idDistrito: number){
    let url = URL_SERVICIOS + '/frecuencias-mun-segmento/' + idDistrito;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }

        return resp.result;
      })
    );
  }

  getGeoJsonMunicipioSegmento(idMunicipio: number){
      let url = URL_SERVICIOS + '/geoJsonMunicipioSegmento/' + idMunicipio;
      let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
      const options = {
        headers
      }
      return this.http.get( url, options )
      .pipe( 
        map((resp: Response)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          
          //console.log(resp);
          // return JSON.stringify(resp);
  
          //console.log(JSON.stringify(resp));
          //console.log(resp);
          return resp;
        })
      );
  }
  
  getTotalesGraficaGenerosSeccionSegmento(seccion: number){
    let url = URL_SERVICIOS + '/totales-grafica-genero-seccion-segmento/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOnomasticoSeccionSegmento(seccion: number){
    let url = URL_SERVICIOS + '/totales-grafica-onomastico-seccion-segmento/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getTotalesGraficaOcupacionSeccionSegmento(seccion: number){
    let url = URL_SERVICIOS + '/totales-grafica-ocupacion-seccion-segmento/' + seccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        //console.log(resp);
        return resp.totales;
      })
    );
  }

  getGeoJsonSeccionSegmento(idSeccion: number){
    let url = URL_SERVICIOS + '/geoJsonSeccionSegmento/' + idSeccion;
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url, options )
    .pipe( 
      map((resp: Response)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        
        //console.log(resp);
        // return JSON.stringify(resp);

        //console.log(JSON.stringify(resp));
        //console.log(resp);
        return resp;
      })
    );
  }

  // exportarDispersion(idSegmento: number){
  //   let url = URL_SERVICIOS + '/dispersion-electoral/' + idSegmento;
  //   return this.http.post( url,{headers: new HttpHeaders({'Authorization':this.usuarioService.token}), responseType: 'blob' })
  //   .pipe( 
  //     map((resp:any)=>{
  //       //return resp;

  //       let dashboard = resp.data;

  //       // Create a new instance of a Workbook class
  //       var workbook = new excel.Workbook();

  //       // Add Worksheets to the workbook
  //       var worksheet = workbook.addWorksheet('Dispersion');

  //       // Create a reusable style
  //       var style = workbook.createStyle({
  //       font: {
  //           color: '#000000',
  //           size: 12
  //       },
  //       numberFormat: '$#,##0.00; ($#,##0.00); -'
  //       });

  //       let fila = 1, columna = 1;

  //       let toString = function(value:any):string{
  //           return value+'';
  //       }

  //       ///encabezados
  //       // worksheet.cell(fila, 1).string(" ").style({font:{bold: true}});
  //       // worksheet.cell(fila, 2, fila, 3, true).string("DL 1").style({font:{bold: true}});
  //       // fila++;
  //       worksheet.cell(fila, 1).string("Nombre").style({font:{bold: true}});
  //       worksheet.cell(fila, 2).string("ApellidoPaterno").style({font:{bold: true}});
  //       worksheet.cell(fila, 3).string("ApellidoMaterno").style({font:{bold: true}});
  //       worksheet.cell(fila, 4).string("Direccion").style({font:{bold: true}});
  //       worksheet.cell(fila, 5).string("Telefono").style({font:{bold: true}});
  //       worksheet.cell(fila, 6).string("Correo").style({font:{bold: true}});
  //       worksheet.cell(fila, 7).string("Colonia").style({font:{bold: true}});
  //       worksheet.cell(fila, 8).string("CodigoPostal").style({font:{bold: true}});
  //       worksheet.cell(fila, 9).string("Facebook").style({font:{bold: true}});
  //       worksheet.cell(fila, 10).string("Twitter").style({font:{bold: true}});
  //       worksheet.cell(fila, 11).string("Whatsapp").style({font:{bold: true}});
  //       worksheet.cell(fila, 12).string("Lat").style({font:{bold: true}});
  //       worksheet.cell(fila, 13).string("Lng").style({font:{bold: true}});
  //       worksheet.cell(fila, 14).string("Referencia").style({font:{bold: true}});
  //       fila++;

  //       // contactos.forEach((contacto)=>{
  //       //     worksheet.cell(fila, 1).string(toString(contacto.Nombre));
  //       //     worksheet.cell(fila, 2).string(toString(contacto.ApellidoPaterno));
  //       //     worksheet.cell(fila, 3).string(toString(contacto.ApellidoMaterno));
  //       //     worksheet.cell(fila, 4).string(toString(contacto.Direccion));
  //       //     worksheet.cell(fila, 5).string(toString(contacto.Telefono));
  //       //     worksheet.cell(fila, 6).string(toString(contacto.Correo));
  //       //     worksheet.cell(fila, 7).string(toString(contacto.Colonia));
  //       //     worksheet.cell(fila, 8).string(toString(contacto.CodigoPostal));
  //       //     worksheet.cell(fila, 9).string(toString(contacto.Facebook));
  //       //     worksheet.cell(fila, 10).string(toString(contacto.Twitter));
  //       //     worksheet.cell(fila, 11).string(toString((contacto.Whatsapp == 1)?'Si':'No'));
  //       //     worksheet.cell(fila, 12).string(toString(contacto.Lat));
  //       //     worksheet.cell(fila, 13).string(toString(contacto.Lng));
  //       //     worksheet.cell(fila, 14).string(toString(contacto.Referencia));
  //       //     fila++;
  //       // }); 

  //       workbook.write('dispersion.xlsx')
  //     }),
  //   );
  // }
}