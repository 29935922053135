import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';
import { URL_SERVICIOS } from 'src/app/config/config';

@Component({
  selector: 'app-tabla-contactos-segmento',
  templateUrl: './tabla-contactos-segmento.component.html',
  styleUrls: ['./tabla-contactos-segmento.component.css']
})

export class TablaContactosSegmentoComponent implements OnInit {
  data: any[] = [];
  RutaPage: string;
  IdContacto: number;
  // pagina: number = 0;
  // items: [];
  // pageOfItems: Array<any>;
  // ShowModalEmail: boolean = false;
  // personas: any[] = [];

  constructor(
    private _dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public _serviceUsuario:UsuarioService
  ) { }

  ngOnInit() {
    this.getContactosSegmento()
  }

  // onChangePage(pageOfItems: Array<any>) {
  //   this.pageOfItems = pageOfItems;
  // }

  getTotalContactos(par: string) {
    return this.data.map(t => t[par]).reduce((acc, value: number) => acc +  Number(value), 0);
  }

  getContactosSegmento()
  {
    this.activatedRoute.paramMap.subscribe(params => {
      //let pagina: number = +params.get('page');
      // this.pagina = +params.get('page');

      // if (!this.pagina) { this.pagina = 0 };
      // if( p !== -1 ){ this.pagina = 0 }

    // this._dashboardService.getEnviosEmail(fechaInicio, fechaFinal, this.pagina)
    //   .subscribe( res=>{
    //     console.log(res);
    //     this.data = res;
    //     this.items = res.data;
    //   });

    this._dashboardService.getContactosSegmento()
      .subscribe( res=>{
        this.data = res;
        // console.log(this.data);
      });
    });
  }

  exportar(){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;

    window.open(URL_SERVICIOS+'/exportacion-contactos-segmento/' + this.IdContacto);     
  }

}
