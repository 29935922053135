import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import Contacto from 'src/app/models/contacto.model';
import Padron from 'src/app/models/padron.model';
import Unico from 'src/app/models/unico.model';
import Swal from 'sweetalert2';
import { PadronService } from 'src/app/services/service/padron.service';
import { UsuarioService } from 'src/app/services/service.index';
import { MunicipioService } from 'src/app/services/service/municipio.service';
import { OcupacionService } from 'src/app/services/service/ocupacion.service';
import { LocalidadService } from 'src/app/services/service/localidad.service';

declare function mascara(parametro:string);
declare function setFocus(parametro:string);

@Component({
  selector: 'app-form-padron',
  templateUrl: './form-padron.component.html',
  styleUrls: ['./form-padron.component.css']
})
export class FormPadronComponent implements OnInit {

  @Input() Padron:Padron;
  @Input() Contacto:Contacto;

  @Output() getPadrones:EventEmitter<any> = new EventEmitter();

  showUnicos:boolean = false;
  municipios = [];
  ocupacion = [];
  localidades = [];

  padron:Padron = new Padron();
  contacto:Contacto = new Contacto();
  unico:Unico = new Unico();

  txtLocalidad:string = '';

  modo:string = 'nuevo';

  titulo:string = '';

  constructor(
    public _serviceMunicipio: MunicipioService,
    public _serviceOcupacion: OcupacionService,
    public _serviceLocalidad: LocalidadService,
    public _usuarioService:UsuarioService,
    public _servicePadron:PadronService
  ) { }

  ngOnInit() {
    this.modo = (this.Padron)?'editar':'nuevo';
    this.padron = this.Padron || new Padron();
    this.contacto = this.Contacto || new Contacto();

    if(this.modo == 'editar'){
      this.unico = {
        IdUnico:this.contacto.IdUnico,
        Nombre:this.contacto.Nombre,
        ApellidoPaterno:this.contacto.ApellidoPaterno,
        ApellidoMaterno:this.contacto.ApellidoMaterno
      }
      this.buscarLocalidades(this.contacto.IdMunicipio);
    }else{
      this.Padron = new Padron();
      this.contacto = new Contacto();
    }


    this._serviceMunicipio.getMunicipios()
      .subscribe(res=>{
        this.municipios = res;
      });

      this._serviceOcupacion.getOcupaciones()
      .subscribe(res=>{
          this.ocupacion = res;
      });

    setFocus('input-padron');
    mascara('inputCelular');
  }//ngOnInit

  abrirUnicos(){
    this.showUnicos = !this.showUnicos;
  }
  EventoCerrarUnicos(){
    this.abrirUnicos();
  }
  EventoObtenerUnico(u:Unico){
    this.unico = u;
    this.contacto.IdUnico = this.unico.IdUnico;
    this.contacto.Nombre = this.unico.Nombre;
    this.contacto.ApellidoPaterno = this.unico.ApellidoPaterno;
    this.contacto.ApellidoMaterno = this.unico.ApellidoMaterno;
    setFocus('inputCalle');
  }

  add(){
    ///validar que exista coordenadas
    if(!this.contacto.Lat){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Falta agregar la ubicacion!',
        footer: ''
      });
      return;
    }

    ///VALIDAR QUE TENGA LA DESCRIPCION DE PADRON
    if( this.padron.Padron == '' || this.padron.Padron == undefined ){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Falta agregar la descripcion del padron.',
        footer: ''
      });
      return;
    }

    if(this.modo === 'nuevo'){

      this.contacto.IdPerfil = 5;
      this.contacto.Password = '';
      
      this.contacto.IdProyecto = this._usuarioService.getSession().IdProyecto;
      this.padron.IdProyecto = this._usuarioService.getSession().IdProyecto;
      
      let d = {
        contacto:this.contacto,
        padron:this.padron
      }
      this._servicePadron.crear(d)
      .subscribe(res=>{
        this.getPadrones.emit();
      });
    }else{
      let d = {
        contacto:this.contacto,
        padron:{
          IdPadron:this.padron.IdPadron,
          Padron:this.padron.Padron
        }
      }
      this._servicePadron.editar(d.padron.IdPadron,d)
      .subscribe(res=>{
        this.getPadrones.emit();
      });
    }

  }//fin add

  buscarLocalidades(id){
    id = id || -1;
    this._serviceLocalidad.getLocalidades(id)
    .subscribe(res=>{
      this.localidades = res;
    });
  }

  cerrar(){
    this.getPadrones.emit();
  }

  ////////////////////////////////***********************
  ////////////////////////////////EVENTOS MAPA***********************
  DatosMapa(event:any){
    this.contacto.Lat = event.lat;
    this.contacto.Lng = event.lng;
    setFocus('inputRef');
  }
  ////////////////////////////////***********************
  ////////////////////////////////***********************
  ////////////////////////////////***********************

  findTextoLocalidad(id){
    let loc = this.localidades.find(localidad=>localidad.cod_loc == id);
    this.txtLocalidad = (loc.nom_loc || '');
  }
}
