import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';

declare var jQuery:any;
declare var $:any;
let _this;
let start;
let end;

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.css']
})
export class DaterangepickerComponent implements OnInit {
  Filtro_: any = {
                    fechaInicio: "",
                    fechaFinal: ""
                 };

  @Output() public rangos = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    _this = this;
    this.componentesJQuery();
  }

  cambiaRango(){
    this.rangos.emit(this.Filtro_)
  }

  componentesJQuery(){
    this.picker();
  }

  picker(){
    // Daterange picker
    moment.updateLocale('es', {
      dow : 1,
      doy : 7
    })

    start = moment().startOf('week').toDate();
    end = moment().endOf('week').toDate();
    
    function cb(start, end) {
        $('#reportrange').html(moment(start).locale('es').format('LL') + ' a ' + moment(end).locale('es').format('LL'));
        let fechaInicio = moment(start).format('YYYY-MM-DD');
        let fechaFinal = moment(end).format('YYYY-MM-DD');
        
        _this.Filtro_ = {
                          fechaInicio: fechaInicio,
                          fechaFinal: fechaFinal
                       }

        _this.cambiaRango(_this.Filtro_);
    }

    $('.input-daterange-datepicker__').daterangepicker({
          locale: {
          format: 'DD/MM/YYYY',
          applyLabel: 'Aplicar',
          cancelLabel: 'Cerrar',
          fromLabel: 'De',
          toLabel: 'A',
          customRangeLabel: 'Personalizar Rango',
          daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        },
        startDate: start,
        endDate: end,
        opens: 'right',
        ranges: {
          'Hoy': [moment(), moment()],
          'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Últimos 7 Días': [moment().subtract(6, 'days'), moment()],
          'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
          'Este Mes': [moment().startOf('month'), moment().endOf('month')],
          'Último Mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
       },
        buttonClasses: ['btn', 'btn-sm'],
        applyClass: 'btn-danger',
        cancelClass: 'btn-inverse',
        autoApply: false
    }, cb);

    cb(start, end);
  }
}
