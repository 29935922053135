import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import Usuario from 'src/app/models/usuario.model';
import { URL_SERVICIOS } from 'src/app/config/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  url:string;
  usuario:Usuario = {};

  constructor(
    public usuarioService:UsuarioService,
    private router:Router
  ) { 
    this.url = URL_SERVICIOS+'/imagenUsuario/'+this.usuarioService.getSession().IdContacto;
    this.usuario = usuarioService.usuario;
  }

  ngOnInit() {
  }

  openPerfil(){
    this.router.navigateByUrl('/perfil' );
  }

}
