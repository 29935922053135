import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import Padron from 'src/app/models/padron.model';

declare function cargando();
declare function cargado();

@Injectable({
  providedIn: 'root'
})
export class PadronService {

  constructor(
    private http:HttpClient,
    private usuarioService:UsuarioService
  ) { }

  private getHeaders(){
    return {
      headers: new HttpHeaders({'Authorization':this.usuarioService.token})
    };
  }

  getPadron( id:number ){
    let url = URL_SERVICIOS + '/padron/'+id;

    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.proveedor;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        })
        return err;
      })
    );
  }

  getPadrones( IdSegmento:number,page:number ){
    let url = URL_SERVICIOS + '/padrones/'+IdSegmento+'/'+page;

    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.padrones;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        })
        return err;
      })
    );
  }

  getPadronesByAsignado( page:number,termino:string ){

    let url = URL_SERVICIOS + '/padrones-idpersona/'+page+'/'+termino;

    if(termino == '')
      url = URL_SERVICIOS + '/padrones-idpersona/'+page;

    cargando();
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        cargado();
        return resp.padrones;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        })
        return err;
      })
    );
  }

  crear( data:any ){
    let url = URL_SERVICIOS + '/padron';

    cargando();
    return this.http.post( url,data,this.getHeaders() )
    .pipe(
      map((resp:any)=>{
        cargado();
        return resp;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        });
        return err;
      })
    );
  }

  editar( id:number,datos:any ){
    let url = URL_SERVICIOS + '/padron/'+id;

    cargando();
    return this.http.put( url,datos,this.getHeaders() )
    .pipe(
      map((resp:any)=>{
        cargado();
        return resp;
      }),
      catchError(err=>{
        cargado();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.error.error.message || err.error.error,
          footer: ''
        });
        return err;
      })
    );
  }

  
}
