import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { UsuarioService } from './usuario.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import Empresa from 'src/app/models/empresa.model';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(
    private usuarioService:UsuarioService,
    private http:HttpClient
  ) { }

  private getHeaders(){
    return {
      headers: new HttpHeaders({'Authorization':this.usuarioService.token})
    };
  }

  getEmpresa( id:number ){
    let url = URL_SERVICIOS + '/empresa/'+id;
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        return resp.proveedor;
      })
    );
  }

  getEmpresasPage( page:number ){
    let url = URL_SERVICIOS + '/empresas/'+page;
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        return resp.page;
      })
    );
  }

  getEmpresas( ){
    let url = URL_SERVICIOS + '/empresas';
    return this.http.get( url,this.getHeaders() )
    .pipe( 
      map((resp:any)=>{
        if( !resp.ok ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error intentelo de nuevo',
            footer: ''
          })
        }
        return resp.empresas;
      })
    );
  }

  crear( empresa:Empresa ){
    let url = URL_SERVICIOS + '/empresa';
    return this.http.post( url,empresa,this.getHeaders() );
  }

  editar( id:number,empresa:Empresa ){
    let url = URL_SERVICIOS + '/empresa/'+id;
    return this.http.put( url,empresa,this.getHeaders() );
  }

  cambioEstatus( id:number,status:number ){
    let url = URL_SERVICIOS + '/empresa_cambiar_estatus/'+id;
    let empresa:Empresa = { Activo:status };
    return this.http.put( url,empresa,this.getHeaders() );
  }
}
