export default class Ceofiltro{
    Periodo1?: any;
    Periodo2?: any;
    AmbitoGeografico?: string;
    Tipo?: string;
    Municipio?: string;
    Localidad?: string;
    DistritoLocal?: string;
    DsitritoFederal?: string;
    DsitritoSeccion?: string;
    Edad1?: string;
    Edad2?: String;
    Genero?: number;
    Mes?: number;
    IdPadron?: any;
    bandera?: boolean;
    TipoPersona?: any;
    BanderaSMS?: boolean =false;
    BanderaCorreo?: boolean =false;
    Ocupacion?: any;
}