import { AbstractControl } from "@angular/forms";
import { UnicoService } from "src/app/services/service/unico.service";
import { map } from 'rxjs/operators';

export class Validaciones{

    static isIdOficialValido(control:AbstractControl){
        let value = control.value;
        if(value){
            let expreg = new RegExp('[A-Z]{6}[0-9]{8}[M|H][0-9]{3}');
            console.log(expreg.exec(value));
            if(expreg.test(value))
                return null;
            else
                return {isValido:true}
        }
        return null;
    }

    static validateExistSeccion( unicosService:UnicoService ){
        return (control:AbstractControl)=>{
            const value = control.value;
            return unicosService.isValidoLaSeccion( value )
            .pipe(
                map( (response:any)=>{
                    return response.isSeccionAvailable ? null : {isSeccionAvailable:true};
                })
            )
        };
    }
    
}