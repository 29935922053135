import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';
import { URL_SERVICIOS } from 'src/app/config/config';

@Component({
  selector: 'app-tabla-contactos-padron',
  templateUrl: './tabla-contactos-padron.component.html',
  styleUrls: ['./tabla-contactos-padron.component.css']
})

export class TablaContactosPadronComponent implements OnInit {
  data:any[];
  RutaPage: string = '/contactos-padron';
  IdContacto: number;
  //pagina: number = 0;
  //items: [];
  dashboard: [];
  //pageOfItems: Array<any>;
  //ShowModalEmail: boolean = false;
  //personas: any[] = [];

  constructor(
    private _dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public _serviceUsuario:UsuarioService
  ) { }

  ngOnInit() {
    this.getContactosPadron(0)
  }

  // onChangePage(pageOfItems: Array<any>) {
  //   this.pageOfItems = pageOfItems;
  // }

  getTotalContactos(par: string) {
    return this.dashboard.map(t => t[par]).reduce((acc, value) => acc + value, 0);
  }

  getContactosPadron(page: number)
  {
    this.activatedRoute.paramMap.subscribe(params => {
      //let pagina: number = +params.get('page');

    // this._dashboardService.getEnviosEmail(fechaInicio, fechaFinal, this.pagina)
    //   .subscribe( res=>{
    //     console.log(res);
    //     this.data = res;
    //     this.items = res.data;
    //   });

    this._dashboardService.getContactosPadron((page || 0))
      .subscribe( res=>{
        console.log(res);
        this.data = res;
        this.dashboard = res.data;
        //this.items = res.data;
      });
    });
  }

  onSeleccion(page:number){
    this.getContactosPadron(page);
  }

  exportar(){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;

    window.open(URL_SERVICIOS+'/exportacion-contactos-padron/' + this.IdContacto);     
  }

}
