import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grafica-encabezado',
  templateUrl: './grafica-encabezado.component.html',
  styleUrls: ['./grafica-encabezado.component.css']
})
export class GraficaEncabezadoComponent implements OnInit {
  @Input() IdPerfil: number;

  RutaPage: string = '/dashboard/page';
  dashboard = {
    TotalProyectos: 0,
    TotalSegmentos: 0,
    TotalPadrones: 0,
    TotalContactos: 0,
    TotalContactosUnicos: 0,
    TotalUnicos: 0,
    CorreosEnviados: 0,
    CorreosNoEnviados: 0,
    TotalMensajes: 0,
    MensajesEnviados: 0,
    MensajesNoEnviados: 0,
  }

  constructor(
    private _dashboardService:DashboardService,
    private router:Router
  ) { }

  ngOnInit() {
    this.getGraficaEncabezado();
    //console.log("IDPERFIL: " + this.IdPerfil);
  }

  getGraficaEncabezado(){
    this._dashboardService.getGraficaEncabezado()
      .subscribe( res=>{
        //console.log(res);
        this.dashboard = res;
      });
  }
}