import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-sms',
  templateUrl: './modal-sms.component.html',
  styleUrls: ['./modal-sms.component.css']
})
export class ModalSmsComponent implements OnInit {
  @Input() ShowModalSms:boolean;
  @Input() personas: Array<any> = [];
  @Output() cerrarModal:EventEmitter<boolean> = new EventEmitter();

  oculto:string = 'oculto';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    if( this.ShowModalSms ){
      this.oculto = '';
    }else{
      this.oculto = 'oculto';
    }
  }

  cerrar(){
    this.cerrarModal.emit( false );
  }

}