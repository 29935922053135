import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';

let _this;

@Component({
  selector: 'app-grafica-envios',
  templateUrl: './grafica-envios.component.html',
  styleUrls: ['./grafica-envios.component.css']
})
export class GraficaEnviosComponent implements OnInit {
  @ViewChild ('contenedorEnvios', {static : true})  chartDivEnvios: ElementRef;
  @Input() Filtro_: any;
  
  RutaPage: string = '/dashboard/page';
  dashboard = {
    TotalEnvios: 0,
    CorreosEnviados: 0,
    MensajesEnviados: 0
  };
  
  //form: FormGroup;
  //meses = [];
  //_this = this;

  highcharts = Highcharts;
  chartOptions;
  
  fechaInicio: string;
  fechaFinal: string;

  constructor(
      private _dashboardService:DashboardService,
      private router:Router,
      //private formBuilder: FormBuilder,
  ) {
      // this.form = this.formBuilder.group({
      //   meses: ['']
      // });

      // //this._this = this;
      // this.meses = this.getMeses();
  }

  getMeses() {
        return [
                  { id: '0', mes: 'Todos' },
                  { id: '1', mes: 'Enero' },
                  { id: '2', mes: 'Febrero' },
                  { id: '3', mes: 'Marzo' },
                  { id: '4', mes: 'Abril' },
                  { id: '5', mes: 'Mayo' },
                  { id: '6', mes: 'Junio' },
                  { id: '7', mes: 'Julio' },
                  { id: '8', mes: 'Agosto' },
                  { id: '9', mes: 'Septiembre' },
                  { id: '10', mes: 'Octubre' },
                  { id: '11', mes: 'Noviembre' },
                  { id: '12', mes: 'Diciembre' }
                ];
              }

  ngOnInit() {
    var start = moment().format('YYYY-MM-DD');
    var end = moment().format('YYYY-MM-DD');

    this.getTotalesGraficaEnvios(start, end);
    this.getGraficaEnvios(start, end);
  }
  ngAfterViewInit(){
    _this = this;
    //this.componentesJQuery();
  }

  ngOnChanges() {
    //console.log("test");
    //console.log(this.Filtro_.fechaInicio);

    var fechaI = moment(this.Filtro_.fechaInicio).format("YYYY-MM-DD");
    var fechaF = moment(this.Filtro_.fechaFinal).format("YYYY-MM-DD");

    // fechaI = fechaI.format('YYYY-MM-DD');
    // fechaF = this.Filtro_.Periodo2.format('YYYY-MM-DD');
    
    this.getTotalesGraficaEnvios(fechaI, fechaF);
    this.getGraficaEnvios(fechaI, fechaF);
  }

  getTotalesGraficaEnvios(fechaInicio: string, fechaFinal: string){
    //console.log(fechaInicio);
    this._dashboardService.getTotalesGraficaEnvios(fechaInicio, fechaFinal)
      .subscribe( res=>{
        //console.log(res);
        this.dashboard = res;
      });
  }

  getGraficaEnvios(fechaInicio: string, fechaFinal: string){
    let categories = [];
    let correo = [];
    let sms = [];
    
    this._dashboardService.getGraficaEnvios(fechaInicio, fechaFinal)
    .subscribe( res=>{
        res.forEach(row => {
          if (parseInt(row.CorreosEnviados) + parseInt(row.MensajesEnviados) > 0){
            categories.push(formatDate(row.fecha, 'dd-MM-yyyy', 'en-US'))
            correo.push(parseInt(row.CorreosEnviados))
            sms.push(parseInt(row.MensajesEnviados))
          }
        });
        
        this.chartOptions = {
            chart: {
              type: "spline"
            },
            title: {
              text: "Avance de envio"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Totales"
              } 
            },
            credits: {
              enabled: false
            },
            series: [
              {
                name: 'Total Email Enviados',
                data: correo
              },
              {
                name: 'Total SMS Enviados',
                data: sms
              }
            ]
          };

        Highcharts.chart(this.chartDivEnvios.nativeElement, this.chartOptions);

      });

      //console.log(categories);
      //console.log(correo);
      //console.log(sms);
      //this.chartOptions.xAxis.categories = categories;
      //this.highcharts.Series[0].setData(correo, true);
      //this.highcharts.Series[1].setData(sms);
      //this.chartOptions.series[0].setData(correo);
      // this.chartOptions.series[0]['data'] = correo;
      // this.chartOptions.series[1]['data'] = sms;

      //console.log(this.chartOptions);
  }

  // changeMonth(e){
  //   console.log(e.target.value)
  //   this.getGraficaEnvios(e.target.value);
  // }
  
  // cambiar(){
  //   this.title = "Luigi lo ha cambiado"
  //   this.cambiartitulodash.emit(this.title)
  // }

}
