import { Component, OnInit,Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { CeoSegmentoPadronesService } from "src/app/services/service/ceoSegmentoPadrones.service";
@Component({
  selector: 'app-graficas-barras',
  templateUrl: './graficas-barras.component.html',
  styleUrls: ['./graficas-barras.component.css']
})
export class GraficasBarrasComponent implements OnInit {
  @Input() Totales_Banner: any;
  @Input() FiltroAplicar_: any;
  barraHonomasticos = Highcharts;  chartOptionsHonomasticos;
  barraMunicipiosAdministrativo = Highcharts;  chartOptionsMunicipiosAdministrativo;
  barraMunicipiosPolitico = Highcharts;  chartOptionsMunicipiosPolitico;
  barraDistritoLocal = Highcharts;  chartOptionsDistritoLocal;
  barraDistritoFederal = Highcharts;  chartOptionsDistritoFederal;
  barraSecciones = Highcharts;  chartOptionsSecciones;
  constructor(private ceoSegmentoPadronesService: CeoSegmentoPadronesService) { }
  ngOnInit() {
    // console.clear();
    // this.GraficaBarraHonomasticos([''],[0]);
  }
  ngOnChanges() {
    //.log(this.FiltroAplicar_);
    this.getServidor(this.FiltroAplicar_);
  }
  getServidor(filtro){
    this.ceoSegmentoPadronesService.getGraficaMes(filtro).subscribe(res =>{
      let data: any=[]; let values:any=[];
      for (let index = 0; index < res.length; index++) {
        data.push(res[index].Mes)
        values.push(res[index].Total)
      }
      
      this.GraficaBarraHonomasticos(data,values);
    });
    // Municipios Administrador
    this.ceoSegmentoPadronesService.getGraficaMunicipiosAdministrativo(filtro).subscribe(res=>{
      let data: any=[]; let values:any=[];
      for (let index = 0; index < res.length; index++) {
        data.push(res[index].nom_mun)
        values.push(res[index].Total)
      }
      // this.GraficaBarraHonomasticos(data,values);
      this.GraficaBarraMunicipiosAdministrativo(data,values);
    });
    // Municipios Politicos
    this.ceoSegmentoPadronesService.getGraficaMunicipiosPolitico(filtro).subscribe(res=>{
      let data: any=[]; let values:any=[];
      for (let index = 0; index < res.length; index++) {
        data.push(res[index].nom_mun)
        values.push(res[index].Total)
      }
      this.GraficaBarraMunicipiosPoliticos(data,values);
    });
    // Distrito Local
    this.ceoSegmentoPadronesService.getGraficaDistritoLocal(filtro).subscribe(res =>{
      let data: any=[]; let values:any=[];
      for (let index = 0; index < res.length; index++) {
        data.push(res[index].DISTRITO_L)
        values.push(res[index].Total)
      }
      this.GraficaBarraDistritoLocal(data,values);
    });
    // Distrito Federal
    this.ceoSegmentoPadronesService.getGraficaDistritoFederal(filtro).subscribe(res =>{
      let data: any=[]; let values:any=[];
      for (let index = 0; index < res.length; index++) {
        data.push(res[index].DISTRITO_F)
        values.push(res[index].Total)
      }
      this.GraficaBarraDistritoFederal(data,values);
    });
    // Secciones
    this.ceoSegmentoPadronesService.getGraficaSecciones(filtro).subscribe(res =>{
      let data: any=[]; let values:any=[];
      for (let index = 0; index < res.length; index++) {
        data.push(res[index].SECCION)
        values.push(res[index].Total)
      }
      this.GraficaBarraSecciones(data,values);
    });
  }
  GraficaBarraHonomasticos(datos,valores){
    // console.log(datos)
    // console.log(valores)
    var data = [{
      name: 'Onomásticos',
      data: valores
    }];
    var categorias = datos;
    this.chartOptionsHonomasticos = {   
      chart: {
        type: "column"//bar
      },
      title: {
        text: "Onomásticos"
      },
      xAxis:{
        categories: categorias
      },
      yAxis: {          
        title:{
            text:"Mes"
        } 
      },
      legend:{
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        shadow: false
      },
      series: data
    };
  }
  GraficaBarraMunicipiosAdministrativo(datos,valores){
    var data = [{
      name: 'Municipio',
      data: valores
    }];
    var categorias =datos;
    this.chartOptionsMunicipiosAdministrativo = {   
      chart: {
        type: "column"
      },
      title: {
        text: "Ambito Administrativo"
      },
      xAxis:{
        categories: categorias
      },
      yAxis: {          
        title:{
            text:"Municipio"
        } 
      },
      legend:{
        shadow: true
      },
      series: data
    };
  }
  GraficaBarraMunicipiosPoliticos(datos,valores){
    var data = [{
      name: 'Total',
      data: valores
    }];
    var categorias =datos;
    this.chartOptionsMunicipiosPolitico = {   
      chart: {
        type: "column"
      },
      title: {
        text: "Ambito Politico"
      },
      xAxis:{
        categories: categorias
      },
      yAxis: {          
        title:{
            text:"Municipio"
        } 
      },
      legend:{
        shadow: true
      },
      series: data
    };
  }
  GraficaBarraDistritoLocal(datos,valores){
    var data = [{
      name: 'Total',
      data: valores
    }];
    var categorias =datos;
    this.chartOptionsDistritoLocal = {   
      chart: {
        type: "column"
      },
      title: {
        text: "Distrito Local"
      },
      xAxis:{
        categories: categorias
      },
      yAxis: {          
        title:{
            text:"Distrito Local"
        } 
      },
      legend:{
        shadow: true
      },
      series: data
    };
  }
  GraficaBarraDistritoFederal(datos,valores){
    var data = [{
      name: 'Total',
      data: valores
    }];
    var categorias =datos;
    this.chartOptionsDistritoFederal = {   
      chart: {
        type: "column"
      },
      title: {
        text: "Distrito Federal"
      },
      xAxis:{
        categories: categorias
      },
      yAxis: {          
        title:{
            text:"Distrito Federal"
        } 
      },
      legend:{
        shadow: true
      },
      series: data
    };
  }
  GraficaBarraSecciones(datos,valores){
    var data = [{
      name: 'Total',
      data: valores
    }];
    var categorias =datos;
    this.chartOptionsSecciones = {   
      chart: {
        type: "column"
      },
      title: {
        text: "Secciones"
      },
      xAxis:{
        categories: categorias
      },
      yAxis: {          
        title:{
            text:"Secciones"
        } 
      },
      legend:{
        shadow: true
      },
      series: data
    };
  }
  GraficaBarraLocalidades(){
    var data = [{
      name: 'Total',
      data: [2,1,5]
    }];
    var categorias =["Cozumel","Punta Celarain","Punta Molas"];
    this.chartOptionsDistritoLocal = {   
      chart: {
        type: "column"
      },
      title: {
        text: "Localidad"
      },
      xAxis:{
        categories: categorias
      },
      yAxis: {          
        title:{
            text:"Localidad"
        } 
      },
      legend:{
        shadow: true
      },
      series: data
    };
  }
}
