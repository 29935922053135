import { Routes,RouterModule } from "@angular/router";
import { PageComponent } from './page.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { ClientesComponent } from './clientes/clientes.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginGuardGuard } from '../services/service.index';
import { UsuarioFormComponent } from './usuarios/form/usuario-form/usuario-form.component';
import { ProveedorFormComponent } from './proveedores/proveedor-form/proveedor-form.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { ProyectoFormComponent } from './proyectos/proyecto-form/proyecto-form.component';
import { SegmentosComponent } from './segmentos/segmentos.component';
import { SegmentoFormComponent } from './segmentos/segmento-form/segmento-form.component';
import { PadronesComponent } from './padrones/padrones.component';
import { FormPadronComponent } from './padrones/form-padron/form-padron.component';
import { CeosComponent } from './ceos/ceos.component';
import { ConfiguracionComponent } from "./ceos/configuracion/configuracion.component";
import { PlantillasComponent } from "./ceos/plantillas/plantillas.component";
import { CaracteristicaTipoComponent } from "./ceos/caracteristica-tipo/caracteristica-tipo.component";
import { PerfilComponent } from '../perfil/perfil.component';

const pageRoute: Routes = [
    {
        path:'',
        component: PageComponent,
        canActivate: [ LoginGuardGuard ],
        children:[
            { path:'dashboard',component: DashboardComponent },
            { path:'dashboard/tabla-envios/:fechaInicio/:fechaFinal/:page;',component: DashboardComponent },
            { path:'usuarios',component: UsuariosComponent },
            { path:'usuarios/page/:id/:page',component: UsuariosComponent },
            { path:'usuario-form/:IdProyecto/:page',component: UsuarioFormComponent },
            { path:'usuario-form/:IdProyecto/:page/:id',component: UsuarioFormComponent },
            { path:'proveedores',component: ProveedoresComponent },
            { path:'proveedor-form',component: ProveedorFormComponent },
            { path:'proveedor-form/:id',component: ProveedorFormComponent },
            { path:'clientes',component: ClientesComponent },
            { path:'proyectos',component: ProyectosComponent },
            { path:'proyecto-form',component: ProyectoFormComponent },
            { path:'proyecto-form/:id',component: ProyectoFormComponent },
            { path:'segmentos',component: SegmentosComponent },
            { path:'segmentos-form',component: SegmentoFormComponent },
            { path:'segmentos-form/:id',component: SegmentoFormComponent },
            { path:'padrones',component: PadronesComponent },
            { path:'padron',component: FormPadronComponent },
            { path:'padron/:id',component: FormPadronComponent },
            { path: 'ceos', component: CeosComponent},
            { path: 'ceos/configuracion', component: ConfiguracionComponent},
            { path: 'ceos/plantilla', component: PlantillasComponent},
            { path: 'ceos/caracteristicaTipo', component: CaracteristicaTipoComponent},
            { path: 'perfil',component:PerfilComponent}
        ]
    }
];

export const PAGES_ROUTES = RouterModule.forChild( pageRoute );