import { Component, OnInit } from '@angular/core';
import Proveedor from '../../models/proveedor.model';
import { ProveedorService } from 'src/app/services/service/proveedor.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css']
})
export class ProveedoresComponent implements OnInit {
  
  ACTIVO: number = 1;
  INACTIVO: number = 2;

  proveedores:Proveedor[] = [];

  RutaPage: string = '/proveedores/page';
  data: any;

  constructor(
    private _proveedorService:ProveedorService,
    private router:Router
  ) { }

  ngOnInit() {
    this.getProveedores();
  }

  getProveedores(){
    this._proveedorService.getProveedoresPage( 0 )
      .subscribe( res=>{
        this.data = res;
        this.proveedores = res.data;
        console.log(res);
      });
  }

  nuevo(){
    this.router.navigateByUrl('proveedor-form');
  }

  editar( proveedor:Proveedor ){
    this.router.navigateByUrl('proveedor-form/'+proveedor.IdProveedor);
  }

  cambiarEstautsProveedor( status:number,proveedor:Proveedor ){
    let mensajes = ( status == this.INACTIVO )?{
      titulo: '¿Esta seguro de Desactivar este proveedor?',
      botonConfirmar:'Si, Desactivar',
      botonCancelar:'No, Cancelar!',
      exito:['Desactivado','El proveedor fue desactivado.']
    }:
    {
      titulo: '¿Esta seguro de Activar este proveedor?',
      botonConfirmar:'Si, Activar',
      botonCancelar:'No, Cancelar!',
      exito:['Activado','El proveedor fue activado.']
    };

    Swal.fire({
      title: mensajes.titulo,
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: mensajes.botonConfirmar,
      cancelButtonText: mensajes.botonCancelar
    }).then((result) => {
      if (result.value) {
        this._proveedorService.cambioEstatus( proveedor.IdProveedor,status )
          .subscribe( resp =>{
            
            Swal.fire(mensajes.exito[0],mensajes.exito[1],'success');
            this.getProveedores();
          })
        
      }
    });
  }

}
