import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuarioService } from '../service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class EstadoService {

  constructor(
    private http: HttpClient,
    private usuarioService:UsuarioService
  ) { }

  getEstados(){
    let url = URL_SERVICIOS + '/estados';
    let headers = new HttpHeaders({'Authorization':this.usuarioService.token});
    const options = {
      headers
    }
    return this.http.get( url,options )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.estados;
        }),
        catchError(err=>{
          
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.error.error.message || err.error.error,
            footer: ''
          });
          return err;
        })
      );
  }//fin getPerfiles
}
