import { Component, OnInit, Input,OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-paginacion',
  templateUrl: './paginacion.component.html',
  styleUrls: ['./paginacion.component.css']
})
export class PaginacionComponent implements OnInit {
  
  @Input() data:any;
  @Input() RutaPage:string;
  paginas:number[];

  desde:number;
  hasta:number;

  constructor() { }

  ngOnInit() {
    this.calcular();
  }

  ngOnChanges(changes:SimpleChanges){
    this.calcular();
    /*let paginadorActualizado = changes['data'];
    if( paginadorActualizado.previousValue){
      this.calcular();
    }*/
  }

  private calcular():void{
    this.desde = Math.min( Math.max(1,this.data.Pagina-4),this.data.Final-5 );
    this.hasta = Math.max( Math.min( this.data.Final,this.data.Pagina+4 ),6 );
    if( this.data.Final > 5){
      this.paginas = new Array<number>( this.hasta-this.desde+1 ).fill( 0 ).map( (valor,indice) =>{ return indice + this.desde  });
    }else{
      this.paginas = new Array<number>( this.data.Final ).fill( 0 ).map( (valor,indice) =>{ return indice + 1  });
    }
  }

}
