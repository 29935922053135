import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { UsuarioService } from 'src/app/services/service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import * as Highcharts from 'highcharts';

declare var google;
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-mapa-deys-padron-distrito-federal',
  templateUrl: './mapa-deys-padron-distrito-federal.component.html',
  styleUrls: ['./mapa-deys-padron-distrito-federal.component.css']
})

export class MapaDeysPadronDistritoFederalComponent implements OnInit {
  @Input() DistritoFederal_: any;
  @ViewChild('map4',{ static: true }) mapaElement:ElementRef;
  @ViewChild ('contenedorEdadesDF', {static : true})  chartDivEdadesDF: ElementRef;
  @ViewChild ('contenedorOnomasticosDF', {static : true})  chartDivOnomasticosDF: ElementRef;
  @ViewChild ('contenedorOcupacionesDF', {static : true})  chartDivOcupacionesDF: ElementRef;
  @ViewChild ('contenedorOrigenDF', {static : true})  chartDivOrigenDF: ElementRef;

  IdContacto: number;
  IdSeccion: number;
  dashboard: any;

  chartOptions_generoedad_distrito_federal;
  chartOptions_onomastico_distrito_federal;
  chartOptions_ocupacion_distrito_federal;
  chartOptions_origen_distrito_federal;
  
  geoJsonObject: any = {};
  geoJson: any;
  GeoData: any = {};
  bounds = new google.maps.LatLngBounds();
  seccion: number;
  totales: any = {};
  all_polygons: any = [];
  polygon = null;
  frecuencias: any = [];
  arr:any = [];

  infoWindow: google.maps.InfoWindow;
  map: google.maps.Map;
  
  constructor(
    private _dashboardService:DashboardService,
    public _serviceUsuario:UsuarioService
  ) {}

  ngOnInit() 
  {
    $("#sidebar-distrito-federal").slideDown(50), $("#sidebar-distrito-federal").toggleClass("shw-rside");
    this.cargarMapa();
    //this.getGeoJson(7);
  }

  ngOnChanges() 
  {
    //this.cargarMapa();
    this.arr = [];
    this.getGeoJson(this.DistritoFederal_);
  }

  ngAfterViewInit(){
    this.componentesJQuery();
  }

  componentesJQuery(){
    $('[data-plugin="knob"]').knob();
    $(".scroll-sidebar, .right-side-panel, .message-center, .right-sidebar").perfectScrollbar()

    $("#leyendaDF").perfectScrollbar();

    var slider = $("#menu-bar-df").slideReveal({
      //width: 100,
      push: false,
      position: "left",
      // speed: 600,
      trigger: $("#btnShowLegendDF"),
      // autoEscape: false,
      shown: function(obj){
        $("#btnShowLegendDF").html('<span style="background: url(../../../../assets/images/Icono-leyenda-close.png) no-repeat transparent; background-size: cover; width: 40px; height: 40px; display: inline-block;"></span>');        
        // obj.find(".handle").html('<span style="background: url(../../../../assets/images/Icono-leyendai.png) no-repeat transparent; background-size: cover; width: 40px; height: 40px; display: inline-block;"></span>');
        obj.addClass("left-shadow-overlay");
      },
      hidden: function(obj){
        $("#btnShowLegendDF").html('<span style="background: url(../../../../assets/images/Icono-leyenda-open.png) no-repeat transparent; background-size: cover; width: 40px; height: 40px; display: inline-block;"></span>');
        // obj.find(".handle").html('<span style="background: url(../../../../assets/images/Icono-leyenda.png) no-repeat transparent; background-size: cover; width: 40px; height: 40px; display: inline-block;"></span>');
        obj.removeClass("left-shadow-overlay");
      }
    });
  }

  cargarMapa()
  {
    let latLng = new google.maps.LatLng(18.519742, -88.300422);

    const mapaOpciones: google.maps.MapOptions = {
      center: latLng,
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      
    };

    this.map = new google.maps.Map(this.mapaElement.nativeElement, mapaOpciones);
  }

  getGeoJson(idDistrito)
  {
    this.LimpiaMapa();

    this._dashboardService.getGeoJsonDistritoFederalPadron(idDistrito)
      .subscribe( res=>{
        this.geoJsonObject = res;
        this.GeoData = this.geoJsonObject.geo;
        
        this._dashboardService.getTablaFrecuenciasDFPadron(idDistrito).subscribe( res=>{
          this.frecuencias = res;
          
          var xmin: number = this.frecuencias[0].xMin;
          var intervalo: number = Math.round(1 + 3.322 * Math.log10(this.frecuencias[0].totalDatos));

          if (this.frecuencias[0].Rango == 0)
            var amp:any = 1
          else
            var amp: any = this.frecuencias[0].Rango / intervalo;

          var rangoi;
          var rangos;
          
          var colores = this.generateColor('#DE364A','#FFFF00', intervalo);
          rangoi = xmin;
          
          for (var i = 0; i < intervalo; i++)
          {
            rangos = parseInt(rangoi) + parseInt(amp);
            this.arr.push({rango:rangos, etiqueta:'De ' + rangoi + ' a ' + rangos + ' Contactos', totalSecciones:0, totalContactos:0, icono:'m-r-5 fa fa-square' , color: '#' + colores[i]});
            rangoi = rangos + 1;
          }
        
          for (var i = 0; i < this.GeoData.length; i++)
          {
            let ini = xmin;
            for(var x= 0; x< this.arr.length; x++){
              if(this.GeoData[i].TotalContactosUnicos >=ini && this.GeoData[i].TotalContactosUnicos <= this.arr[x].rango )
              {
                this.arr[x].totalSecciones += 1;
                this.arr[x].totalContactos += this.GeoData[i].TotalContactosUnicos;
                this.GeoData[i].Ampli = x;
                this.GeoData[i].Color = this.arr[x].color;
              }
        
              ini += parseInt(amp)+1;
            }
          }

          for (let index = 0; index < this.GeoData.length; index++)
          {
            const rows = this.GeoData[index];
            this.PintaPoligono(rows);
          }

          this.map.fitBounds(this.bounds);
        });//segunda peticion
      });
  }

  getInfoSeccion(seccion: number)
  {
    this._dashboardService.getTotalesGeneroSeccion(seccion)
    .subscribe( res=>{
      this.totales = res;

      let t = res.TotalUnicos;
      //console.log(t);

      let p = 0;
      p = (res.Hombres / t) * 100;
    
      $('.GH').val(p+"%").trigger('change');
    
      p = (res.Mujeres / t) * 100;
      $('.GM').val(p+"%").trigger('change');
    });
  }

  hex (c) {
    var s = "0123456789abcdef";
    var i = parseInt (c);
    if (i == 0 || isNaN (c))
      return "00";
    i = Math.round (Math.min (Math.max (0, i), 255));
    return s.charAt ((i - i % 16) / 16) + s.charAt (i % 16);
  }
  
  /* Convert an RGB triplet to a hex string */
  convertToHex (rgb) {
    return this.hex(rgb[0]) + this.hex(rgb[1]) + this.hex(rgb[2]);
  }
  
  /* Remove '#' in color hex string */
  trim (s) { return (s.charAt(0) == '#') ? s.substring(1, 7) : s }
  
  /* Convert a hex string to an RGB triplet */
  convertToRGB (hex) {
    var color = [];
    color[0] = parseInt ((this.trim(hex)).substring (0, 2), 16);
    color[1] = parseInt ((this.trim(hex)).substring (2, 4), 16);
    color[2] = parseInt ((this.trim(hex)).substring (4, 6), 16);
    return color;
  }
  
  generateColor(colorStart,colorEnd,colorCount){
  
    // The beginning of your gradient
    var start = this.convertToRGB (colorStart);    
  
    // The end of your gradient
    var end   = this.convertToRGB (colorEnd);    
  
    // The number of colors to compute
    var len = colorCount;
  
    //Alpha blending amount
    var alpha = 0.0;
  
    var salida = [];
    
    for (var i = 0; i < len; i++) {
      var c = [];
      alpha += (1.0/len);
      
      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];
  
      salida.push(this.convertToHex (c));
      
    }
    
    return salida;
  }

  getTablaFrecuenciasPadron(distrito: number)
  {
    this._dashboardService.getTablaFrecuenciasDFPadron(distrito)
    .subscribe( res=>{
      this.totales = res;
      let t = res;
    });
  }

  getGraficaEdades(seccion: number){
    let categories = ['0-04','05-13','14-17','18-30','31-50','51-70','71-90','91->'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaGenerosSeccion(seccion)
    .subscribe( res=>{
        totalesH = [parseInt(res.HEDAD04) * -1, parseInt(res.HEDAD513) * -1, parseInt(res.HEDAD1417) * -1, parseInt(res.HEDAD1830) * -1, parseInt(res.HEDAD3150) * -1, parseInt(res.HEDAD5170) * -1, parseInt(res.HEDAD7190) * -1, parseInt(res.HEDAD91) * -1]
        totalesM = [parseInt(res.MEDAD04), parseInt(res.MEDAD513), parseInt(res.MEDAD1417), parseInt(res.MEDAD1830), parseInt(res.MEDAD3150), parseInt(res.MEDAD5170), parseInt(res.MEDAD7190), parseInt(res.MEDAD91)]

        this.chartOptions_generoedad_distrito_federal = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Género y Edad"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', edad de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivEdadesDF.nativeElement, this.chartOptions_generoedad_distrito_federal);

    });
  }

  getGraficaOnomasticos(seccion: number){
    let categories = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaOnomasticoSeccion(seccion)
    .subscribe( res=>{
        totalesH = [parseInt(res.HENE) * -1, parseInt(res.HFEB) * -1, parseInt(res.HMAR) * -1, parseInt(res.HABR) * -1, parseInt(res.HMAY) * -1, parseInt(res.HJUN) * -1, parseInt(res.HJUL) * -1, parseInt(res.HAGO) * -1, parseInt(res.HSEP) * -1, parseInt(res.HOCT) * -1, parseInt(res.HNOV) * -1, parseInt(res.HDIC) * -1]
        totalesM = [parseInt(res.MENE), parseInt(res.MFEB), parseInt(res.MMAR), parseInt(res.MABR), parseInt(res.MMAY), parseInt(res.MJUN), parseInt(res.MJUL), parseInt(res.MAGO), parseInt(res.MSEP), parseInt(res.MOCT), parseInt(res.MNOV), parseInt(res.MDIC)]

        this.chartOptions_onomastico_distrito_federal = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Onomástico"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', mes de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivOnomasticosDF.nativeElement, this.chartOptions_onomastico_distrito_federal);

    });
  }

  getGraficaOcupaciones(seccion: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOcupacionSeccion(seccion)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Ocupacion)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_ocupacion_distrito_federal = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Ocupación"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOcupacionesDF.nativeElement, this.chartOptions_ocupacion_distrito_federal);

    });
  }

  getGraficaOrigen(seccion: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOrigenSeccion(seccion)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Estado)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_origen_distrito_federal = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Lugar de Origen"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOrigenDF.nativeElement, this.chartOptions_origen_distrito_federal);
    });
  }

  PintaPoligono(GeoPinta){
    const datos = GeoPinta.GeoJson;
    var coords = datos.coordinates;
    
    var paths = [];

    for (var i = 0; i < coords.length; i++) {
            for (var j = 0; j < coords[i].length; j++) {
                var path = [];
                for (var k = 0; k < coords[i][j].length; k++) {
                    var ll = new google.maps.LatLng(coords[i][j][k][0],coords[i][j][k][1]);
                    path.push(ll);
                }
                paths.push(path);
            }
      }

      this.polygon = new google.maps.Polygon({
        paths: paths,
        strokeColor: GeoPinta.Color,
        strokeOpacity: 1,
        strokeWeight: 5,
        fillColor: GeoPinta.Color,
        fillOpacity: 0.25,
      });

      this.polygon.setMap(this.map);
      this.all_polygons.push(this.polygon);
      this.AgregarInfoWindow(this.polygon, GeoPinta.SECCION);
      // this.polygon.addListener('click', e =>{
      //   alert("hola")
      // });
  }

  AgregarInfoWindow(polygon, seccion)
  {
    polygon.addListener('click', e =>{
      this.seccion = seccion;
      this.IdSeccion = seccion;

      this.getInfoSeccion(seccion);
      this.getGraficaEdades(seccion);
      this.getGraficaOnomasticos(seccion);
      this.getGraficaOcupaciones(seccion);
      this.getGraficaOrigen(seccion);

      $("body").addClass("modal-open");

      var modal = document.getElementById("modal-distrito-federal");
      modal.style.display = "block";

      $("#sidebar-distrito-federal").slideDown(50), $("#sidebar-distrito-federal").toggleClass("shw-rside");
    });

    for (var i = 0; i < polygon.getPath().getLength(); i++) {
      this.bounds.extend(polygon.getPath().getAt(i));
    }
  }

  LimpiaMapa(){
    // Solo cuando hay elementos
    if(this.polygon != null){
        // Recorrer el arreglo de todos los poligons
        for (var i = 0; i < this.all_polygons.length; i++) {
          // Eliminar aplicar el null al poligo con la funcion de "setMap"
           this.all_polygons[i].setMap(null);
        }
        // Inicialziando variables
        this.all_polygons = [];
        this.polygon = null;
        this.bounds = new google.maps.LatLngBounds();
    }  
  }

  exportarContactosSeccionDtl(){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-seccion-padron-detalle/' + this.IdContacto + '/' + this.IdSeccion);
  }

  cerrar()
  {
    $("#tabOcupacionesDF").removeClass("active")
    $("#tabOnomasticosDF").removeClass("active")
    $("#tabOrigenDF").removeClass("active")
    $("#tabEdadesDF").addClass("active")

    $("#ocupaciones_distrito_federal").removeClass("active")
    $("#onomasticos_distrito_federal").removeClass("active")
    $("#origen_distrito_federal").removeClass("active")
    $("#edades_distrito_federal").addClass("active")
    
    $("body").removeClass("modal-open")
    
    $("#sidebar-distrito-federal").delay(300).slideDown(50);
    
    $("#sidebar-distrito-federal").toggleClass("shw-rside").promise().done(function(){
      var modal = document.getElementById("modal-distrito-federal");
      modal.style.display = "none";
    });
  }

}