import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-grafica-encabezado-segmento',
  templateUrl: './grafica-encabezado-segmento.component.html',
  styleUrls: ['./grafica-encabezado-segmento.component.css']
})
export class GraficaEncabezadoSegmentoComponent implements OnInit {
  @ViewChild ('contenedorMpio', {static : true})  chartDivMpio: ElementRef;
  @ViewChild ('contenedorDF', {static : true})  chartDivDF: ElementRef;
  @ViewChild ('contenedorDL', {static : true})  chartDivDL: ElementRef;
  
  chartOptionsMpio;
  chartOptionsDF;
  chartOptionsDL;

  constructor(
    private _dashboardService:DashboardService,
  ) {  }

  ngOnInit() {
    this.getGraficaMunicipiosSegmento();
    this.getGraficaDistritosFSegmento();
    this.getGraficaDistritosLSegmento();
  }

  getGraficaMunicipiosSegmento(){
    let categories = [];
    let contactos = [];
    let sms = [];
    
    this._dashboardService.getGraficaMunicipiosSegmento()
    .subscribe( res=>{
        res.forEach(row => {
            categories.push(row.Municipio)
            contactos.push(parseInt(row.TotalContactosUnicos))
        });
        // console.log(categories);
        // console.log(contactos);

        this.chartOptionsMpio = {
            chart: {
              type: "column"
            },
            title: {
              text: "Municipios"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Contactos"
              } 
            },
            colors: [
              '#C0C0C0' 
              ],
            plotOptions: {
              series: {
                  colorByPoint: true
              }
            },
            credits: {
              enabled: false
            },
            series: [
              {
                name: 'Contactos',
                data: contactos,
                showInLegend: false
              }
            ],
          };
        
          Highcharts.chart(this.chartDivMpio.nativeElement, this.chartOptionsMpio);

      });
  }

  getGraficaDistritosFSegmento(){
    let categories = [];
    let contactos = [];
    let sms = [];
    
    this._dashboardService.getGraficaDistritosFSegmento()
    .subscribe( res=>{
        res.forEach(row => {
            categories.push(row.Distrito)
            contactos.push(parseInt(row.TotalContactosUnicos))
        });
        // console.log(categories);
        // console.log(contactos);

        this.chartOptionsDF = {
            chart: {
              type: "column"
            },
            title: {
              text: "Distritos Federales"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Contactos"
              } 
            },
            colors: [
              '#C7C2A2' 
              ],
            plotOptions: {
              series: {
                  colorByPoint: true
              }
            },
            credits: {
              enabled: false
            },
            series: [
              {
                name: 'Contactos',
                data: contactos,
                showInLegend: false
              }
            ],
          };

          Highcharts.chart(this.chartDivDF.nativeElement, this.chartOptionsDF);

      });
  }

  getGraficaDistritosLSegmento(){
    let categories = [];
    let contactos = [];
    let sms = [];
    
    this._dashboardService.getGraficaDistritosLSegmento()
    .subscribe( res=>{
        res.forEach(row => {
            categories.push(row.Distrito)
            contactos.push(parseInt(row.TotalContactosUnicos))
        });
        // console.log(categories);
        // console.log(contactos);

        this.chartOptionsDL = {
            chart: {
              type: "column"
            },
            title: {
              text: "Distritos Locales"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Contactos"
              } 
            },
            colors: [
              '#3E6083' 
              ],
            plotOptions: {
              series: {
                  colorByPoint: true
              }
            },
            credits: {
              enabled: false
            },
            series: [
              {
                name: 'Contactos',
                data: contactos,
                showInLegend: false
              }
            ],
          };

          Highcharts.chart(this.chartDivDL.nativeElement, this.chartOptionsDL);

      });
  }

}
