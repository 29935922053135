export default class Contacto{
    public IdContacto?:number;
    public IdPerfil?:number;
    public Nombre?:string;
    public ApellidoPaterno?:string;
    public ApellidoMaterno?:string;
    public Direccion?:string;
    public Telefono?:string;
    public Correo?:string;
    public Password?:string;
    public SeccionCalculada?:string;
    public IdProyecto?:number;
    public IdMunicipio?:number;
    public IdLocalidad?:number;
    public Colonia?:string;
    public CodigoPostal?:string;
    public IdOcupacion?:number;
    public Facebook?:string;
    public Twitter?:string;
    public Whatsapp?:number;
    public IdUnico?:number;
    public IdPadron?:number;
    public Lider?:number;
    public Lat?:string;
    public Lng?:string;
    public Referencia?:string;
    public Numero?:number;

    public Foto?:string;
}