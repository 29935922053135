import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-grafica-encabezado2',
  templateUrl: './grafica-encabezado2.component.html',
  styleUrls: ['./grafica-encabezado2.component.css']
})
export class GraficaEncabezado2Component implements OnInit {
  @ViewChild ('contenedorMpio', {static : true})  chartDivMpio: ElementRef;
  @ViewChild ('contenedorDF', {static : true})  chartDivDF: ElementRef;
  @ViewChild ('contenedorDL', {static : true})  chartDivDL: ElementRef;
  
  chartOptionsMpio:any;
  chartOptionsDF:any;
  chartOptionsDL:any;

  constructor(
    private _dashboardService:DashboardService,
  ) {  }

  ngOnInit() {
    this.getGraficaMunicipiosPadron();
    this.getGraficaDistritosFPadron();
    this.getGraficaDistritosLPadron();
  }

  getGraficaMunicipiosPadron(){
    let categories = [];
    let contactos = [];
    
    this._dashboardService.getGraficaMunicipiosPadron()
    .subscribe( res=>{
        res.forEach(row => {
            categories.push(row.Municipio)
            contactos.push(parseInt(row.TotalContactosUnicos))
        });

        this.chartOptionsMpio = {
            chart: {
              type: "column"
            },
            title: {
              text: "Municipios"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Contactos"
              } 
            },
            colors: [
              '#C0C0C0'
              ],
            plotOptions: {
              series: {
                  colorByPoint: true
              }
            },
            credits: {
              enabled: false
            },
            series: [
              {
                name: 'Contactos',
                data: contactos,
                showInLegend: false
              }
            ],
          };

      // this.chartOptionsMpio.xAxis["categories"] = categories;
      // this.chartOptionsMpio.series[0]["data"] = contactos;
      //console.log(this.chartOptionsMpio);

      Highcharts.chart(this.chartDivMpio.nativeElement, this.chartOptionsMpio)
      });
  }

  getGraficaDistritosFPadron(){
    let categories = [];
    let contactos = [];
    
    this._dashboardService.getGraficaDistritosFPadron()
    .subscribe( res=>{
        res.forEach(row => {
            categories.push(row.Distrito)
            contactos.push(parseInt(row.TotalContactosUnicos))
        });

        this.chartOptionsDF = {
            chart: {
              type: "column"
            },
            title: {
              text: "Distritos Federales"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Contactos"
              } 
            },
            colors: [
              '#C7C2A2'
              ],
            plotOptions: {
              series: {
                  colorByPoint: true
              }
            },
            credits: {
              enabled: false
            },
            series: [
              {
                name: 'Contactos',
                data: contactos,
                showInLegend: false
              }
            ],
          };

      Highcharts.chart(this.chartDivDF.nativeElement, this.chartOptionsDF)
      });
  }

  getGraficaDistritosLPadron(){
    let categories = [];
    let contactos = [];
    
    this._dashboardService.getGraficaDistritosLPadron()
    .subscribe( res=>{
        res.forEach(row => {
            categories.push(row.Distrito)
            contactos.push(parseInt(row.TotalContactosUnicos))
        });
        
        this.chartOptionsDL = {
            chart: {
              type: "column"
            },
            title: {
              text: "Distritos Locales"
            },
            xAxis:{
              categories: categories
            },
            yAxis: {          
              title:{
                text:"Contactos"
              } 
            },
            colors: [
              '#3E6083'
              ],
            plotOptions: {
              series: {
                  colorByPoint: true
              }
            },
            credits: {
              enabled: false
            },
            series: [
              {
                name: 'Contactos',
                data: contactos,
                showInLegend: false
              }
            ],
          };

      Highcharts.chart(this.chartDivDL.nativeElement, this.chartOptionsDL)
      });
  }

}
