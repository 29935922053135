import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UsuarioService } from '../service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

import CeoSegmento from 'src/app/models/ceosegmento.model';
import CeoPadron from 'src/app/models/ceopadron.model';

declare function cargando();
declare function cargado();

@Injectable({
    providedIn: 'root'
  })
export class CeoSegmentoPadronesService {

    constructor(
        private http:HttpClient,
        private router:Router,
        private usuarioService:UsuarioService
      ) { }

      private getHeaders(){
        return {
          headers: new HttpHeaders({'Authorization':this.usuarioService.token})
        };
      }

    //   
    getSegmentosPage( page:number ){
        let url = URL_SERVICIOS + '/segmentospadron/'+page;
        cargando();
        return this.http.get( url,this.getHeaders() )
        .pipe( 
          map((resp:any)=>{
            cargado();
            if( !resp.ok ){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error intentelo de nuevo',
                footer: ''
              })
            }
            return resp.page;
          }),
          catchError(ex=>{
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: ex.error.error,
              footer: ''
            })
            return ex;
          })
        );
    }

    getBannerPrincipal(Filtro: any ){
      let url = URL_SERVICIOS + '/BannerPrincipal';
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Oops...',
            //   text: 'Ocurrio un error intentelo de nuevo',
            //   footer: ''
            // })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getFiltros(Filtro: any ){
      let url = URL_SERVICIOS + '/Filtro';
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getSegmentosPageFiltros( page:number,Filtro: any  ){
      let url = URL_SERVICIOS + '/PadronFiltro/'+page;
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.page.data;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
  }
    getGraficaGenero(Filtro: any ){
      let url = URL_SERVICIOS + '/GraficaGenero';
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getGraficaMes(Filtro: any ){
      let url = URL_SERVICIOS + '/GraficaMes';
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getMunicipiosAdministrativo(){
      let url = URL_SERVICIOS + '/MunicipiosAdministrativo';
      cargando();
      return this.http.get( url,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    getLocalidadAdministrativo(Municipio: any){
      let url = URL_SERVICIOS + '/LocalidadAdministrativo';
      cargando();
      return this.http.post( url,Municipio,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    getGraficaMunicipiosAdministrativo(Filtro: any){
      let url = URL_SERVICIOS + '/GraficaMunicipiosAdministrativo';
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getMunicipiosPolitico(){
      let url = URL_SERVICIOS + '/MunicipiosPolitico';
      cargando();
      return this.http.get( url,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    getGraficaMunicipiosPolitico(Filtro: any){
      let url = URL_SERVICIOS + '/GraficaMunicipiosPolitico';
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            cargado();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getDistritoLocalPolitico(Municipio: any){
      let url = URL_SERVICIOS + '/DistritoLocalPolitico';
      cargando();
      return this.http.post( url,Municipio,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    getDistritoFederalPolitico(Municipio: any){
      let url = URL_SERVICIOS + '/DistritoFederalPolitico';
      cargando();
      return this.http.post( url,Municipio,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    getSeccionesPolitico(Municipio: any){
      let url = URL_SERVICIOS + '/SeccionesPolitico';
      cargando();
      return this.http.post( url,Municipio,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    getGraficaDistritoLocal(Filtro: any){
      let url = URL_SERVICIOS + '/GraficaDistritoLocal';
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getGraficaDistritoFederal(Filtro: any){
      let url = URL_SERVICIOS + '/GraficaDistritoFederal';
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getGraficaSecciones(Filtro: any){
      let url = URL_SERVICIOS + '/GraficaSecciones';
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getGraficaLinealEdades(Filtro: any){
      let url = URL_SERVICIOS + '/GraficaEdades';
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Oops...',
          //   text: ex.error.error,
          //   footer: ''
          // })
          return ex;
        })
      );
    }
    getMeses(Filtro: any){
      let url = URL_SERVICIOS + '/CeoMeses/'+Filtro;
      return this.http.get( url,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    getOcupacion(Filtro: any){
      let url = URL_SERVICIOS + '/CeoOcupacion/'+Filtro;
      return this.http.get( url,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    postGuardarMensaje(mensaje: any ){
      let url = URL_SERVICIOS + '/GuardarMensaje';
      cargando();
      return this.http.post( url,mensaje,this.getHeaders() )
      .pipe(      
        map((resp:any)=>{
          cargado();
          return resp;
        }),  
        catchError(ex=>{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hubo un problema al guardar el mensaje, intente de nuevo',
            footer: ''
          })
          return ex;
        })
      );
    }
    postPrevioCorreo( correo: any ){
      let url = URL_SERVICIOS + '/PrevioCorreo/';
      cargando();
      return this.http.post( url,correo,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          // if( !resp.ok ){
          //   Swal.fire({
          //     icon: 'error',
          //     title: 'Oops...',
          //     text: 'Ocurrio un error intentelo de nuevo',
          //     footer: ''
          //   })
          // }
          return resp;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text:'Correo invalido',
            footer: ''
          })
          return ex;
        })
      );
    }
    postSMS( numero: any ){
      let url = URL_SERVICIOS + '/PrevioSMS/';
      cargando();
      return this.http.post( url,numero,this.getHeaders() )
      .pipe(     
        map((resp:any)=>{
          cargado();
          // if( !resp.ok ){
          //   Swal.fire({
          //     icon: 'error',
          //     title: 'Oops...',
          //     text: 'Ocurrio un error intentelo de nuevo',
          //     footer: ''
          //   })
          // }
          return resp;
        }),   
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hubo un problema al enviar el mensaje, intente de nuevo',
            footer: ''
          })
          return ex;
        })
        
      );
    }
    getDatosEnvio(Filtro: any ){
      let url = URL_SERVICIOS + '/DatosMensaje';
      cargando();
      return this.http.post( url,Filtro,this.getHeaders() )
      .pipe( 
        map((resp:any)=>{
          cargado();
          if( !resp.ok ){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error intentelo de nuevo',
              footer: ''
            })
          }
          return resp.data;
        }),
        catchError(ex=>{
          cargado();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: ex.error.error,
            footer: ''
          })
          return ex;
        })
      );
    }
    Descargar(Filtro: any ){
      let url = URL_SERVICIOS + '/ExportacionSegmentos/'+0;
      return this.http.post( url,Filtro,{headers: new HttpHeaders({'Authorization':this.usuarioService.token}), responseType: 'blob' })
      .pipe( 
        map((resp:any)=>{
          return resp;
        }),
      );
    }
}