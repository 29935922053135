import { Component, OnInit, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/services/service/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { UsuarioService } from 'src/app/services/service.index';
import { URL_SERVICIOS } from 'src/app/config/config';
import { ThrowStmt } from '@angular/compiler';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-dispersion-eys-padron',
  templateUrl: './dispersion-eys-padron.component.html',
  styleUrls: ['./dispersion-eys-padron.component.css']
})
export class DispersionEysPadronComponent implements OnInit {
  @Output() public filtro_ = new EventEmitter<any>();

  @ViewChild ('contenedorEdadesDFPerfiles', {static : true})  chartDivEdadesDFPerfiles: ElementRef;
  @ViewChild ('contenedorOnomasticosDFPerfiles', {static : true})  chartDivOnomasticosDFPerfiles: ElementRef;
  @ViewChild ('contenedorOcupacionesDFPerfiles', {static : true})  chartDivOcupacionesDFPerfiles: ElementRef;
  @ViewChild ('contenedorOrigenDFPerfiles', {static : true})  chartDivOrigenDFPerfiles: ElementRef;

  @ViewChild ('contenedorEdadesDLPerfiles', {static : true})  chartDivEdadesDLPerfiles: ElementRef;
  @ViewChild ('contenedorOnomasticosDLPerfiles', {static : true})  chartDivOnomasticosDLPerfiles: ElementRef;
  @ViewChild ('contenedorOcupacionesDLPerfiles', {static : true})  chartDivOcupacionesDLPerfiles: ElementRef;
  @ViewChild ('contenedorOrigenDLPerfiles', {static : true})  chartDivOrigenDLPerfiles: ElementRef;

  @ViewChild ('contenedorEdadesMunPerfiles', {static : true})  chartDivEdadesMunPerfiles: ElementRef;
  @ViewChild ('contenedorOnomasticosMunPerfiles', {static : true})  chartDivOnomasticosMunPerfiles: ElementRef;
  @ViewChild ('contenedorOcupacionesMunPerfiles', {static : true})  chartDivOcupacionesMunPerfiles: ElementRef;
  @ViewChild ('contenedorOrigenMunPerfiles', {static : true})  chartDivOrigenMunPerfiles: ElementRef;

  DistritoFederal_: any;
  DistritoLocal_: any;
  Municipio_: any;
  Seccion_: any;
  
  dataCDF: any[];
  dataCD: any[];
  dataCM: any[];
  dataCS: any[];
  dashboard = [];
  dashboardCDF = [];
  dashboardCD = [];
  dashboardCM = [];
  dashboardCS = [];

  IdPadron: any;
  selDistritoF: any;
  selDistrito: any;
  selMunicipio: any;
  selSeccion: any;
  
  graficaContactosDistritoF = Highcharts;
  graficaContactosDistrito = Highcharts; 
  graficaContactosMunicipio = Highcharts;
  graficaContactosSeccion = Highcharts;
  
  chartOptionsCDF;
  chartOptionsCD;
  chartOptionsCM;
  chartOptionsCS;
  
  padrones: any;
  distritosF: any;
  distritos: any;
  municipios: any;
  secciones: any;

  totalesDistritoF = {
    TotalSecciones: 0,
    TotalContactos: 0,
    TotalContactosUnicos: 0
  };

  totalesDistrito = {
    TotalSecciones: 0,
    TotalContactos: 0,
    TotalContactosUnicos: 0
  };

  totalesMunicipio = {
    TotalSecciones: 0,
    TotalContactos: 0,
    TotalContactosUnicos: 0
  };

  totalesSeccion = {
    TotalContactos: 0,
    TotalContactosUnicos: 0
  };

  IdContacto: number;
  totalesDF: any = {};
  totalesDL: any = {};
  totalesMun: any = {};

  chartOptions_generoedad_distrito_federal_perfiles;
  chartOptions_onomastico_distrito_federal_perfiles;
  chartOptions_ocupacion_distrito_federal_perfiles;
  chartOptions_origen_distrito_federal_perfiles;

  chartOptions_generoedad_distrito_local_perfiles;
  chartOptions_onomastico_distrito_local_perfiles;
  chartOptions_ocupacion_distrito_local_perfiles;
  chartOptions_origen_distrito_local_perfiles;
  
  chartOptions_generoedad_mun_perfiles;
  chartOptions_onomastico_mun_perfiles;
  chartOptions_ocupacion_mun_perfiles;
  chartOptions_origen_mun_perfiles;
  nombreMunicipio: string;

  constructor(
    private _dashboardService:DashboardService,
    private activatedRoute: ActivatedRoute,
    public _serviceUsuario:UsuarioService
  ) { }

  ngOnInit() {
    this.getPadrones()
    this.getDistritosF()
    this.getDistritos()
    this.getMunicipios()
    this.getSecciones()
    this.getDispersionElectoral(0)
    $("#sidebar-distrito-federal-perfiles").slideDown(50), $("#sidebar-distrito-federal-perfiles").toggleClass("shw-rside");
    $("#sidebar-distrito-local-perfiles").slideDown(50), $("#sidebar-distrito-local-perfiles").toggleClass("shw-rside");
    $("#sidebar-mun-perfiles").slideDown(50), $("#sidebar-mun-perfiles").toggleClass("shw-rside");
  }

  ngAfterViewInit(){
    this.componentesJQuery();
  }

  componentesJQuery(){
    $('.selectpicker_Padrones').selectpicker();
    $('.selectpicker_DistritosF').selectpicker();
    $('.selectpicker_Distritos').selectpicker();
    $('.selectpicker_Municipios').selectpicker();
    $('.selectpicker_Secciones').selectpicker();
  }

  getPadrones()
  {
    this.activatedRoute.paramMap.subscribe(params => {
    this._dashboardService.getPadrones()
      .subscribe( res=>{
        //console.log(res);
        this.padrones = res;

        setTimeout(()=>{ $('.selectpicker_Padrones').selectpicker('refresh'); }, 1000) 
      })
    });
  }

  getDistritosF()
  {
    this.activatedRoute.paramMap.subscribe(params => {
    this._dashboardService.getDistritosFPadron()
      .subscribe( res=>{
        //console.log(res);
        this.distritosF = res;

        setTimeout(()=>{ $('.selectpicker_DistritosF').selectpicker('refresh'); }, 1000) 
      })
    });
  }

  getDistritos()
  {
    this.activatedRoute.paramMap.subscribe(params => {
    this._dashboardService.getDistritosPadron()
      .subscribe( res=>{
        //console.log(res);
        this.distritos = res;

        setTimeout(()=>{ $('.selectpicker_Distritos').selectpicker('refresh'); }, 1000) 
      })
    });
  }

  getMunicipios()
  {
    this.activatedRoute.paramMap.subscribe(params => {
    this._dashboardService.getMunicipiosPadron()
      .subscribe( res=>{
        //console.log(res);
        this.municipios = res;

        setTimeout(()=>{ $('.selectpicker_Municipios').selectpicker('refresh'); }, 1000) 
      })
    });
  }

  getSecciones()
  {
    this.activatedRoute.paramMap.subscribe(params => {
    this._dashboardService.getSeccionesPadron()
      .subscribe( res=>{
        //console.log(res);
        this.secciones = res;

        setTimeout(()=>{ $('.selectpicker_Secciones').selectpicker('refresh'); }, 1000) 
      })
    });
  }

  getDispersionElectoral(idPadron: number)
  {
    this.activatedRoute.paramMap.subscribe(params => {
    this._dashboardService.getDispersionElectoralPadrones(idPadron)
      .subscribe( res=>{
        //console.log(res);
        this.dashboard = res;

        //setTimeout(()=>{ this.getDispersionElectoral(idSegmento); }, 1000) 
      });
    });
  }

  getTotal(par: string) {
    return this.dashboard.map(t => t[par]).reduce((acc, value) => acc + value, 0);
  }

  getTotalPadronesCDF(par: string) {
    return this.dashboardCDF.map(t => t[par]).reduce((acc, value) => acc + value, 0);
  }

  getTotalPadronesCD(par: string) {
    return this.dashboardCD.map(t => t[par]).reduce((acc, value) => acc + value, 0);
  }

  getTotalPadronesCM(par: string) {
    return this.dashboardCM.map(t => t[par]).reduce((acc, value) => acc + value, 0);
  }

  getTotalPadronesCS(par: string) {
    return this.dashboardCS.map(t => t[par]).reduce((acc, value) => acc + value, 0);
  }

  getEstadisticasDistritoF(idDistritoF: number){
    this.getTotalesDistritoF(idDistritoF);
    this.getTablaContactosDistritoF(idDistritoF, 0);

    this.DistritoFederal_ = idDistritoF;
    this.filtro_.emit(idDistritoF);
  }

  getEstadisticasDistrito(idDistrito: number){
    this.getTotalesDistrito(idDistrito);
    this.getTablaContactosDistrito(idDistrito, 0);

    this.DistritoLocal_ = idDistrito;
    this.filtro_.emit(idDistrito);
  }

  getEstadisticasMunicipio(idMunicipio: number, nombreMunicipio: string){
    this.getTotalesMunicipio(idMunicipio);
    this.getTablaContactosMunicipio(idMunicipio, 0);

    this.Municipio_ = idMunicipio
    this.filtro_.emit(idMunicipio);

    this.nombreMunicipio = nombreMunicipio;
  }

  getEstadisticasSeccion(idSeccion: number){
    this.getTotalesSeccion(idSeccion);
    this.getTablaContactosSeccion(idSeccion, 0);

    this.Seccion_ = idSeccion
    this.filtro_.emit(idSeccion);
  }

  getTotalesDistritoF(idDistritoF: number){
    this._dashboardService.getTotalesDistritoFPadron(idDistritoF)
      .subscribe( res=>{
        //console.log(res);
        this.totalesDistritoF = res;
      });
  }

  getTotalesDistrito(idDistrito: number){
    this._dashboardService.getTotalesDistritoPadron(idDistrito)
      .subscribe( res=>{
        //console.log(res);
        this.totalesDistrito = res;
      });
  }

  getTotalesMunicipio(idDistrito: number){
    this._dashboardService.getTotalesMunicipioPadron(idDistrito)
      .subscribe( res=>{
        //console.log(res);
        this.totalesMunicipio = res;
      });
  }

  getTotalesSeccion(idSeccion: number){
    this._dashboardService.getTotalesSeccionPadron(idSeccion)
      .subscribe( res=>{
        //console.log(res);
        this.totalesSeccion = res;
      });
  }

  onSeleccionDistritoFP(idDistritoF: number, page: number){
    this.getTablaContactosDistritoF(idDistritoF, page);
  }

  onSeleccionDistritoP(idDistrito: number, page: number){
    this.getTablaContactosDistrito(idDistrito, page);
  }

  onSeleccionMunicipioP(idMunicipio: number, page: number){
    this.getTablaContactosMunicipio(idMunicipio, page);
  }

  onSeleccionSeccionP(idSeccion: number, page: number){
    this.getTablaContactosSeccion(idSeccion, page);
  }

  getTablaContactosDistritoF(idDistritoF: number, page: number){
    this._dashboardService.getContactosDistritoFPadron(idDistritoF, (page || 0)).subscribe(res=>
      {
        this.dataCDF = res;
        this.dashboardCDF = res.data;
      });
  }

  getTablaContactosDistrito(idDistrito: number, page: number){
    this._dashboardService.getContactosDistritoPadron(idDistrito, (page || 0)).subscribe(res=>
      {
        this.dataCD = res;
        this.dashboardCD = res.data;
      });
  }

  // getGraficaContactosDistrito(idDistrito: number){
  //   let categories = [];
  //   let contactos = [];
  //   let x_values = {};
    
  //   this._dashboardService.getContactosDistritoPadron(idDistrito, 0).subscribe(res=>
  //     {
  //       this.dashboardCD = res;
        
  //       res.forEach(row => {
  //         categories.push(row.Padron)
  //         x_values['name'] = row.Padron;
  //         x_values['y'] = row.TotalContactos;
  //         contactos.push(x_values);
  //         x_values = {}
  //       })

  //       this.chartOptionsCD = {
  //           chart: {
  //             type: "pie",
  //           },
  //           colors: ['#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
  //           title: {
  //             text: "Total Contactos"
  //           },
  //           xAxis:{
  //             categories: categories
  //           },
  //           yAxis: {          
  //             title:{
  //               text:"Totales"
  //             } 
  //           },
  //           series: [
  //             {
  //               name: 'Total Contactos',
  //               colorByPoint: true,
  //               data: contactos
  //             }
  //           ]
  //         };
  //     });
  // }

  getTablaContactosMunicipio(idMunicipio: number, page: number){
    this._dashboardService.getContactosMunicipioPadron(idMunicipio, (page || 0)).subscribe(res=>
      {
        this.dataCM = res;
        this.dashboardCM = res.data;
      });
  }

  // getGraficaContactosMunicipio(idMunicipio: number){
  //   let categories = [];
  //   let contactos = [];
  //   let x_values = {};
    
  //   this._dashboardService.getContactosMunicipioPadron(idMunicipio).subscribe(res=>
  //     {
  //       this.dashboardCM = res;
        
  //       res.forEach(row => {
  //         categories.push(row.Padron)
  //         x_values['name'] = row.Padron;
  //         x_values['y'] = row.TotalContactos;
  //         contactos.push(x_values);
  //         x_values = {}
  //       })

  //       this.chartOptionsCM = {
  //           chart: {
  //             type: "pie"
  //           },
  //           colors: ['#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
  //           title: {
  //             text: "Total Contactos"
  //           },
  //           xAxis:{
  //             categories: categories
  //           },
  //           yAxis: {          
  //             title:{
  //               text:"Totales"
  //             } 
  //           },
  //           series: [
  //             {
  //               name: 'Total Contactos',
  //               colorByPoint: true,
  //               data: contactos
  //             }
  //           ]
  //         };
  //     });
  // }

  getTablaContactosSeccion(idSeccion: number, page: number){
    this._dashboardService.getContactosSeccionPadron(idSeccion, (page || 0)).subscribe(res=>
      {
        this.dataCS = res;
        this.dashboardCS = res.data;
      });
  }

  // getGraficaContactosSeccion(idSeccion: number){
  //   let categories = [];
  //   let contactos = [];
  //   let x_values = {};
    
  //   this._dashboardService.getContactosSeccionPadron(idSeccion).subscribe(res=>
  //     {
  //       this.dashboardCS = res;
  //       console.log(this.dashboardCS);

  //       res.forEach(row => {
  //         categories.push(row.Padron)
  //         x_values['name'] = row.Padron;
  //         x_values['y'] = row.TotalContactos;
  //         contactos.push(x_values);
  //         x_values = {}
  //       })

  //       this.chartOptionsCS = {
  //           chart: {
  //             type: "pie"
  //           },
  //           colors: ['#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
  //           title: {
  //             text: "Total Contactos"
  //           },
  //           xAxis:{
  //             categories: categories
  //           },
  //           yAxis: {          
  //             title:{
  //               text:"Totales"
  //             } 
  //           },
  //           series: [
  //             {
  //               name: 'Total Contactos',
  //               colorByPoint: true,
  //               data: contactos
  //             }
  //           ]
  //         };
  //     });
  // }

  exportarDispersion(IdPadron: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    IdPadron = IdPadron || 0;
    
    window.open(URL_SERVICIOS+'/exportacion-dispersion-padron/' + this.IdContacto + '/' + IdPadron); 
  }

  exportarDispersionDetalle(IdPadron: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    IdPadron = IdPadron || 0;

    window.open(URL_SERVICIOS+'/exportacion-dispersion-padron-detalle/' + this.IdContacto + '/' + IdPadron);   
  }

  exportarContactosDistritoF(IdDistritoF: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-distritof-padron/' + this.IdContacto + '/' + IdDistritoF);
  }

  exportarContactosDistritoFDtl(IdDistritoF: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-distritof-padron-detalle/' + this.IdContacto + '/' + IdDistritoF);
  }
  
  exportarContactosDistrito(IdDistrito: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-distrito-padron/' + this.IdContacto + '/' + IdDistrito);
  }

  exportarContactosDistritoDtl(IdDistrito: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-distrito-padron-detalle/' + this.IdContacto + '/' + IdDistrito);
  }

  exportarContactosMunicipio(IdMunicipio: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-municipio-padron/' + this.IdContacto + '/' + IdMunicipio);
  }

  exportarContactosMunicipioDtl(IdMunicipio: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-municipio-padron-detalle/' + this.IdContacto + '/' + IdMunicipio);
  }

  exportarContactosSeccion(IdSeccion: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-seccion-padron/' + this.IdContacto + '/' + IdSeccion);
  }

  exportarContactosSeccionDtl(IdSeccion: number){
    this.IdContacto = this._serviceUsuario.getSession().IdContacto;
    
    window.open(URL_SERVICIOS+'/exportacion-contactos-seccion-padron-detalle/' + this.IdContacto + '/' + IdSeccion);
  }

  perfilesDistritoF(IdDistrito: number){
      this.getInfoDF(IdDistrito);
      this.getGraficaEdadesDF(IdDistrito);
      this.getGraficaOnomasticosDF(IdDistrito);
      this.getGraficaOcupacionesDF(IdDistrito);
      this.getGraficaOrigenDF(IdDistrito);

      $("body").addClass("modal-open");
      var modal = document.getElementById("modal-distrito-federal-perfiles");
      modal.style.display = "block";

      $("#sidebar-distrito-federal-perfiles").slideDown(50), $("#sidebar-distrito-federal-perfiles").toggleClass("shw-rside");
  }

  getInfoDF(IdDistrito: number)
  {
    this._dashboardService.getTotalesGeneroDFPadron(IdDistrito)
    .subscribe( res=>{
      this.totalesDF = res;
     
      let t = res.TotalUnicos;
      let p = 0;

      p = (res.Hombres / t) * 100;
      $('.GHDF').val(p+"%").trigger('change');
    
      p = (res.Mujeres / t) * 100;
      $('.GMDF').val(p+"%").trigger('change');
    });
  }

  getGraficaEdadesDF(IdDistrito: number){
    let categories = ['0-04','05-13','14-17','18-30','31-50','51-70','71-90','91->'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaGenerosDFPadron(IdDistrito)
    .subscribe( res=>{
        totalesH = [parseInt(res.HEDAD04) * -1, parseInt(res.HEDAD513) * -1, parseInt(res.HEDAD1417) * -1, parseInt(res.HEDAD1830) * -1, parseInt(res.HEDAD3150) * -1, parseInt(res.HEDAD5170) * -1, parseInt(res.HEDAD7190) * -1, parseInt(res.HEDAD91) * -1]
        totalesM = [parseInt(res.MEDAD04), parseInt(res.MEDAD513), parseInt(res.MEDAD1417), parseInt(res.MEDAD1830), parseInt(res.MEDAD3150), parseInt(res.MEDAD5170), parseInt(res.MEDAD7190), parseInt(res.MEDAD91)]

        this.chartOptions_generoedad_distrito_federal_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Género y Edad"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', edad de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivEdadesDFPerfiles.nativeElement, this.chartOptions_generoedad_distrito_federal_perfiles);

    });
  }

  getGraficaOnomasticosDF(IdDistrito: number){
    let categories = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaOnomasticoDFPadron(IdDistrito)
    .subscribe( res=>{
        totalesH = [parseInt(res.HENE) * -1, parseInt(res.HFEB) * -1, parseInt(res.HMAR) * -1, parseInt(res.HABR) * -1, parseInt(res.HMAY) * -1, parseInt(res.HJUN) * -1, parseInt(res.HJUL) * -1, parseInt(res.HAGO) * -1, parseInt(res.HSEP) * -1, parseInt(res.HOCT) * -1, parseInt(res.HNOV) * -1, parseInt(res.HDIC) * -1]
        totalesM = [parseInt(res.MENE), parseInt(res.MFEB), parseInt(res.MMAR), parseInt(res.MABR), parseInt(res.MMAY), parseInt(res.MJUN), parseInt(res.MJUL), parseInt(res.MAGO), parseInt(res.MSEP), parseInt(res.MOCT), parseInt(res.MNOV), parseInt(res.MDIC)]

        this.chartOptions_onomastico_distrito_federal_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Onomástico"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', mes de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivOnomasticosDFPerfiles.nativeElement, this.chartOptions_onomastico_distrito_federal_perfiles);

    });
  }

  getGraficaOcupacionesDF(IdDistrito: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOcupacionDFPadron(IdDistrito)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Ocupacion)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_ocupacion_distrito_federal_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Ocupación"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOcupacionesDFPerfiles.nativeElement, this.chartOptions_ocupacion_distrito_federal_perfiles);
    });
  }

  getGraficaOrigenDF(IdDistrito: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOrigenDFPadron(IdDistrito)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Estado)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_origen_distrito_federal_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Lugar de Origen"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOrigenDFPerfiles.nativeElement, this.chartOptions_origen_distrito_federal_perfiles);
    });
  }

  perfilesDistritoL(IdDistrito: number){
    this.getInfoDL(IdDistrito);
    this.getGraficaEdadesDL(IdDistrito);
    this.getGraficaOnomasticosDL(IdDistrito);
    this.getGraficaOcupacionesDL(IdDistrito);
    this.getGraficaOrigenDL(IdDistrito);

    $("body").addClass("modal-open");
    var modal = document.getElementById("modal-distrito-local-perfiles");
    modal.style.display = "block";

    $("#sidebar-distrito-local-perfiles").slideDown(50), $("#sidebar-distrito-local-perfiles").toggleClass("shw-rside");
  }

  getInfoDL(IdDistrito: number)
  {
    this._dashboardService.getTotalesGeneroDLPadron(IdDistrito)
    .subscribe( res=>{
      this.totalesDL = res;
     
      let t = res.TotalUnicos;
      let p = 0;

      p = (res.Hombres / t) * 100;
      $('.GHDL').val(p+"%").trigger('change');
    
      p = (res.Mujeres / t) * 100;
      $('.GMDL').val(p+"%").trigger('change');
    });
  }

  getGraficaEdadesDL(IdDistrito: number){
    let categories = ['0-04','05-13','14-17','18-30','31-50','51-70','71-90','91->'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaGenerosDLPadron(IdDistrito)
    .subscribe( res=>{
        totalesH = [parseInt(res.HEDAD04) * -1, parseInt(res.HEDAD513) * -1, parseInt(res.HEDAD1417) * -1, parseInt(res.HEDAD1830) * -1, parseInt(res.HEDAD3150) * -1, parseInt(res.HEDAD5170) * -1, parseInt(res.HEDAD7190) * -1, parseInt(res.HEDAD91) * -1]
        totalesM = [parseInt(res.MEDAD04), parseInt(res.MEDAD513), parseInt(res.MEDAD1417), parseInt(res.MEDAD1830), parseInt(res.MEDAD3150), parseInt(res.MEDAD5170), parseInt(res.MEDAD7190), parseInt(res.MEDAD91)]

        this.chartOptions_generoedad_distrito_local_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Género y Edad"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', edad de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivEdadesDLPerfiles.nativeElement, this.chartOptions_generoedad_distrito_local_perfiles);

    });
  }

  getGraficaOnomasticosDL(IdDistrito: number){
    let categories = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaOnomasticoDLPadron(IdDistrito)
    .subscribe( res=>{
        totalesH = [parseInt(res.HENE) * -1, parseInt(res.HFEB) * -1, parseInt(res.HMAR) * -1, parseInt(res.HABR) * -1, parseInt(res.HMAY) * -1, parseInt(res.HJUN) * -1, parseInt(res.HJUL) * -1, parseInt(res.HAGO) * -1, parseInt(res.HSEP) * -1, parseInt(res.HOCT) * -1, parseInt(res.HNOV) * -1, parseInt(res.HDIC) * -1]
        totalesM = [parseInt(res.MENE), parseInt(res.MFEB), parseInt(res.MMAR), parseInt(res.MABR), parseInt(res.MMAY), parseInt(res.MJUN), parseInt(res.MJUL), parseInt(res.MAGO), parseInt(res.MSEP), parseInt(res.MOCT), parseInt(res.MNOV), parseInt(res.MDIC)]

        this.chartOptions_onomastico_distrito_local_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Onomástico"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', mes de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivOnomasticosDLPerfiles.nativeElement, this.chartOptions_onomastico_distrito_local_perfiles);
    });
  }

  getGraficaOcupacionesDL(IdDistrito: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOcupacionDLPadron(IdDistrito)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Ocupacion)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_ocupacion_distrito_local_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Ocupación"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOcupacionesDLPerfiles.nativeElement, this.chartOptions_ocupacion_distrito_local_perfiles);
    });
  }

  getGraficaOrigenDL(IdDistrito: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOrigenDLPadron(IdDistrito)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Estado)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_origen_distrito_local_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Lugar de Origen"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOrigenDLPerfiles.nativeElement, this.chartOptions_origen_distrito_local_perfiles);
    });
  }

  perfilesMun(IdMunicipio: number){
    this.getInfoMun(IdMunicipio);
    this.getGraficaEdadesMun(IdMunicipio);
    this.getGraficaOnomasticosMun(IdMunicipio);
    this.getGraficaOcupacionesMun(IdMunicipio);
    this.getGraficaOrigenMun(IdMunicipio);

    $("body").addClass("modal-open");
    var modal = document.getElementById("modal-mun-perfiles");
    modal.style.display = "block";

    $("#sidebar-mun-perfiles").slideDown(50), $("#sidebar-mun-perfiles").toggleClass("shw-rside");
  }

  getInfoMun(IdMunicipio: number)
  {
    this._dashboardService.getTotalesGeneroMunPadron(IdMunicipio)
    .subscribe( res=>{
      this.totalesMun = res;
     
      let t = res.TotalUnicos;
      let p = 0;

      p = (res.Hombres / t) * 100;
      $('.GHMUN').val(p+"%").trigger('change');
    
      p = (res.Mujeres / t) * 100;
      $('.GMMUN').val(p+"%").trigger('change');
    });
  }

  getGraficaEdadesMun(IdMunicipio: number){
    let categories = ['0-04','05-13','14-17','18-30','31-50','51-70','71-90','91->'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaGenerosMunPadron(IdMunicipio)
    .subscribe( res=>{
        totalesH = [parseInt(res.HEDAD04) * -1, parseInt(res.HEDAD513) * -1, parseInt(res.HEDAD1417) * -1, parseInt(res.HEDAD1830) * -1, parseInt(res.HEDAD3150) * -1, parseInt(res.HEDAD5170) * -1, parseInt(res.HEDAD7190) * -1, parseInt(res.HEDAD91) * -1]
        totalesM = [parseInt(res.MEDAD04), parseInt(res.MEDAD513), parseInt(res.MEDAD1417), parseInt(res.MEDAD1830), parseInt(res.MEDAD3150), parseInt(res.MEDAD5170), parseInt(res.MEDAD7190), parseInt(res.MEDAD91)]

        this.chartOptions_generoedad_mun_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Género y Edad"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', edad de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivEdadesMunPerfiles.nativeElement, this.chartOptions_generoedad_mun_perfiles);

    });
  }

  getGraficaOnomasticosMun(IdMunicipio: number){
    let categories = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    let totalesH = [];
    let totalesM = [];
    
    this._dashboardService.getTotalesGraficaOnomasticoMunPadron(IdMunicipio)
    .subscribe( res=>{
        totalesH = [parseInt(res.HENE) * -1, parseInt(res.HFEB) * -1, parseInt(res.HMAR) * -1, parseInt(res.HABR) * -1, parseInt(res.HMAY) * -1, parseInt(res.HJUN) * -1, parseInt(res.HJUL) * -1, parseInt(res.HAGO) * -1, parseInt(res.HSEP) * -1, parseInt(res.HOCT) * -1, parseInt(res.HNOV) * -1, parseInt(res.HDIC) * -1]
        totalesM = [parseInt(res.MENE), parseInt(res.MFEB), parseInt(res.MMAR), parseInt(res.MABR), parseInt(res.MMAY), parseInt(res.MJUN), parseInt(res.MJUL), parseInt(res.MAGO), parseInt(res.MSEP), parseInt(res.MOCT), parseInt(res.MNOV), parseInt(res.MDIC)]

        this.chartOptions_onomastico_mun_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Onomástico"
          },
          xAxis: [{
                    categories: categories,
                    reversed: false,
                    labels: {
                      step: 1
                    }
                 },
                 {
                    opposite: true,
                    reversed: false,
                    categories: categories,
                    linkedTo: 0,
                    labels: {
                      step: 1
                    }
                 }
        ],
          yAxis: {          
            title:{
              text: null
            },
            labels: {
                formatter: function() {
                    return Math.abs(this.value);
                }
            }
          },
          tooltip: {
              formatter: function () {
                  return '<b>' + this.series.name + ', mes de ' + this.point.category + '<b><br/>' + 'Contactos: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
              }
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          credits: {
            enabled: false
          },
          colors: [
            '#33ADFF',
            '#FF335C',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
            ],
          series: [
            {
              name: 'Hombres',
              data: totalesH
            },
            {
              name: 'Mujeres',
              data: totalesM
            }
          ]
        };

        Highcharts.chart(this.chartDivOnomasticosMunPerfiles.nativeElement, this.chartOptions_onomastico_mun_perfiles);
    });
  }

  getGraficaOcupacionesMun(IdMunicipio: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOcupacionMunPadron(IdMunicipio)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Ocupacion)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_ocupacion_mun_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Ocupación"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOcupacionesMunPerfiles.nativeElement, this.chartOptions_ocupacion_mun_perfiles);
    });
  }

  getGraficaOrigenMun(IdMunicipio: number) {
    let categories = [];
    let contactos = [];

    this._dashboardService.getTotalesGraficaOrigenMunPadron(IdMunicipio)
      .subscribe(res => {

        res.forEach(row => {
          categories.push(row.Estado)
          contactos.push(parseInt(row.Total))
        });

        this.chartOptions_origen_mun_perfiles = {
          chart: {
            type: "bar"
          },
          title: {
            text: "Distribución por Lugar de Origen"
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: {
              text: "Contactos"
            }
          },
          colors: [
            '#FF7D00',
            '#33ADFF',
            '#33CC33',
            '#F03C3C',
            '#9933FF',
            '#FFFF00',
            '#2BD68F',
            '#2200CC'
          ],
          plotOptions: {
            series: {
              colorByPoint: true
            }
          },
          credits: {
            enabled: false
          },
          series: [
            {
              name: 'Contactos',
              data: contactos,
              showInLegend: false
            }
          ],
        };

        Highcharts.chart(this.chartDivOrigenMunPerfiles.nativeElement, this.chartOptions_origen_mun_perfiles);
    });
  }

  cerrarDF()
  {
    $("#tabOcupacionesDFPerfiles").removeClass("active")
    $("#tabOnomasticosDFPerfiles").removeClass("active")
    $("#tabOrigenDFPerfiles").removeClass("active")
    $("#tabEdadesDFPerfiles").addClass("active")

    $("#ocupaciones_distrito_federal_perfiles").removeClass("active")
    $("#onomasticos_distrito_federal_perfiles").removeClass("active")
    $("#origen_distrito_federal_perfiles").removeClass("active")
    $("#edades_distrito_federal_perfiles").addClass("active")
    
    $("body").removeClass("modal-open")
    
    $("#sidebar-distrito-federal-perfiles").delay(300).slideDown(50);
    
    $("#sidebar-distrito-federal-perfiles").toggleClass("shw-rside").promise().done(function(){
      var modal = document.getElementById("modal-distrito-federal-perfiles");
      modal.style.display = "none";
    });
  }

  cerrarDL()
  {
    $("#tabOcupacionesDLPerfiles").removeClass("active")
    $("#tabOnomasticosDLPerfiles").removeClass("active")
    $("#tabOrigenDLPerfiles").removeClass("active")
    $("#tabEdadesDLPerfiles").addClass("active")

    $("#ocupaciones_distrito_local_perfiles").removeClass("active")
    $("#onomasticos_distrito_local_perfiles").removeClass("active")
    $("#origen_distrito_local_perfiles").removeClass("active")
    $("#edades_distrito_local_perfiles").addClass("active")
    
    $("body").removeClass("modal-open")
    
    $("#sidebar-distrito-local-perfiles").delay(300).slideDown(50);
    
    $("#sidebar-distrito-local-perfiles").toggleClass("shw-rside").promise().done(function(){
      var modal = document.getElementById("modal-distrito-local-perfiles");
      modal.style.display = "none";
    });
  }

  cerrarMun()
  {
    $("#tabOcupacioneMunPerfiles").removeClass("active")
    $("#tabOnomasticosMunPerfiles").removeClass("active")
    $("#tabOrigenMunPerfiles").removeClass("active")
    $("#tabEdadesMunPerfiles").addClass("active")

    $("#ocupaciones_mun_perfiles").removeClass("active")
    $("#onomasticos_mun_perfiles").removeClass("active")
    $("#origen_mun_perfiles").removeClass("active")
    $("#edades_mun_perfiles").addClass("active")
    
    $("body").removeClass("modal-open")
    
    $("#sidebar-mun-perfiles").delay(300).slideDown(50);
    
    $("#sidebar-mun-perfiles").toggleClass("shw-rside").promise().done(function(){
      var modal = document.getElementById("modal-mun-perfiles");
      modal.style.display = "none";
    });
  }

}